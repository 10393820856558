import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import annotation_plus from './../../../../images/expand-icon.svg';
import download from '../../../../../src/images/download-icon.svg';
import Loader from '../../../common/page-loader/ComponentLoader';
import NoData from '../../../dashboard/components/no-data/NoData';
import mockData from "../data/amps.json";
//import AmpsChart from './charts/AmpsChart';
import { AmpsDetailsProps, ampsData } from './interfaces/amps.interfaces'; 
import AmpsModal from './modals/AmpsModal';
import AmpsRefChart from './charts/AmpsRefChart';
import { fetchAmpsData } from './storeSlices/ampsSlice';
import { useAppDispatch } from '../../../../hooks/storeHooks';


const AmpsDetails: React.FC<AmpsDetailsProps> = ({heading}) => {
    const loading = false;  // Set loading based on your data fetching logic
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);   
    const chartRefOne = useRef<HTMLDivElement | null>(null);
    const chartRefTwo = useRef<HTMLDivElement | null>(null);
    const [data, setData] = useState<ampsData>({ overload: [], underload: [] });
    const hElement = document.getElementById('main-root');
    
    const dispatch = useAppDispatch();


    const getLast7DaysDate = () => {
        const today = new Date();
        const last7Days = new Date(today);
        last7Days.setDate(today.getDate() - 6);
        return last7Days;
    };

    const [selectedDateRange,] = useState({
        startDate: getLast7DaysDate(),
        endDate: new Date(),
        key: 'selection',
    });

    const openModal = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setModalIsOpen(true);
        hElement && hElement?.classList.add('modal-open');
    };

    const handleClose = () => {
        setModalIsOpen(false);
        hElement && hElement?.classList.remove('modal-open');
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
          setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const fetchData = async () => {
        const transformedData: ampsData = {
            overload: mockData.ampsData.overload.map((item) => ({
                date: new Date(item.date).getTime(),
                value: item.value,
            })),
            underload: mockData.ampsData.underload.map((item) => ({
                date: new Date(item.date).getTime(),
                value: item.value,
            })),
        };
        setData(transformedData);
    }

    useEffect(() => {
        fetchData();
    }, [selectedDateRange.startDate, selectedDateRange.endDate]);

    useEffect(() => {
        dispatch(fetchAmpsData({ assetName: 'FRASER+TXL+H3+108LH+8' }));
    }, [dispatch]);


  return (
    <>
        <ToastContainer position='top-right' autoClose={3000} />
        <div className='grid-item-text h-full graph-card well-graph-card'>
            <div className='table-header-section pie-section'>
                <div className='title'>{heading}</div>
                <div className='header-icon'>
                    <button className='expand-btn cancelSelectorName' onClick={openModal} style={{ cursor: 'pointer', zIndex: 0 }}>
                        <img src={annotation_plus} alt='Expand' className='img-border' />
                    </button>
                </div>
                <div className='mr-4 dropdown-container' ref={dropdownRef}>
                    <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                        <span className='dot'></span>
                        <span className='dot'></span>
                        <span className='dot'></span>
                    </button>
                    {isDropdownOpen && (
                    <div className='dropdown-options'>
                        <button>Actions</button>
                        <button className={`btn btn-default cancelSelectorName`}>
                            <img src={download} alt='icon' className='btn-icon' />
                            Download PDF
                        </button>
                        <button className={`btn btn-default cancelSelectorName`}>
                            <img src={download} alt='icon' className='btn-icon' />
                            Download CSV
                        </button>
                    </div>
                    )}
                </div>
            </div>
            <hr />

            <div id="amps-container" className='wellChartSection ampsChartSection'>
                {loading ? (
                    // <div className='well-production-loader'>
                    <Loader />
                    // </div> 
                ) :  data?.overload?.length > 0 || data?.underload?.length > 0 ? (
                    <>
                        <div className='flex justify-end mt-3 space-x-4'>
                            <label className={`radio-container mr-4`} >
                                <input 
                                type='radio' 
                                name={`ampsCheck`}
                                defaultChecked
                                //onChange={}
                                />
                                <span className='radio-checkmark'></span>
                                Surface
                            </label>
                            <label className={`radio-container`} >
                                <input 
                                type='radio' 
                                name={`ampsCheck`}
                                //onChange={}
                                />
                                <span className='radio-checkmark'></span>
                                Downhole
                            </label>
                        </div>
                         <AmpsRefChart chartID={`ampsChartdiv${Math.random()}`} chartRef={chartRefOne} data={data}/>      
                    </>
                ): (
                    <NoData heading='No data found' />
                )}
            </div>            
        </div>
        <AmpsModal
          isOpen={modalIsOpen}
          onRequestClose={handleClose}
          heading={'Amps'}
          loading={loading}
          modelWidth={'75rem'}
          modelClass={'wellChartModal'}        
        >
            {/* <AmpsChart chartID={`ampsChartdiv${Math.random()}`} data={data}/> */}
            <AmpsRefChart chartID={`ampsChartdiv${Math.random()}`} chartRef={chartRefTwo} data={data}/>  
        </AmpsModal>
    </>    
  );
};

export default AmpsDetails;