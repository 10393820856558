import React from 'react'
import ProgressBar from './ProgressBar'
import InfoCircle from '../../../images/info-circle.svg'
import ClickEventToolTip from '../../common/tooltip/ClickEventToolTip'


const HealthScoreChart = () => {

    return (
        <div className='health-score-chart-container card m-0 flex flex-row'>
            <div className='health-score-chart flex justify-center items-center'>
                chart
            </div>
            <div className='progress-bar-container'>
                <div className='header1'>Health targets</div>
                <div className='progress-div'>
                    <div className='flex justify-between items-center flex-row'>
                        <span className='variable'>Variable 1</span>
                        <span className='percentage'>70%</span>
                    </div>
                    <ProgressBar progress={70} />

                </div>
                <div className='progress-div'>
                    <div className='flex justify-between items-center flex-row'>
                        <span className='variable'>Variable 2</span>
                        <span className='percentage'>70%</span>
                    </div>
                    <ProgressBar progress={70} />

                </div>
                <div className='progress-div'>
                    <div className='flex justify-between items-center flex-row'>
                        <span className='variable'>Variable 3</span>
                        <span className='percentage'>70%</span>
                    </div>
                    <ProgressBar progress={70} />

                </div>

            </div>
            <div className='info-button flex items-start flex-column'>
                <ClickEventToolTip content='The health score is comprised of the most impactful positive variable, most impactful negative variable and a baseline variable' direction='bottom'>
                    <button className='button flex justify-center items-center'>
                        <img src={InfoCircle} />
                    </button>
                </ClickEventToolTip>
            </div>


        </div>
    )
}

export default HealthScoreChart