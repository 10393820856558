import React, { memo, useEffect, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { backPressureChartProps } from '../interfaces/backPressure.interface';

const BackPressureChart: React.FC<backPressureChartProps> = ({ 
  chartID, 
  chartRef, 
  classValue,
  minValue,
  maxValue,
  clockHandValue,
  clockHandName,
  startAngle,
  endAngle,
  innerCircleEndvalue,
  bandsData,
  spacingValue
}) => {
   const root = useRef<any>(null);


    useEffect(() => {
      setTimeout(() => {
        if (chartRef.current) {

          // Dispose of previous root if it exists
          if (root.current) {
            root.current.dispose();
            root.current = null;
          }

          root.current = am5.Root.new(chartRef.current);  
          root.current.setThemes([am5themes_Animated.new(root.current)]);
  
          const chart = root.current.container.children.push(
              am5radar.RadarChart.new(root.current, {
                  panX: false,
                  panY: false,
                  startAngle: startAngle,
                  endAngle: endAngle,
                  paddingBottom: 40, // Add padding at the bottom
                  //innerRadius: -10
              })
          );
  
          const axisRenderer = am5radar.AxisRendererCircular.new(root.current, {
              strokeOpacity: 0.1,
              minGridDistance: 30
          });
  
          axisRenderer.ticks.template.setAll({
              visible: false,
              strokeOpacity: 0.5
          });
  
          axisRenderer.grid.template.setAll({
              visible: false
          });
  
          const axis = chart.xAxes.push(
              am5xy.ValueAxis.new(root.current, {
                  maxDeviation: 0,
                  //min: filteredData?.min,
                  //max: filteredData?.max,
                  min: minValue,
                  max: maxValue,
                  strictMinMax: true,
                  renderer: axisRenderer,
                  //fill: am5.color(0x384252),
              })
          );
  
          axis.get('renderer').labels.template.setAll({
            fill: am5.color('#fff'),
            rotation: 0, // Horizontal labels
            textAlign: 'center', // Centered horizontally
            verticalCenter: 'middle', // Centered vertically
            maxWidth: 100, // Adjust as needed
            fontSize: "12px", // Smaller font size if necessary
            fontWeight: "400" // Adjust weight if needed
        });
        // Adjust spacing between labels
        axis.get('renderer').labels.template.set('paddingTop', 10); // Add padding to avoid overlap


          // create inner gray band.
            const createInnerRange = (start:number, end:number, color:am5.Color) => {
              const rangeDataItem: any = axis.makeDataItem({
                value: start,
                endValue: end
              });
            
              axis.createAxisRange(rangeDataItem);
    
              rangeDataItem.get("axisFill").setAll({
                visible: true,
                fill: color,
                fillOpacity: 1,
                innerRadius: -25,
                  //stroke: color,
                  //  strokeOpacity: 0.8,
                //strokeWidth: 1
              });
              
              rangeDataItem.get("tick").setAll({
                visible: false
              });
            }
    
            createInnerRange(0, innerCircleEndvalue ?? 1500, am5.color(0x384252));
  
  
          // Create axis ranges bands
           am5.array.each(bandsData, function (data, index) {
              const axisRange: any = axis.createAxisRange(axis.makeDataItem({}));
            
              axisRange.setAll({
                value: data.lowScore + (index * spacingValue),
                endValue: data.highScore + (index * spacingValue)
              });
            
              axisRange.get("axisFill").setAll({
                visible: true,
                fill: am5.color(data.color),
                fillOpacity: 1,
                innerRadius: -10,
                gap: 10,
                cornerRadius: 25
              });
            
            });
  
  
  
            // Add clock hand
              const createHand = (value:number | undefined, topWidth:number, bottomWidth:number, pinRadius:number, radius:number,  color:am5.Color) => {
                const handDataItem: any = axis.makeDataItem({
                  value: value
                });
                
                const hand = handDataItem.set("bullet", am5xy.AxisBullet.new(root.current, {
                  sprite: am5radar.ClockHand.new(root.current, {
                    topWidth: topWidth,
                    pinRadius: am5.percent(pinRadius),
                    radius: am5.percent(radius),
                    bottomWidth: bottomWidth,
                    //bottomWidth: 200,
                    //innerRadius: am5percent(50),
                  })
                }));
                
                hand.get("sprite").pin.setAll({
                    forceHidden: false,
                    fill: color,
                    fillOpacity: 1
                  });
                
                  hand.get("sprite").hand.setAll({
                    fill: color,
                    fillOpacity: 1
                  });
                
                  
                  axis.createAxisRange(handDataItem);
                
                return hand;
              }
              createHand(50, 0, 0, 72, 78, am5.color(0x5D6675));
              createHand(50, 0, 0, 70, 78, am5.color(0x001023));            
              const hand2 = createHand(clockHandValue, 0, 45, 61, 86, am5.color(0x4A5463));
              const hand1 = createHand(clockHandValue, 0, 40, 60, 85, am5.color(0x001023));
              
  
              hand1.get("sprite").dataItem.animate({
                key: "value",
                to: clockHandValue,//filteredData?.value,
                duration: 800,
                easing: am5.ease.out(am5.ease.cubic)
              }); 
              hand2.get("sprite").dataItem.animate({
                key: "value",
                to: clockHandValue,//filteredData?.value,
                duration: 800,
                easing: am5.ease.out(am5.ease.cubic)
              });
              
              const label2 = chart.radarContainer.children.push(am5.Label.new(root.current, {
                fill: am5.color(0xffffff),
                centerX: am5.percent(50),
                textAlign: "center",
                centerY: am5.percent(-30),
                fontSize: "16px"
              }));
              label2.set("text", `${clockHandName}`); //${filteredData?.uom ?? 0}
             

              // Create a legend
              const legend = chart.children.push(
                am5.Legend.new(root.current, {
                  centerX: am5.percent(60),  // Center horizontally
                  x: am5.p50,        // Align to the center
                  y: am5.percent(115), // Move it to the bottom
                  centerY: am5.p100, // Anchor the legend at the bottom
                  layout: root.current.horizontalLayout, // Layout for horizontal alignment
                })
              );

              // Set the legend data (name and value)
              legend.data.setAll([
                {
                  name: "Valve rating: ",
                  value: '1500 psi',
                },
              ]);

              // Set the legend labels
              legend.labels.template.setAll({
                text: "{name}",
              });

              // Set the legend value labels
              legend.valueLabels.template.setAll({
                text: "{value}",
              });

              legend.labels.template.set('fill', am5.color(0xffffff));
              legend.valueLabels.template.set('fill', am5.color(0xffffff));

              // Update the legend dynamically if the hand value changes
            /*  hand2.events.on("valuechanged", () => {
                legend.data.setAll([
                  {
                    name: "Current Value",
                    value: hand2.get("value").toString(),
                  },
                ]);
              }); */

          
  
          
          // Clean up chart when the component unmounts
          return () => {
            root.current.dispose();
          };
        }
      }, 500); 
      }, []); 
  

    return (
        <>
            <div id={`${chartID}`} ref={chartRef} className={`${classValue} chart-wrapper`}></div>
        </>
    );
};

export default memo(BackPressureChart);