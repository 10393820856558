import React from 'react';
import Dataflow from '../../../images/data-flow.svg';
import colorDataflow from '../../../images/data-flow-color.svg';
import Carrot from '../../../images/chevron-down.svg';
import ChevronUp from '../../../images/chevron-up.svg';

interface assets {
    assetId:string;
    assetName:string;
    enabled?:boolean;
    industryApplicationId?:number;
    wellOpType?: string | number | null;
}
/* interface childGroups {
    assets: assets[] | null;
    childGroups: childGroups[] | null;
    groupName: string;
} */
  
interface DrawerItemInterface {
    groupData:any;
    expandedGroups:string[];
    handleSecondLevelGroupNameClick:(assetGroupName: string, GroupName: string) => void;
    handleAssetGroupClick:(assetGroupName: string) => void;
    handleAssetIdClick:(assetName: string, assetId: string | undefined, assetGroupName: string) => void;
    paddingGroup?:string;
    paddingAsset?:string;
    stickyClass?:string;
}

const DrawerItem: React.FC<DrawerItemInterface> = ({groupData,expandedGroups,handleSecondLevelGroupNameClick,handleAssetGroupClick,handleAssetIdClick,paddingGroup,paddingAsset, stickyClass}) => {
  return (
    <>
        <div className={`navigation-header mb-1 ${expandedGroups.includes(groupData.groupName) ? `expanded-two ${stickyClass}` : '' }`} 
        style={{ paddingLeft: paddingGroup }}
        >
            <div className='navigation-content-frame-item-content'>
                <div className='navigation-content-frame-item-dataflow'>
                {expandedGroups.includes(groupData.groupName) ? (
                    <img src={colorDataflow} alt='Colordataflow' className='data-flow-icon' />
                ) : (
                    <img src={Dataflow} alt='Dataflow' className='data-flow-icon' />
                )}
                </div>
                <div
                className='navigation-content-frame-item-text'
                // onClick={() => handleAssetGroupNameClick(group.groupName)}
                onClick={() => {handleSecondLevelGroupNameClick(groupData.groupName, groupData.groupName)}}
                >
                <span>{groupData.groupName}</span>
                </div>
            </div>
            <div className='navigation-content-frame-item-carrot'>
                {groupData?.childGroups?.length > 0 ? (
                groupData.childGroups && <span className='child-count'>{groupData.childGroups.length}</span>
                ) : ( groupData.assets && <span className='child-count'>{groupData.assets.length}</span>)}
                {expandedGroups.includes(groupData.groupName) ? (
                <img
                    className='chevron'
                    src={ChevronUp}
                    alt='Chevron Up'
                    onClick={() => handleAssetGroupClick(groupData.groupName)}
                />
                ) : (
                <img
                    src={Carrot}
                    alt='Carrot'
                    onClick={() => handleAssetGroupClick(groupData.groupName)}
                    className='chevron-down'
                />
                )}
            </div>
        </div>
        <div className='navigation-child-container'>
        {expandedGroups.includes(groupData.groupName) &&
            groupData.assets &&
            groupData.assets.map((asset: assets) => (
            <div
                data-testid={`navbar-${asset.assetId}`}
                key={asset.assetId}
                className='navigation-content-frame-asset'
                style={{ paddingLeft: paddingAsset }}
                onClick={() => {
                handleAssetIdClick(
                    asset.assetName,
                    asset.assetId,
                    groupData.groupName,
                    // childGroup.assets,
                );
                }}
            >
                {/*  <div className='navigation-content-frame-item-dataflow'>
                <img src={Dataflow} alt='Dataflow' className='data-flow-icon' />
                </div> */}
                <div className='navigation-content-frame-item-text flex items-start'>{asset.assetName}</div>
            </div>
            ))}
        </div>
    </>
  )
}

export default DrawerItem;