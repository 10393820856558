import React from 'react'

const PIDSetting = () => {
    return (
        <div className='pid-setting-container'>
            <div className='flex flex-col space-y-4'>
                <div className='flex flex-row space-x-4'>
                    <div className='manage-asset-form-control w-1/2'>
                        <label className='label'>Process Variable</label>
                        <input
                            type='text'
                            className='time-input-field'
                            placeholder='47.0 Hz'
                        />
                    </div>
                    <div className='manage-asset-form-control w-1/2'>
                        <label className='label'>Action</label>
                        <input
                            type='text'
                            className='time-input-field'
                            placeholder='47.0 Hz'
                        />
                    </div>
                </div>

                <div className='flex flex-row space-x-4'>
                    <div className='manage-asset-form-control w-1/4'>
                        <label className='label'>Shutdown setpoint</label>
                        <input
                            type='text'
                            className='time-input-field'
                            placeholder='47.0 Hz'
                        />
                    </div>
                    <div className='manage-asset-form-control w-1/4'>
                        <label className='label'>Restart setpoint</label>
                        <input
                            type='text'
                            className='time-input-field'
                            placeholder='47.0 Hz'
                        />
                    </div>
                    <div className='manage-asset-form-control w-1/4'>
                        <label className='label'>PID delay</label>
                        <input
                            type='text'
                            className='time-input-field'
                            placeholder='47.0 Hz'
                        />
                    </div>
                    <div className='manage-asset-form-control w-1/4'>
                        <label className='label'>Setpoint (A)</label>
                        <input
                            type='text'
                            className='time-input-field'
                            placeholder='47.0 Hz'
                        />
                    </div>
                </div>

                <div className='flex flex-row space-x-4'>
                    <div className='manage-asset-form-control w-1/4'>
                        <label className='label'>Deadbond (A)</label>
                        <input
                            type='text'
                            className='time-input-field'
                            placeholder='47.0 Hz'
                        />
                    </div>
                    <div className='manage-asset-form-control w-1/4'>
                        <label className='label'>Fixed output (Hz)</label>
                        <input
                            type='text'
                            className='time-input-field'
                            placeholder='47.0 Hz'
                        />
                    </div>
                    <div className='manage-asset-form-control w-1/4'>
                        <label className='label'>PID delay (Hz)</label>
                        <input
                            type='text'
                            className='time-input-field'
                            placeholder='47.0 Hz'
                        />
                    </div>
                    <div className='manage-asset-form-control w-1/4'>
                        <label className='label'>Max frequency (A)</label>
                        <input
                            type='text'
                            className='time-input-field'
                            placeholder='47.0 Hz'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PIDSetting