export enum WellControlAction {
    Start = 1,
    Stop = 2,
    Idle = 3,
    ClearAlarms = 4,
    ConstantRunMode = 5,
    PocMode = 6,
    PercentTimeMode = 7,
    Scan = 8,
    SetClock = 9,
    PowerShutdown = 10,
    UpdatePocData = 11,
    FastScan = 12,
    SurfaceMode = 13,
    VfdMode = 14,
    PipControl = 15,
    Download = 16,
    Upload = 17,
    PlungerLift5Min = 18,
    ScheduleMode = 19,
    ScanMasterAndSlaves = 20,
    EnablePid = 21,
    DisablePid = 22,
    FixedSpeedManual = 23,
    FixedSpeedTimer = 24,
    FixedSpeedPumpOff = 25,
    VariableSpeedPumpFillage = 26,
    VariableSpeedManual = 27,
    VariableSpeedPumpOff = 28,
    FixedSpeedPumpFillage = 29,
}