import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import axios from 'axios';
import { getAPIBaseURL } from '../../utilities/BaseURLUtility';



interface State {
  loading: boolean;
  groups: Array<Group> | null | undefined;
  searchGroups: Array<Group> | null | undefined;
  error: string | undefined;
  selectedAssetId: any;
  selectedAssetPocType: string | undefined;
  selectedAssetName: string | undefined;
  selectedGroup: string | undefined;
  assetList: AssetActionPayload[];
  selectedGroupName: string | undefined;
  groupHashMap: any;
  socketID: any;
  setPointSocketID: any;
}

interface ActionPayload {
  assetGroupName?: string | undefined;
  assetId?: string | undefined;
  assetName?: string | undefined;
  searchString?: string | undefined;
  pocType?: string | undefined;
}

// interface SelectedAssetActionPayload {
//   assetId: string | undefined;
// }

interface AssetActionPayload {
  assetName: string | undefined;
  assetId: string | undefined;
  industryApplicationId: number | undefined;
}

interface Group {
  assetGroupName: string;
  assetIds: Array<string>;
  showAssets: boolean;
  class: string;
}





// Async thunk to fetch the initial state data
export const fetchInitialStateAsync = createAsyncThunk('assetGroupsSlice/fetchInitialState', async () => {
  const data = await axios.get(`${getAPIBaseURL()}/groups`);
  return data.data;
});



const initialState: State = {
  loading: false,
  groups: null,
  searchGroups: null,
  groupHashMap: null,
  error: undefined,
  selectedAssetId: undefined,
  selectedAssetPocType: undefined,
  selectedAssetName: undefined,
  selectedGroup: undefined,
  assetList: [],
  selectedGroupName: undefined,
  socketID: null,
  setPointSocketID: null,
};

export const assetGroupsSlice = createSlice({
  name: 'assetGroups',
  initialState,
  reducers: {
    updateAssetsDisplayState: (state, action: PayloadAction<ActionPayload>) => {
      state.searchGroups?.map((group: Group) => {
        if (group.assetGroupName == action.payload.assetGroupName) {
          const showAssets = !group.showAssets;
          group.showAssets = showAssets;
          group.class = showAssets ? 'navigation-content-frame-item item-selected' : 'navigation-content-frame-item';
        }
      });
    },
    updateSelectedAsset: (state, action: PayloadAction<ActionPayload>) => {
      state.selectedAssetName = action.payload.assetName;
      state.selectedAssetId = action.payload.assetId;
      state.selectedGroup = action.payload.assetGroupName;
    },
    updateAsset: (state, action: PayloadAction<ActionPayload>) => {
      state.selectedAssetId = action.payload.assetId;
      state.selectedAssetName = action.payload.assetName;
    },
    updateAssetId: (state, action: PayloadAction<ActionPayload>) => {
      state.selectedAssetId = action.payload.assetId;
    },
    updateAssetPocType: (state, action: PayloadAction<ActionPayload>) => {
      state.selectedAssetPocType = action.payload.pocType;
    },
    updateAssetList: (state, action: PayloadAction<AssetActionPayload[]>) => {
      state.assetList = action.payload;
    },
    updateSelectedGroupName: (state, action: PayloadAction<string>) => {
      state.selectedGroupName = action.payload;
    },

    updateSearchGroups: (state, action: PayloadAction<ActionPayload>) => {
      const searchString = action.payload.searchString?.toLowerCase();
      state.searchGroups = resetShowAssets(state.groups);
      if (searchString) {
        const allGroups = state.groups || [];
        state.searchGroups = allGroups
          .filter((group) =>
            group.assetIds.some((assetId) => assetId.toLowerCase().startsWith(searchString)),
          )
          .map((group) => ({
            ...group,
            showAssets: true,
            assetIds: group.assetIds.filter((assetId) => assetId.toLowerCase().startsWith(searchString)),
          }));
      } else {
        state.searchGroups = state.groups;
      }
    },
    updateSocketID: (state, action: PayloadAction<string>) => {
      state.socketID = action.payload;
    },
    updateSetPointSocketID: (state, action: PayloadAction<string>) => {
      state.setPointSocketID = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitialStateAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInitialStateAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.groups = action.payload;
        state.searchGroups = action.payload;
      })
      .addCase(fetchInitialStateAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

  },
});

export const fetchInitialState = async () => {
  try {
    const response = await axios.get(`${getAPIBaseURL()}/groups`);
    return response.data;
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return [];
  }
};
const resetShowAssets = (groups: Group[] | null | undefined): Group[] | null => {
  if (!groups) return null;
  return groups.map((group: Group) => ({
    ...group,
    showAssets: false,
    class: 'navigation-content-frame-item',
  }));
};

// const setGroupHashMapDisplayState = (groupHashMap: Record<number, Group>, active: boolean): Record<number, Group> => {
//   const resetGroupDisplayState = (group: Group): Group => ({
//     ...group,
//     showAssets: active,
//     class: active ? 'navigation-content-frame-item item-selected' : 'navigation-content-frame-item',
//   });

//   const newHashMap = { ...groupHashMap };
//   Object.keys(newHashMap).forEach((key:string) => {
//     const numKey = parseInt(key, 10);
//     newHashMap[numKey] = resetGroupDisplayState(newHashMap[numKey]);
//   });
//   return newHashMap;
// };

// Export the actions from the itemsSlice
export const { updateAssetsDisplayState, updateSelectedGroupName, updateSelectedAsset, updateAsset, updateAssetId, updateAssetPocType, updateAssetList, updateSearchGroups, updateSocketID, updateSetPointSocketID } = assetGroupsSlice.actions;

export const selectAssetGroups = (state: RootState) => state.assetGroups;

export default assetGroupsSlice.reducer;
