import React, { useLayoutEffect, useRef, useState } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import { HistoryDetailsProps } from '../HistoryDetails';
import NoData from '../../../../dashboard/components/no-data/NoData';
import Loader from '../../../../common/page-loader/ComponentLoader';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/storeHooks';
import { GetAggregation } from '../../../../../utilities/CommonFunctions';
import { fetchSleTimeSeriesData } from '../../sle-time-series/SleSlice';

interface ChartDataProps {
    date: number;
    value: number;
}

interface LineChartProps {
    chartName: string;
    index: number;
    unitOfMeasure?: string;
    chartData: HistoryDetailsProps[] | null;
    colorCombination: any;
    short_UnitOfMeasure: any,
    selectedCalendarDate: {
        startDate: Date,
        endDate: Date
    };
    filteredValues: string[],
    currentAggregationValue: string
}

const chartsToSync: am5xy.XYChart[] = [];

const LineChart: React.FC<LineChartProps> = ({ chartName, index, chartData, colorCombination, short_UnitOfMeasure, selectedCalendarDate, filteredValues, currentAggregationValue }) => {
    const dispatch = useAppDispatch()
    const chartId = `chart-${chartName}-${index}-${Math.random()}`;
    const startDate = new Date(selectedCalendarDate.startDate.getFullYear(), selectedCalendarDate.startDate.getMonth(), selectedCalendarDate.startDate.getDate(), 0, 0, 0, 0);
    const endDate = new Date(selectedCalendarDate.endDate.getFullYear(), selectedCalendarDate.endDate.getMonth(), selectedCalendarDate.endDate.getDate(), 23, 59, 59, 999);
    const sleCancelTokenSource = useRef<ReturnType<typeof axios.CancelToken.source> | null>(null);
    const selectedAsset = useAppSelector((state) => state.assetGroups);
    const aggregateValues = useAppSelector((state) => state?.assetDetail?.aggregateValue)
    const sleVariable = useAppSelector((state) => state.sleTimeSeries.variable)
    const currentAggregation = useRef(currentAggregationValue)
    const [loading, setLoading] = useState(false)
    const { granularDataLoading } = useAppSelector((state) => state.sleTimeSeries);
    const prevZoomState = useRef({ start: 0, end: 1 })

    const handleStartEndChange = (targetChart: am5xy.XYChart) => {
        setTimeout(async () => {
            const xAxis = targetChart.xAxes.getIndex(0) as am5xy.DateAxis<am5xy.AxisRendererX>
            if (xAxis) {
                const startTimestamp = xAxis.getPrivate("selectionMin") ?? 0;
                const endTimestamp = xAxis.getPrivate("selectionMax") ?? 0;
                const start = xAxis.get("start") || 0;
                const end = xAxis.get("end") || 1;
                if (start >= 0 || end <= 1) {
                    const zoomDirection = (prevZoomState.current.start < start || prevZoomState.current.end > end) ? 'in' : 'out'
                    prevZoomState.current = { start: start, end: end }
                    const apiData = await getData(new Date(startTimestamp), new Date(endTimestamp), zoomDirection)

                    am5.array.each(chartsToSync, function (chart) {
                        const seriesArray = Array.from(chart.series || []);

                        apiData?.forEach((element: any) => {
                            // Find the variable name based on the current element's variable
                            const variableName = sleVariable.find(item => item.variable === element.variable)?.variableName || '';

                            // Find the series in seriesArray that has a name matching variableName
                            const matchedSeries = seriesArray?.find(series => series?.get('name') === variableName);

                            if (matchedSeries) {
                                const convertedArray = [...element.dataPoints]?.map((item) => ({ ...item, date: new Date(item.date).getTime() }))
                                const sortedData = convertedArray.sort((a, b) => a.date - b.date);
                                matchedSeries.data.setAll(sortedData)
                            }
                        });
                    });
                }
            }
        }, 50);

    };

    function debounce(func: any, wait: number) {
        let timeout: any;
        return function (...args: any[]) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    }

    const debouncedHandleStartEndChange = debounce((targetChart: am5xy.XYChart) => handleStartEndChange(targetChart), 500);

    function syncAxes(targetChart: am5xy.XYChart) {


        const targetAxis = targetChart.xAxes.getIndex(0);

        if (targetAxis && targetAxis._skipSync !== true) {
            const start = targetAxis.get("start");
            const end = targetAxis.get("end");

            am5.array.each(chartsToSync, function (chart) {
                if (chart !== targetChart) {

                    const axis = chart.xAxes.getIndex(0);
                    if (axis) {
                        axis._skipSync = true;
                        axis.setAll({
                            start: start,
                            end: end
                        });
                        axis._skipSync = false;
                    }
                }
                if (chart === targetChart) {
                    debouncedHandleStartEndChange(targetChart)
                }
            });
        }
    }

    const getData = async (start: any, end: any, zoomDirection = 'in') => {
        if (sleCancelTokenSource.current) {
            sleCancelTokenSource.current.cancel('canceled');
        }
        const { store } = await import('../../../../../store');

        const selTimeSeriesDataSet1 = store.getState().sleTimeSeries.sleTimeSeriesDataSet;
        sleCancelTokenSource.current = axios.CancelToken.source();

        const wellName = selectedAsset?.selectedAssetName ?? '';
        const startDate = new Date(start).toISOString();
        const endDate = new Date(end).toISOString();
        const aggregateValue = GetAggregation(new Date(start), new Date(end), aggregateValues)
        if (currentAggregation.current !== aggregateValue) {
            currentAggregation.current = aggregateValue
        }

        const matchedRecord = selTimeSeriesDataSet1?.find((item) => item.aggregate === aggregateValue)
        let matchedData = matchedRecord?.data
        if (!matchedData) {
            setLoading(true)
            await dispatch(fetchSleTimeSeriesData({ wellName: wellName, startDate: startDate, endDate: endDate, variables: filteredValues.toString() || '', aggregate: aggregateValue, cancelToken: sleCancelTokenSource?.current?.token, granularData: true }))
                .unwrap()
                .then((response) => {
                    const data = response.data
                    matchedData = data
                })
                .catch((error) => {
                    console.error('Failed to fetch fluid tab details:', error);
                    matchedData = []
                })
                .finally(() => {
                    setLoading(false)
                })

        } else {
            if (zoomDirection === 'out' || zoomDirection === 'in') {
                const storedDataStartDate = matchedRecord?.startDate ? new Date(matchedRecord?.startDate) : new Date(0)
                const storedDataEndDate = matchedRecord?.endDate ? new Date(matchedRecord?.endDate) : new Date(0)
                const startDateDifference = Math.abs(storedDataStartDate.getTime() - new Date(startDate).getTime());
                const endDateDifference = Math.abs(storedDataEndDate.getTime() - new Date(endDate).getTime());

                if ((storedDataStartDate > new Date(startDate) && startDateDifference > 1 * 1000) || (storedDataEndDate < new Date(endDate) && endDateDifference > 1 * 1000)) {
                    setLoading(true)

                    await dispatch(fetchSleTimeSeriesData({ wellName: wellName, startDate: startDate, endDate: endDate, variables: filteredValues.toString() || '', aggregate: aggregateValue, cancelToken: sleCancelTokenSource?.current?.token, granularData: true }))
                        .unwrap()
                        .then((response) => {
                            const data = response.data
                            matchedData = data
                        })
                        .catch((error) => {
                            console.error('Failed to fetch fluid tab details:', error);
                            matchedData = []
                        })
                        .finally(() => {
                            setLoading(false)
                        })
                }
            }
        }
        const convertedArray = matchedData?.map((item) => ({ ...item, trendName: sleVariable?.find((sleVariable) => sleVariable.variable === item.variable)?.variableName || '' }))
        return convertedArray
    }

    useLayoutEffect(() => {
        if (!chartData) return;
        const root = am5.Root.new(chartId);

        root.setThemes([
            am5themes_Dark.new(root)
        ]);

        const chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: 'none',
            wheelY: 'zoomX',
            pinchZoomX: true,
            maxTooltipDistance: 0,
        }));
        chartsToSync.push(chart);

        const xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            maxDeviation: 0.2,
            groupData: false,
            extraMax: 0,
            extraMin: 0,
            strictMinMax: true,
            baseInterval: { timeUnit: 'second', count: 1 },
            renderer: am5xy.AxisRendererX.new(root, {
                minGridDistance: 100
            })
        }));

        if (startDate)
            xAxis.set('min', new Date(startDate).getTime())
        if (endDate)
            xAxis.set('max', new Date(endDate).getTime())

        xAxis.get('renderer').labels.template.set('paddingTop', 15);

        const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation: 0.2,
            extraMax: 0,
            extraMin: 0,
            renderer: am5xy.AxisRendererY.new(root, {})
        }));

        const cursor = chart.set(
            'cursor',
            am5xy.XYCursor.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                behavior: 'none',
            }),
        );
        cursor.lineY.set('visible', false);
        cursor.lineX.setAll({
            visible: true,
            stroke: am5.color('#ADD8E6'),
            strokeWidth: 2,
        });

        cursor?.lineY.setAll({
            strokeDasharray: [2, 2],
        });

        const tooltip = am5.Tooltip.new(root, {
            getFillFromSprite: false,
            autoTextColor: false,
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
        });


        tooltip?.get("background")?.setAll({
            fill: am5.color("#001023"),
            strokeOpacity: 0
        });

        chart.plotContainer.set("tooltipPosition", "pointer");
        chart.plotContainer.set("tooltipHTML", "<div style='overflow:visible' >" + "Placeholder" + "</div>");
        chart.plotContainer.set("tooltip", tooltip);



        xAxis.on("start", () => syncAxes(chart));
        xAxis.on("end", () => syncAxes(chart));
        function createSeries(
            name: string,
            color: string,
            data: ChartDataProps[],
        ) {
            const series = chart.series.push(
                am5xy.LineSeries.new(root, {
                    name: name,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: 'value',
                    valueXField: 'date',
                    tooltip: am5.Tooltip.new(root, {
                        forceHidden: true,
                    }),
                    stroke: am5.color(color),
                    snapTooltip: true,
                }),
            );

            series.strokes.template.set('strokeWidth', 2);


            series.bullets.push(() => {
                const circle = am5.Circle.new(root, {
                    radius: 0,
                    fill: am5.color('#0000'),
                    stroke: am5.color('#fff'),
                    strokeWidth: 5,
                    visible: true,
                });

                circle.states.create('hover', {
                    radius: 7,
                });

                return am5.Bullet.new(root, {
                    locationX: 0.5,
                    sprite: circle,
                });
            });

            cursor.events.on('cursormoved', cursorMoved);

            let previousBulletSprites: any = [];
            function cursorMoved() {
                for (let i = 0; i < previousBulletSprites?.length; i++) {
                    previousBulletSprites[i]?.unhover();
                }
                previousBulletSprites = [];
                chart.series.each(function (series) {
                    const dataItem = series?.get('tooltip')?.dataItem;
                    if (dataItem) {
                        const bulletSprite = dataItem?.bullets?.length && dataItem?.bullets[0]?.get('sprite');
                        bulletSprite && bulletSprite.hover();
                        previousBulletSprites.push(bulletSprite);
                    }
                });


                let show = true;
                let html = `<div style="width:auto;background:#001023;border:1px solid #4A5463;border-radius:5px;display:flex;flex-direction:column;font-size: 14px;color: #F7F9F9;font-family: 'Mulish';font-weight: 400">`;
                chart.series.each(function (series) {
                    const tooltipDataItem = series.get("tooltipDataItem");
                    if (tooltipDataItem) {
                        if (show) {
                            const valueX = tooltipDataItem.get("valueX");
                            if (valueX != null) {
                                const date: any = new Date(valueX);
                                const dateOptions: any = { weekday: 'long', month: 'short', day: 'numeric' };
                                const formattedDate = date.toLocaleDateString(undefined, dateOptions);
                                const timeOptions: any = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
                                const formattedTime = date.toLocaleTimeString(undefined, timeOptions);
                                const datetime = `${formattedDate}, ${formattedTime}`;
                                html += `<div style="border-bottom: 1px solid #4A5463;padding:10px"> ${datetime}</div>`;
                                show = false;
                            }
                        }

                        const valueY = tooltipDataItem.get("valueY");
                        const valueYText = valueY == null ? "" : +valueY.toFixed(3);
                        const seriesName = series.get("name") || "Unnamed Series"; // Fallback for series name
                        const seriesColor = series.get("stroke")?.toString() || "#000000";
                        html += `<div style="display: flex; align-items: center;flex-direction: row;gap: 20px;justify-content: space-between;padding:10px">
            <div style="height:24px;align-items: center;display: flex">
            <span style="border:1px solid ${seriesColor};height:24px;margin-right: 5px;"></span>
            <span>${seriesName}</span>
            </div>
            <div>${valueYText} ${short_UnitOfMeasure}</div>
            </div>`;
                    }

                });

                html += "</div>"; // Ensure the closing div is added
                chart.plotContainer.set("tooltipHTML", html);
            }
            series.data.setAll(data);
        }

        // const seriesArray :any[]=[]
        chartData?.forEach((element) => {
            const data = element?.dataPoints && element?.dataPoints?.map((chart) => {
                return { date: new Date(chart?.date).getTime(), value: Number(chart?.value) }
            });

            const sortedData = data?.sort((a, b) => a.date - b.date)
            const colordata = colorCombination[element.trendName] ? colorCombination[element.trendName] : '#60BFDA';
            createSeries(
                element?.trendName,
                colordata,
                sortedData ?? [],
            );
        });

        return () => {
            root.dispose();
        };
    }, [chartData, filteredValues]);

    return (
        <>

            <div id={chartId} style={{
                width: "100%",
                height: "200px",
                opacity: loading || granularDataLoading ? 0.5 : 1,
                pointerEvents: loading || granularDataLoading ? "none" : "auto",
                position: 'relative'
            }} className={chartData ? '' : 'center-content'}>
                {loading || granularDataLoading ? (
                    <div className='trend-loader flex items-center justify-center w-100'>
                        <Loader />
                    </div>
                ) : (!chartData && <NoData heading='No data found' />)}

            </div>
        </>

    );

};

export default LineChart;