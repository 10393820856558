import React, { useState } from 'react'
import './BuildWatchListModal.scss'
import CloseIcon from '../../../../../images/CloseIcon.svg'

interface BuildWatchListModalProps {
    closeModal: () => void;
    CreateBtnHandler: () => void;
}

const BuildWatchListModal = ({ closeModal, CreateBtnHandler }: BuildWatchListModalProps) => {

    const [count, setCount] = useState<number>(0)
    const updateCount = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const description = e.target.value
        setCount(description.length)
    }

    const [formFields, setFormFields] = useState<{
        name: string,
        description: string;

    }>({
        name: '',
        description: ''
    })

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormFields({
            ...formFields,
            [e.target.id]: e.target.value
        })
    }

    return (
        <div className="build-watchlist-modal modal-overlay">
            <div className="modal-content">

                <div className="modal-header">
                    <h2 className="modal-title">Build watchlist</h2>
                    <button className="close-btn" onClick={closeModal}>
                        <img src={CloseIcon} alt="close" />
                    </button>
                </div>
                <div className="modal-body">
                    <div className="form-group">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input value={formFields.name} onChange={onChange} type="text" id="name" className="form-control" placeholder='My list' />
                    </div>
                    <div className="form-group">
                        <label htmlFor="description" className="form-label">Description (optional)</label>
                        <textarea value={formFields.description} onChange={(e) => {
                            onChange(e)
                            updateCount(e)
                        }} id="description" className="form-control" placeholder='Description' rows={4} />
                        <p className='max-desc-count'>{count}/50</p>
                    </div>

                </div>
                <div className="modal-footer">
                    <button onClick={closeModal} className="btn btn-secondary btn-lg CR-btn">Cancel</button>
                    <button onClick={CreateBtnHandler} className="btn btn-primary btn-lg CR-btn">Create</button>
                </div>
            </div>
        </div>
    )
}

export default BuildWatchListModal