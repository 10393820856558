import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getHealthScoreHistoryData } from "./services/HealthScoreService";
import { HealthScoreHistory } from "./model/HealthScoreHistory";


interface HealthScoreProps {
    healthScoreHistoryLoading: boolean,
    healthScoreHistory: HealthScoreHistory[],
    totalHealthScoreHistoryCount: number
}

const initialState: HealthScoreProps = {
    healthScoreHistoryLoading: false,
    healthScoreHistory: [],
    totalHealthScoreHistoryCount: 0

}

export const fetchHealthScoreHistory = createAsyncThunk('getHealthScoreHistory', async ({ StartDate, EndDate, PageNumber, PageSize }: { StartDate: string, EndDate: string, PageNumber: number, PageSize: number }) => {
    return await getHealthScoreHistoryData(StartDate, EndDate, PageNumber, PageSize)
})

const healthScoreSlice = createSlice({
    name: 'healthScore',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // fetch health score history thunk cases
        builder.addCase(fetchHealthScoreHistory.pending, (state) => {
            state.healthScoreHistoryLoading = true;
            state.totalHealthScoreHistoryCount = 0

        })
        builder.addCase(fetchHealthScoreHistory.fulfilled, (state, action) => {
            console.log("action.payload", action.payload)
            const healthScoreData = action.payload.data.results
            state.healthScoreHistoryLoading = false;
            state.healthScoreHistory = state.healthScoreHistory ? [...state.healthScoreHistory, ...healthScoreData] : [...healthScoreData]
            state.totalHealthScoreHistoryCount = action.payload.data.totalResultsCount
        })
        builder.addCase(fetchHealthScoreHistory.rejected, (state) => {
            state.healthScoreHistoryLoading = false;
            state.healthScoreHistory = []
        })

    }
});

// export const { updateAssetControlRoomDrawerProps } = assetControlRoomDrawerSlice.actions;
export default healthScoreSlice.reducer;