import React, { useEffect, useState, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import annotation_plus from '../../../../../images/expand-icon.svg';
import ShutdownByReasonModel from './ShutdownByReasonModel'; // Import the new component
import { AppUser } from '../../../../user/model/AppUser';
import { ShutdownByReason } from '../../../model/ShutdownByReason';
import download from '../../../../../../src/images/download-icon.svg';
import { downloadDashboardCSV } from '../../../DashboardServices';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/storeHooks';
import { fetchShutDownByReasonDetails } from '../../../DashboardSlice';
import NoData from '../../no-data/NoData';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../../common/page-loader/ComponentLoader';
import html2canvas from 'html2canvas';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import moment from 'moment-timezone';
import championx from '../../../../../../src/images/championxLogo.png';
import { ShutdownByReasonDetails } from '../../../model/ShutdownByReasonDetails';

interface ShutdownByReasonGraphProps {
  groupName: string;
}
type GroupedShutdownByReasonDetails = {
  operatorId: number;
  operator: string;
  [key: string]: number | string;
}

const ShutdownByReasonGraph: React.FC<ShutdownByReasonGraphProps> = ({ groupName }) => {
  const dispatch = useAppDispatch();
  const shutDownReason = useAppSelector((state) => state.dashboard.PerformanceSnapshot)?.shutdownByReason;
  const shutdownByReasonCount = useAppSelector((state) => state.dashboard.PerformanceSnapshot)?.shutdownByReasonCount;
  const loading = useAppSelector((state) => state.dashboard?.performanceLoading);
  const modalLoading = useAppSelector((state) => state.dashboard?.shutdownByReasonLoading);
  const heading = 'Shutdowns by reason';
  const subHeading = 'Total wells';
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [formatedShutdownReason, setFormatedShutdownreason] = useState<ShutdownByReason[]>([]);
  const hElement = document.getElementById('main-root');
  const [openModalForPDF, setOpenModalForPDF] = useState<boolean>(false);

  const currentUser = useAppSelector((state) => state?.user?.currentUser);
  const shutdownByReasonDetails = useAppSelector((state) => state.dashboard.shutdownByReasonDetails);
  const shutDownchartdiv = useRef<HTMLDivElement | null>(null);
  const root = useRef<am5.Root | null>(null);

  const [groupedData, setGroupedData] = useState<GroupedShutdownByReasonDetails[] | null>(null)

  const [sortConfig, ] = React.useState<{ key: string; direction: 'asc' | 'desc' } | null>(null);


  const colorCode: string[] = [
    '#003747',
    '#00789e',
    '#60bfda',
    '#86cfe3',
    '#a6dbea',
    '#dcf1f7',
    '#005f7c',
    '#912018',
    '#991A0F',
    '#93370D',
    '#05603A',
    '#004654',
    '#3A7E73',
    '#036A64',
    '#932F19',
    '#C78500',
    '#002F6C',
    '#FECDCA',
    '#FEDF89',
    '#A6F4C5',
    '#FABD85',
    '#FFE5B2',
    '#B8D7FF',
  ];

  useEffect(() => {
    if(!groupName) return;
    dispatch(fetchShutDownByReasonDetails({ userId: storedUser?.id, name: 'shutdownbyreason', groupName: groupName }));
  }, [groupName]);

  useEffect(() => {
    const groupData = (data: ShutdownByReasonDetails[] | null): GroupedShutdownByReasonDetails[] => {
      const groupedData: { [key: string]: GroupedShutdownByReasonDetails } = {};

      data?.forEach(item => {
        const key = `${item.operatorId}`;
        if (!groupedData[key]) {
          groupedData[key] = {
            operatorId: item.operatorId,
            operator: item.operator,
          };
        }
        groupedData[key][removeShutdown(item.status)] = item.value;
      });
      return Object?.values(groupedData);
    };

    const groupedData = groupData(shutdownByReasonDetails);
    setGroupedData(groupedData)
  }, [shutdownByReasonDetails])

  
  const removeShutdown = (key: string) => {
    const statusWithoutShutdown = key?.replace(/shutdown/gi, '').trim();
    const cleanStatus = statusWithoutShutdown.replace(/^[-,]+/, '').trim()
    const formatedStatus = cleanStatus?.length > 1 ? cleanStatus : key;
    return formatedStatus;
  }

  const sortedData = React.useMemo(() => {
    if (sortConfig === null) return groupedData;
    if (groupedData != null) {

      if (sortConfig.key === 'operator') {
        return [...groupedData].sort((a, b) => {
          const aValue = a.operator
          const bValue = b.operator
          if (aValue == null || bValue == null) return 0;
          if (typeof aValue === 'string' && typeof bValue === 'string') {
            return aValue.localeCompare(bValue) * (sortConfig.direction === 'asc' ? 1 : -1);
          }
          return aValue < bValue
            ? sortConfig.direction === 'asc'
              ? -1
              : 1
            : aValue > bValue
              ? sortConfig.direction === 'asc'
                ? 1
                : -1
              : 0;
        });
      } else {
        return [...groupedData].sort((a, b) => {
          const aValue = a[sortConfig.key] ?? 0;
          const bValue = b[sortConfig.key] ?? 0;
          if (typeof aValue === 'number' && typeof bValue === 'number') {
            return (aValue - bValue) * (sortConfig.direction === 'asc' ? 1 : -1);
          }
          return aValue < bValue
            ? sortConfig.direction === 'asc'
              ? -1
              : 1
            : aValue > bValue
              ? sortConfig.direction === 'asc'
                ? 1
                : -1
              : 0;
        });
      }
    }
  }, [groupedData, sortConfig]);

  const printWidgetAsPDF = () => {
    setOpenModalForPDF(true);
    openModal(event) // open expanded view for pdf
    setTimeout(() => {
      const modalElement:any = document.getElementsByClassName('ReactModal__Overlay');
      console.log(modalElement,"modalElement");
      
          if(modalElement){
            const table = document.querySelector("#shutdownByReasonModal-container .table-section");
            table?.setAttribute('style', 'display: none !important');
            modalElement[0].style.zIndex = '-9999'
            const totalWells = document.querySelector("#shutdownByReasonModal-container .total-count p");
            console.log(totalWells,"totalWells");
            
            totalWells?.setAttribute('style', 'margin-bottom: 20px');
          }
      
      const input = document.getElementById('shutdownByReasonModal-container');
      if (root.current) {
        const exporting = am5plugins_exporting.Exporting.new(root.current, {
          menu: am5plugins_exporting.ExportingMenu.new(root.current, {}),
        });
        Promise.all([exporting.getPdfmake(), exporting.export('png')]).then((res) => {
          const pdfMake = res[0];
          const str = moment().format('MMM-DD-YYYY hh:mm:ss A');
          const doc = {
            pageSize: 'A4',
            // pageOrientation: 'portrait',
            pageOrientation: 'Landscape',
            pageMargins: [30, 30, 30, 30],
            footer: {
              columns: [
                {
                  text: `Generated By: ${currentUser?.firstName || ''} ${currentUser?.lastName || ''}`,
                  alignment: 'left',
                  width: '50%',
                  bold: true,
                  margin: [10, 10],
                  fontSize: 9,
                  color: 'black',
                  decoration: 'underline',
                },
                {
                  text: 'Generated on : ' + str,
                  alignment: 'right',
                  width: '50%',
                  bold: true,
                  margin: [10, 10],
                  fontSize: 9,
                  color: 'black',
                  decoration: 'underline',
                },
              ],
            },
            content: [] as any[],
            styles: {
              tblheader: {
                fontSize: 8,
                color: 'white',
                fillColor: '#01485e',
                alignment: 'center',
              },
              tblheader1: {
                fontSize: 8,
                color: 'white',
                fillColor: '#01485e',
                alignment: 'left',
              },
              tbody1: {
                alignment: 'center',
                fontSize: 8,
              },
              tbody2: {
                alignment: 'center',
                fontSize: 8,
              },
            },
          };

          const rect = {
            type: 'rect',
            x: -32,
            y: -20, //could be negative to account for padding
            // w: 450,
            w: 650,
            h: 25,
            linearGradient: ['#01485e', '#01485e'],
          };
          const rectHead = {
            type: 'rect',
            x: -32,
            y: -20, //could be negative to account for padding
            w: 395,
            h: 25,
            linearGradient: ['#484848', '#484848'],
          };
          doc.content.push({
            columns: [
              {
                stack: [
                  { canvas: [rect] },
                  {
                    columns: [
                      {
                        // width: rect.w * 0.8,
                        width: rect.w / 2,
                        noWrap: false, //clip at with by wrapping to next line and...
                        maxHeight: rect.h, //don't show the wrapped text
                        image: championx,
                        fit: [55, 55],
                        margin: [5.5, 7.5, 3],
                        background: 'white',
                      },
                      {
                        text: 'Landing Page',
                        // width: rect.w * 0.8,
                        width: rect.w / 2,
                        alignment: 'left',
                        bold: true,
                        margin: [35, 5],
                        fontSize: 13,
                        color: 'white',
                      },
                    ],
                    relativePosition: {
                      x: rect.x,
                      y: -rect.h,
                    },
                  },
                ],
              },
              {
                stack: [
                  { canvas: [rectHead] },
                  {
                    columns: [
                      {
                        width: rectHead.w * 0.3,
                        // width: rectHead.w,
                        noWrap: false, //clip at with by wrapping to next line and...
                        maxHeight: rect.h, //don't show the wrapped text
                        text: 'LOOKOUT',
                        color: 'white',
                        bold: true,
                        alignment: 'right',
                        'font-size': 10,
                      },
                    ],
                    relativePosition: {
                      x: -30,
                      y: -20,
                    },
                  },
                ],
              },
            ],
          });
          doc.content.push({
            text: ' ',
            width: '100%',
          });

          doc.content.push(
            {
              text: 'Shutdowns By Reason',
              width: '*',
              alignment: 'center',
              fontSize: 18,
              margin: [0, 0, 0, 5],
            },
            {
              canvas: [
                {
                  type: 'line',
                  x1: 300,
                  y1: 0,
                  x2: 480,
                  y2: 0,
                  lineWidth: 1,
                },
              ],
              margin: [0, 0, 0, 20],
            },
          );

          html2canvas(input as HTMLElement, { backgroundColor: '#001023' })
            .then((canvas) => {
              canvas.style.marginTop = '10%';
              const imgData = canvas.toDataURL('image/png');

              doc.content.push({
                alignment: 'center',
                image: imgData,
                width: 450,
                height: 180,
                margin: [0, 0, 0, 20],
              });

              const tableColumns = ['Operator', ...formatedShutdownReason.map((item) => item.status)];
              const operators = [...new Set(shutdownByReasonDetails?.map((item) => item.operator))]; // Extract unique operators
              operators.sort((a, b) => a.localeCompare(b));

              // Create table headers
              const tbHead = tableColumns.map((header) => ({
                text: header,
                bold: true,
                style: 'tblheader',
              }));
              const tblBody = [tbHead];

              if (shutdownByReasonDetails) {

                sortedData?.forEach((item: any) => {
                  const row: any = tableColumns.map(key => {
                    if (key === 'Operator') {
                      return { text: item['operator'].toString(), bold: false, style: 'tbody1' };
                    }
                    return { text: Object.prototype.hasOwnProperty.call(item, key) ? item[key].toString() : 0, bold: false, style: 'tbody2' };
                  });

                  tblBody.push(row);
                });

                doc.content.push({
                  table: {
                    headerRows: 1,
                    widths: Array(tableColumns.length).fill('*'),
                    body: tblBody,
                  },
                });
              }

              const fileName = 'Lookout-Landing_Page_ShutdownsByReason_' + str + '.pdf';

              pdfMake.createPdf(doc).download(fileName);
            })
            .catch((err) => {
              console.error('Failed to generate PDF', err);
            });
        }).finally(() => {
          handleClose();
          setOpenModalForPDF(false);
        });
      }
    },250);

  };

  const handleDownloadCSV = () => {
    toast.success('Shutdown By Reason CSV download is in progress.!');
    const currentTime = moment().format("MMM-DD-YYYY hh:mm:ss A");
    const userId = storedUser?.id;
    const widgetName = 'ShutdownByReason';
    const data = { userId, widgetName, groupName };
    downloadDashboardCSV(data).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Lookout-Landing_Page_ShutdownsByReason_' + currentTime + '.csv');
      document.body.appendChild(link);
      link.click();
    }).catch((error) => {
      if (error?.message !== '') {
        toast.error('Shutdown By Reason CSV download is failed. Please try after sometime!');
      }
    });
    setIsDropdownOpen(false);
  };

  const openModal = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setModalIsOpen(true);
    hElement && hElement?.classList.add('modal-open');
  };

  const handleClose = () => {
    setModalIsOpen(false);
    hElement && hElement?.classList.remove('modal-open');
  };

  const initializeChart = (chartId: string, data1: ShutdownByReason[]): am5.Root | undefined => {
    if (shutDownchartdiv.current) {
      const data = data1?.slice(0, 7);
      root.current = am5.Root.new(shutDownchartdiv.current);
      // const root = am5.Root.new(chartId);
      root.current.setThemes([am5themes_Animated.new(root.current)]);

      const chart = root.current.container.children.push(
        am5percent.PieChart.new(root.current, {
          layout: root.current.horizontalLayout,
          innerRadius: am5.percent(50),
        }),
      );

      const series = chart.series.push(
        am5percent.PieSeries.new(root.current, {
          name: 'Series',
          valueField: 'value',
          categoryField: 'status',
          legendLabelText: '[#F7F9F9]{category}[/]',
          legendValueText: '[#F7F9F9]{value}[/]',
        }),
      );
      series.slices.template.setAll({
        stroke: am5.color('#FFFFFF'),
        strokeWidth: 2,
      });
      series.data.setAll(data);

      const dataItemsArray = Array.from(series.dataItems.values());
      dataItemsArray.forEach((dataItem, index) => {
        const sliceColor = colorCode[index];
        dataItem.set('fill', am5.color(sliceColor));
      });

      // Hide labels and ticks
      series.labels.template.set('forceHidden', true);
      series.ticks.template.set('forceHidden', true);

      const legend = chart.children.push(
        am5.Legend.new(root.current, {
          centerY: am5.percent(50),
          y: am5.percent(50),
          layout: root.current.verticalLayout,
        }),
      );

      legend.valueLabels.template.setAll({ textAlign: 'right' });
      legend.labels.template.setAll({
        maxWidth: 140,
        width: 100,
        oversizedBehavior: 'wrap',
      });

      //For making the legend circular
      legend.markerRectangles.template.setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        cornerRadiusBL: 10,
        cornerRadiusBR: 10,
        width: 10,
        height: 10,
      });

      legend.markerRectangles.template.setAll({
        y: 4,
      });
      legend.data.setAll(series.dataItems);

      return root.current;
    }
    return undefined;
  };
  useEffect(() => {
    if (shutDownReason) {
      const formatedReason: ShutdownByReason[] = shutDownReason?.map((item: any) => {
        const statusWithoutShutdown = item?.status?.replace(/shutdown/gi, '').trim();
        const cleanStatus = statusWithoutShutdown.replace(/^[-,]+/, '').trim();
        const formatedStatus = cleanStatus?.length > 1 ? cleanStatus : item?.status;
        return { value: item?.value, status: formatedStatus };
      });
      setFormatedShutdownreason(formatedReason);
    }
  }, [shutDownReason]);

  useEffect(() => {
    let rootInstance: am5.Root | undefined;
    if (formatedShutdownReason?.length && shutdownByReasonCount && shutDownReason?.length && heading && !loading) {
      rootInstance = initializeChart(`${heading}chartdiv`, formatedShutdownReason);
    }
    return () => {
      if (rootInstance) {
        rootInstance.dispose();
      }
    };
  }, [formatedShutdownReason, shutDownReason, shutdownByReasonCount, heading, loading]);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <ToastContainer position='top-right' autoClose={3000} />
      <div className='table-header-section pie-section'>
        <div className='title'>{heading}</div>
        <div className='header-icon'>
          <button
            className='expand-btn cancelSelectorName'
            onClick={openModal}
            style={{ cursor: 'pointer', zIndex: 0 }}
          >
            <img src={annotation_plus} alt='Expand' className='img-border' />
          </button>

          <div className='dropdown-container' ref={dropdownRef}>
            <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              <span className='dot'></span>
              <span className='dot'></span>
              <span className='dot'></span>
            </button>
            {isDropdownOpen && (
              <div className='dropdown-options'>
                <button>Actions</button>
                <button
                  className={`btn btn-default cancelSelectorName ${(shutDownReason === null || shutDownReason === undefined || shutDownReason?.length === 0) ? 'btn-disabled' : ''}`}
                  onClick={printWidgetAsPDF}
                >
                  <img src={download} alt='icon' className='btn-icon' />
                  Download PDF
                </button>
                <button
                  className={`btn btn-default cancelSelectorName ${(shutDownReason === null || shutDownReason === undefined || shutDownReason?.length === 0) ? 'btn-disabled' : ''}`}
                  onClick={handleDownloadCSV}
                >
                  <img src={download} alt='icon' className='btn-icon' />
                  Download CSV
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <hr />

      <div id='shutdownByReason-container' className='pie-container-dashboard nodata sutdown-by-reason'>
        {loading ? (
          <div className='flex items-center justify-center w-100'>
            <Loader />
          </div>
        ) : shutdownByReasonCount ? (
          <>
            <div className='total-count'>
              <p>{subHeading}</p>
              <h2>{shutdownByReasonCount}</h2>
            </div>
            <div id={`${heading}chartdiv`} ref={shutDownchartdiv} className='chart-wrapper'></div>
          </>
        ) : (
          !modalIsOpen && <NoData heading='No data found' />
        )}
        <ShutdownByReasonModel
          isOpen={modalIsOpen}
          onRequestClose={handleClose}
          heading={heading}
          subHeading={subHeading}
          count={shutdownByReasonCount}
          shutdownByReason={formatedShutdownReason}
          loading={modalLoading}
          modalWidth='65rem'
          openModalForPDF={openModalForPDF}
        />
      </div>
    </>
  );
};

export default ShutdownByReasonGraph;