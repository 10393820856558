import React, { useEffect } from 'react';
import Close from '../../../../../images/x-close.svg';
import CustomModal from '../ExpandModel';
import { UptimeDowntime } from '../../../model/UptimeDowntime';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { useAppSelector } from '../../../../../hooks/storeHooks';
import { UptimeDowntimeDetails } from '../../../model/UptimeDowntimeDetails';
import NoData from '../../no-data/NoData';
import Loader from '../../../../common/page-loader/ComponentLoader';
import { ReactComponent as SortIconDefault} from '../../../../../images/Chevron-default-icon.svg';
import { ReactComponent as SortIconUp} from '../../../../../images/Chevron-up-icon.svg';
import { ReactComponent as SortIconDown} from '../../../../../images/Chevron-down-icon.svg';

interface UptimeDowntimeModelProps {
  isOpen: boolean;
  onRequestClose: () => void;
  heading: string;
  subHeading: string;
  count: number | undefined;
  uptimeDowntime?: UptimeDowntime[];
}

type SortableKeys = keyof UptimeDowntimeDetails;
const UptimeDowntimeModel: React.FC<UptimeDowntimeModelProps> = ({
  isOpen,
  onRequestClose,
  heading,
  subHeading,
  count,
  uptimeDowntime,
}) => {
  const loading = useAppSelector((state) => state.dashboard.uptimeDowntimeLoading);
  const uptimeDowntimeDetails = useAppSelector((state) => state.dashboard.uptimeDowntimeDetails);
  console.log('uptimeDowntimeDetails:', uptimeDowntimeDetails);

  const [sortConfig, setSortConfig] = React.useState<{ key: SortableKeys; direction: 'asc' | 'desc' } | null>({
    key: 'operator',
    direction: 'asc',
  });

  const initializeChart = (chartId: string, data: UptimeDowntime[]): am5.Root => {
    const root = am5.Root.new(chartId);
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.horizontalLayout,
        innerRadius: am5.percent(60),
      }),
    );

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        valueField: 'value',
        categoryField: 'status',
        legendLabelText: '[#F7F9F9]{category}[/]',
        legendValueText: '[#F7F9F9]{value}[/]',
      }),
    );
    series.slices.template.setAll({
      stroke: am5.color('#FFFFFF'),
      strokeWidth: 1,
    });
    series.data.setAll(data);

    const dataItemsArray = Array.from(series.dataItems.values());
    dataItemsArray.forEach((dataItem) => {
      const status = dataItem.get('category') as string;
      let sliceColor = '';
      if (status === 'Uptime') {
        sliceColor = '#12b76a';
      } else if (status === 'Downtime') {
        sliceColor = '#f97066';
      } else {
        sliceColor = '#4A5463';
      }
      dataItem.set('fill', am5.color(sliceColor));
    });

    series.labels.template.set('forceHidden', true);
    series.ticks.template.set('forceHidden', true);

    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerY: am5.percent(50),
        y: am5.percent(50),
        layout: root.verticalLayout,
      }),
    );

    legend.valueLabels.template.setAll({ textAlign: 'right', fill: am5.color('#F7F9F9'), });
    legend.labels.template.setAll({
      maxWidth: 140,
      width: 80,
      oversizedBehavior: 'wrap',
      fill: am5.color('#F7F9F9'),
    });

    // For making the legend circular
    legend.markerRectangles.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10,
      width: 10,
      height: 10,
    });

    legend.markerRectangles.template.setAll({
      y: 4,
    });

    legend.data.setAll(series.dataItems);

    return root;
  };
  useEffect(() => {
    let root: am5.Root;
    if (isOpen && uptimeDowntime && count && !loading) {
      console.log('Initializing chart...');
      setTimeout(() => {
        root = initializeChart('uptimeDowntimeModaldiv', uptimeDowntime);
      }, 0);
    }
    return () => {
      if (root) {
        console.log('Disposing chart...');
        root.dispose();
      }
    };
  }, [isOpen, uptimeDowntime, heading, count, loading]);

  const requestSort = (key: SortableKeys) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig?.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };


  const sortedUptimeDowntimeDetails = React.useMemo(() => {
    const sortableItems = uptimeDowntimeDetails ? [...uptimeDowntimeDetails] : [];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];
        if (typeof aValue === 'undefined' || typeof bValue === 'undefined') {
          return 0;
        }
        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [uptimeDowntimeDetails, sortConfig]);

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className='modal-header'>
        <div className='flex'>
          <div className='grow'>
            <h3>{heading}</h3>
          </div>
          <div className='flex-none'>
            <button onClick={onRequestClose}>
              <img src={Close} alt='Expand' />
            </button>
          </div>
        </div>
      </div>
      <div className='modal-content'>
        {loading ? (
          <div className='flex items-center justify-center loader-margin'>
            <Loader />
          </div>
        ) : count ? (
          <div className='chart-wrap'>
            <div className='pie-container-dashboard'>
              <div className='total-count'>
                <p>{subHeading}</p>
                <h2>{count}</h2>
              </div>
              <div id='uptimeDowntimeModaldiv' className='chart-wrapper'></div>
            </div>
            <div className='table-section'>
              <div className='widget-modal'>
                <table className='uptime-downtime-table'>
                  <thead>
                    <tr className='table-header-flex'>
                      <th onClick={() => requestSort('operator')} className='well-by-status'>
                        Well by status{' '}
                        {sortConfig?.key === 'operator' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                      </th>
                      <th onClick={() => requestSort('uptime')} className='uptime'>
                        Uptime {sortConfig?.key === 'uptime' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                      </th>
                      <th onClick={() => requestSort('downtime')} className='downtime'>
                        Downtime {sortConfig?.key === 'downtime' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedUptimeDowntimeDetails?.map((uptimeDowntime, index) => (
                      <tr key={index}>
                        <td className='well-by-status'>{uptimeDowntime.operator}</td>
                        <td className='uptime'>{uptimeDowntime.uptime}</td>
                        <td className='downtime'>{uptimeDowntime.downtime}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <NoData heading='No data found' />
        )}
      </div>
    </CustomModal>
  );
};

export default UptimeDowntimeModel;
