import React, { useState } from 'react'

import Play from '../../../images/play.svg'
import Stop from '../../../images/stop.svg'
import leftArrow from '../../../images/arrow-left.svg'
import Wifi from '../../../images/wifi.svg'
import CheckCircle from '../../../images/check-circle.svg'

import './ManageAsset.scss'
import ScheduleEvent from './schedule-event/ScheduleEvent'
import PIDSetting from './schedule-event/PIDSetting'
import RockingStart from './schedule-event/RockingStart'
import GasLockRecovery from './schedule-event/GasLockRecovery'

const ManageAsset = () => {

    const [selectedEventDropdown, setSelectedDropdown] = useState('')


    const handleDropdownClick = (eventName: string) => {
        console.log("eventName", eventName)
        if (eventName === selectedEventDropdown) setSelectedDropdown('')
        else setSelectedDropdown(eventName)
    }

    return (
        <div className='manage-asset-container'>
            <div className='content-block flex-col space-y-5'>
                <div className='flex justify-between items-center'>
                    <div className='flex space-x-4'>
                        <label className={`radio-container`} >
                            <input type='radio' />
                            <span className='radio-checkmark'></span>
                            Hz
                        </label>
                        <label className={`radio-container`} >
                            <input type='radio' />
                            <span className='radio-checkmark'></span>
                            RPM
                        </label>
                    </div>
                    <div className='asset-control flex space-x-4'>
                        <button className='button disabled'>
                            <img src={Play} />Start
                        </button>
                        <button className='button stop'>
                            <img src={Stop} />Stop
                        </button>
                        <button className='button'>
                            <img src={leftArrow} />Reset faults
                        </button>
                        <button className='button'>
                            <img src={Wifi} />Ping comms
                        </button>
                    </div>
                </div>

                <div className='setpoint-block flex flex-row space-x-4'>
                    <div className='manage-asset-form-control w-1/2'>
                        <label className='label'>Setpoint speed</label>
                        <input
                            type='text'
                            className='time-input-field'
                            placeholder='47.0 Hz'
                        />
                    </div>
                    <div className='manage-asset-form-control w-1/2'>
                        <label className='label'>Setpoint speed</label>
                        <input
                            type='text'
                            className='time-input-field'
                            placeholder='47.0 Hz'
                        />
                        <div className='speed-description'>
                            <span>Shaft speed limit ---</span>
                            <span className='pipe'></span>
                            <span className='amp-capacity'>Max Hz motor amp capacity: ---</span>
                        </div>
                    </div>

                </div>

                <ScheduleEvent
                    eventHeading={'PID setting'}
                    selectedEventDropdown={selectedEventDropdown}
                    handleDropdownClick={handleDropdownClick}
                    child={<PIDSetting />}
                />
                <ScheduleEvent
                    eventHeading={'Rocking start'}
                    selectedEventDropdown={selectedEventDropdown}
                    handleDropdownClick={handleDropdownClick}
                    child={<RockingStart />}
                />
                <ScheduleEvent
                    eventHeading={'Gas lock recovery'}
                    selectedEventDropdown={selectedEventDropdown}
                    handleDropdownClick={handleDropdownClick}
                    child={<GasLockRecovery />}
                />

                <div className='event-block disabled-block flex flex-col space-y-5'>
                    <div className='flex flex-row space-x-4'>
                        <div className='manage-asset-form-control w-1/2'>
                            <label className='label'>Event subject</label>
                            <input
                                type='text'
                                className='time-input-field'
                                placeholder='Subject'
                            />
                        </div>
                        <div className='manage-asset-form-control w-1/2'>
                            <label className='label'>Event type</label>
                            <input
                                type='text'
                                className='time-input-field'
                                placeholder='Event type'
                            />
                        </div>
                    </div>
                    <div className='manage-asset-form-control description-input-field w-full'>
                        <label className='label'>Description</label>
                        <textarea
                            className='time-input-field'
                            placeholder='Description'
                        />
                    </div>
                </div>
            </div>
            <div className='button-block flex justify-between items-center'>
                <div className='flex items-center'>
                    <button className='button'>
                        <img src={CheckCircle} />Controller
                    </button>
                </div>
                <div className='flex flex-row space-x-3 items-center'>
                    <button className='button'>
                        Clear
                    </button>
                    <button className='button disabled'>
                        Save
                    </button>
                </div>
            </div>

        </div>
    )
}

export default ManageAsset