import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getSleTimeSeriesData, getSleVariable } from './SleService';

export interface SleVariables {
  shortUnitOfMeasure: string;
  unitOfMeasure: string;
  variable: string;
  variableName: string
}

export interface DataPoints {
  date: string;
  value: number;
}

export interface SleTimeSeriesData {
  variable: string;
  trendName: string,
  variableName: string;
  unitOfMeasure: string;
  value: number;
  date: string;
  max: string;
  min: string;
  medean: string;
  median?: string;
  maxThreshold: string;
  minThreshold: string;
  dataPoints: DataPoints[];
  shortUnitOfMeasure: string;
}


interface SleTimeSeriesProps {
  loading: boolean
  variableLoading: boolean;
  timeSeriesDataLoading: boolean;
  variable: SleVariables[];
  sleTimeSeriesData: SleTimeSeriesData[];
  sleTimeSeriesDataSet: {
    data: SleTimeSeriesData[],
    aggregate: string,
    startDate: string | Date,
    endDate: string | Date,
  }[];
  initialDataLoading: boolean,
  granularDataLoading: boolean
}

const initialState: SleTimeSeriesProps = {
  loading: false,
  variableLoading: false,
  timeSeriesDataLoading: false,
  variable: [],
  sleTimeSeriesData: [],
  sleTimeSeriesDataSet: [],
  initialDataLoading: false,
  granularDataLoading: false
}

interface fetchSleTimeSeriesDataArgs {
  wellName: string,
  startDate: Date | string,
  endDate: Date | string,
  variables: string,
  aggregate: string,
  cancelToken?: any
  granularData: boolean
}


export const fetchSleTimeSeriesData = createAsyncThunk(
  'sle/Timeseries',
  async ({ wellName, startDate, endDate, variables, aggregate, cancelToken }: fetchSleTimeSeriesDataArgs) => {
    const response = await getSleTimeSeriesData(wellName, startDate, endDate, variables, aggregate, cancelToken);
    return response;
  },
);

export const fetchSleVariables = createAsyncThunk(
  'sle/Variables',
  async (assetName: string) => {
    const response = await getSleVariable(assetName);
    return response;
  },
);

const sleTimeSeriesSlice = createSlice({
  name: 'sleTimeSeries',
  initialState,
  reducers: {
    updateSleTrendTimeSeriesDataSet: (state, action) => {
      state.sleTimeSeriesDataSet = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSleTimeSeriesData.pending, (state, action) => {
      const granularData = action.meta.arg.granularData
      if (granularData) {
        state.granularDataLoading = true;
        state.initialDataLoading = false;
      } else {
        state.granularDataLoading = false;
        state.initialDataLoading = true;
      }
    });
    builder.addCase(fetchSleTimeSeriesData.fulfilled, (state, action) => {
      const aggregateValue = action.meta.arg.aggregate || ''
      const { granularData, startDate, endDate } = action.meta.arg

      // Find index of existing item with the same aggregate value
      const index = state.sleTimeSeriesDataSet?.findIndex((item) => item.aggregate === aggregateValue);

      if (index !== -1 && state.sleTimeSeriesDataSet) {
        // Update existing item
        state.sleTimeSeriesDataSet[index] = { ...state.sleTimeSeriesDataSet[index], data: action.payload.data, startDate: startDate, endDate: endDate };
      } else {
        // Add new item
        state.sleTimeSeriesDataSet.push({ aggregate: aggregateValue, data: action.payload.data, startDate: startDate, endDate: endDate });
      }
      if (!granularData)
        state.sleTimeSeriesData = action.payload.data;
      state.granularDataLoading = false;
      state.initialDataLoading = false;
    });
    builder.addCase(fetchSleTimeSeriesData.rejected, (state, action) => {
      if (action.error.message !== "canceled") {
        const aggregateValue = action.meta.arg.aggregate || ''
        const { granularData, startDate, endDate } = action.meta.arg

        const index = state.sleTimeSeriesDataSet?.findIndex((item) => item.aggregate === aggregateValue);

        if (index !== -1 && state.sleTimeSeriesDataSet) {
          // Update existing item
          state.sleTimeSeriesDataSet[index] = { ...state.sleTimeSeriesDataSet[index], data: [], startDate: startDate, endDate: endDate };
        } else {
          // Add new item
          state.sleTimeSeriesDataSet.push({ aggregate: aggregateValue, data: [], startDate: startDate, endDate: endDate });
        }

        if (!granularData)
          state.sleTimeSeriesData = [];
        state.granularDataLoading = false;
        state.initialDataLoading = false;
      }
    });

    builder.addCase(fetchSleVariables.pending, (state) => {
      state.variableLoading = true;
    });
    builder.addCase(fetchSleVariables.fulfilled, (state, action) => {
      state.variable = action.payload.data;
      state.variableLoading = false;
    });
    builder.addCase(fetchSleVariables.rejected, (state) => {
      state.variableLoading = false;
      state.variable = [];
    });

  }
})

export const { updateSleTrendTimeSeriesDataSet } = sleTimeSeriesSlice.actions;

export default sleTimeSeriesSlice.reducer;

