import React from 'react'
import './CardLayout.scss'

interface CardLayoutProps {
    title: string;
    children: React.ReactNode;
    headerRight?: React.ReactNode;
}

function CardLayout({title, children, headerRight}: CardLayoutProps) {
  return (
    <div className='card-layout'>
        <div className="card-layout-header">
            <h5 className="header-title">
                {title}
            </h5>
            {headerRight}
        </div>
        <div className="content">
            {children}
        </div>
    </div>
  )
}

export default CardLayout