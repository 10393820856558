import { createSlice } from "@reduxjs/toolkit";


interface AssetControlRoomDrawerProps {
    openState: boolean;
    selectedComponent: string | null;
}

const initialState: AssetControlRoomDrawerProps = {
    openState: false,
    selectedComponent: null
}

const assetControlRoomDrawerSlice = createSlice({
    name: 'assetControlRoomDrawer',
    initialState,
    reducers: {
        updateAssetControlRoomDrawerProps: (state, action) => {
            const { openState, selectedComponent } = action.payload
            state.openState = openState;
            state.selectedComponent = selectedComponent
        }
    }
});

export const { updateAssetControlRoomDrawerProps } = assetControlRoomDrawerSlice.actions;
export default assetControlRoomDrawerSlice.reducer;