import { http } from "../../../config/HttpCommon";

export const getWellProductionTrendsByAssetName = async (userId: string, startDate: string, endDate: string, assetName: string) => {
    const response = await http.get(`/getWellProductionTrends/${userId}/${startDate}/${endDate}/All Wells`,
        {
            params: {
                assetName: assetName
            }
        }
    );
    return response.data;
};