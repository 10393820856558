export enum Role {
    Admin = 1,
    Assetcontrol = 2,
    Readonly = 3
}

export enum Privilege {
    Admin = 'Admin',
    Assetcontrol = 'AssetControl',
    Readonly = 'ReadOnly'
}