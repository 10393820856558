import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import Loader from '../../../common/page-loader/ComponentLoader';
// import moment from 'moment-timezone';
// import NoData from '../../../dashboard/components/no-data/NoData';
import { updateAssetControlScanStatus } from '../../../asset/AssetControlSlice';
import { fetchSleTimeSeriesData, SleTimeSeriesData, updateSleTrendTimeSeriesDataSet } from '../sle-time-series/SleSlice';
import { GetAggregation } from '../../../../utilities/CommonFunctions';
import axios from 'axios';
import NoData from '../../../dashboard/components/no-data/NoData';

// interface DataProps {
//     name: string;
//     value: number;
//     uom: string;
//     short_UOM: string;
//     paramTypeId: any;
// }

// interface ChartData {
//     date: string;
//     data: DataProps[];
// }

interface HistoryChartDataProps {
    selectedOption?: string;
    selectedDateRange: {
        startDate: Date;
        endDate: Date;
        key: string;
    };
    filteredValues: string[];
    selectedVariables: string[]
}

const SleSmoothedXLineChart: React.FC<HistoryChartDataProps> = ({ selectedDateRange, filteredValues, selectedVariables }) => {
    const selectedAsset = useAppSelector((state) => state.assetGroups);
    const dispatch = useAppDispatch();
    const [showError, setShowError] = useState(false)
    const assetControlData = useAppSelector((state) => state?.assetControl)
    const { sleTimeSeriesDataSet, initialDataLoading, granularDataLoading, sleTimeSeriesData } = useAppSelector((state) => state.sleTimeSeries);
    const sleVariable = useAppSelector((state) => state.sleTimeSeries.variable);
    const [selectedDataUOM, setSelectedDataUOM] = useState<string[]>([])
    const aggregateValues = useAppSelector((state) => state?.assetDetail?.aggregateValue)
    const sleCancelTokenSource = useRef<ReturnType<typeof axios.CancelToken.source> | null>(null);
    const { assetDetail } = useAppSelector((state) => state?.assetDetail);
    const isSleStatus = assetDetail?.assetDetails.isSleAsset
    const currentAggregation = useRef('')
    const chartInstanceRef = useRef<am5xy.XYChart | null>(null);
    const prevZoomState = useRef({ start: 0, end: 1 })
    const [chartData, setChartData] = useState<SleTimeSeriesData[]>([])
    const colorHex: { [key: string]: string } = {
        'System frequency': '#FEC84B',
        'Vibration Y': '#FFD072',
        'DC bus voltage': '#006DFA',
        'Drive voltage': '#12B76A',
        'Vibration X': '#3A7E73',
        'Intake pressure': '#004EB3',
        'Motor temperature': '#F97066',
        'Intake temperature': '#F04438',
        'Casing pressure': '#6BBBAE',
        'Tubing pressure': '#0094BD',
        'Motor current': '#FB743C',
        'Downhole current': '#F97066',
        'System RPM': '#B8C5CC',
    };

    function setToStartOfDay(date: any) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    }
    function setToEndOfDay(date: any) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
    }
    // Ensure selectedDateRange.startDate is at the start of the day
    const startDate = setToStartOfDay(selectedDateRange.startDate);
    // Ensure selectedDateRange.endDate is at the end of the day
    const endDate = setToEndOfDay(selectedDateRange.endDate);




    useEffect(() => {
        if (!isSleStatus) return
        if (selectedVariables?.length === 0) return
        const wellName = selectedAsset.selectedAssetName ?? '';
        if (wellName === '') return;

        if (sleTimeSeriesDataSet?.length)
            dispatch(updateSleTrendTimeSeriesDataSet([]))
        currentAggregation.current = ''
        sleCancelTokenSource.current = axios.CancelToken.source();

        const startDate = new Date(selectedDateRange.startDate.getFullYear(), selectedDateRange.startDate.getMonth(), selectedDateRange.startDate.getDate(), 0, 0, 0, 0).toISOString();
        const endDate = new Date(selectedDateRange.endDate.getFullYear(), selectedDateRange.endDate.getMonth(), selectedDateRange.endDate.getDate(), 23, 59, 59, 999).toISOString();


        const aggregateValue = GetAggregation(new Date(startDate), new Date(endDate), aggregateValues)
        if (currentAggregation.current !== aggregateValue) {
            currentAggregation.current = aggregateValue
        }

        dispatch(fetchSleTimeSeriesData({ wellName: wellName, startDate: startDate, endDate: endDate, variables: selectedVariables.toString() || '', aggregate: aggregateValue, cancelToken: sleCancelTokenSource?.current?.token, granularData: false }))

        if (assetControlData?.assetControlScanStatus)
            dispatch(updateAssetControlScanStatus(false))
        return () => {
            if (sleCancelTokenSource.current) {
                sleCancelTokenSource.current.cancel('canceled');
            }
        };
    }, [selectedDateRange.startDate, selectedDateRange.endDate, JSON.stringify(selectedVariables), assetControlData?.assetControlScanStatus === true]);

    useEffect(() => {
        if (sleTimeSeriesData?.length) {
            const filteredArray = sleTimeSeriesData?.filter((item) => filteredValues?.includes(item.variable))
            const uoms = filteredArray?.map(item => item?.shortUnitOfMeasure)
            const uniqueUoms = Array.from(new Set(uoms));
            setSelectedDataUOM(uniqueUoms);
            setChartData(filteredArray)
        } else {
            setSelectedDataUOM([])
            setChartData([])
        }
    }, [sleTimeSeriesData, filteredValues])

    useEffect(() => {
        if (sleTimeSeriesDataSet?.length > 1) {
            dispatch(updateSleTrendTimeSeriesDataSet([{ ...sleTimeSeriesDataSet[0] }]))
        }
    }, [filteredValues])

    const getData = async (start: any, end: any, zoomDirection = 'in') => {
        if (sleCancelTokenSource.current) {
            sleCancelTokenSource.current.cancel('canceled');
        }

        const { store } = await import('../../../../store');

        const sleTimeSeriesDataSet1 = store.getState().sleTimeSeries.sleTimeSeriesDataSet;
        sleCancelTokenSource.current = axios.CancelToken.source();

        const wellName = selectedAsset?.selectedAssetName ?? '';
        const startDate = new Date(start).toISOString();
        const endDate = new Date(end).toISOString();
        const aggregateValue = GetAggregation(new Date(start), new Date(end), aggregateValues)
        if (currentAggregation.current !== aggregateValue) {
            currentAggregation.current = aggregateValue
        }
        const matchedRecord = sleTimeSeriesDataSet1?.find((item) => item.aggregate === aggregateValue)
        let matchedData = matchedRecord?.data
        if (!matchedData) {
            await dispatch(fetchSleTimeSeriesData({ wellName: wellName, startDate: startDate, endDate: endDate, variables: selectedVariables.toString() || '', aggregate: aggregateValue, cancelToken: sleCancelTokenSource?.current?.token, granularData: true }))
                .unwrap()
                .then((response) => {
                    const data = response.data
                    matchedData = data
                })
                .catch((error) => {
                    console.error('Failed to fetch fluid tab details:', error);
                    matchedData = []
                })
        } else {
            if (zoomDirection === 'out' || zoomDirection === 'in') {
                const storedDataStartDate = matchedRecord?.startDate ? new Date(matchedRecord?.startDate) : new Date(0)
                const storedDataEndDate = matchedRecord?.endDate ? new Date(matchedRecord?.endDate) : new Date(0)
                const startDateDifference = Math.abs(storedDataStartDate.getTime() - new Date(startDate).getTime());
                const endDateDifference = Math.abs(storedDataEndDate.getTime() - new Date(endDate).getTime());

                if ((storedDataStartDate > new Date(startDate) && startDateDifference > 1 * 1000) || (storedDataEndDate < new Date(endDate) && endDateDifference > 1 * 1000)) {
                    await dispatch(fetchSleTimeSeriesData({ wellName: wellName, startDate: startDate, endDate: endDate, variables: selectedVariables.toString() || '', aggregate: aggregateValue, cancelToken: sleCancelTokenSource?.current?.token, granularData: true }))
                        .unwrap()
                        .then((response) => {
                            const data = response.data
                            matchedData = data
                        })
                        .catch((error) => {
                            console.error('Failed to fetch fluid tab details:', error);
                            matchedData = []
                        })
                }
            }
        }

        const convertedArray = matchedData?.map((item) => ({ ...item, trendName: sleVariable?.find((sleVariable) => sleVariable.variable === item.variable)?.variableName || '' }))
        return convertedArray
    }
    function createSeries(
        chart: am5xy.XYChart,
        root: am5.Root,
        name: string,
        field: string,
        color: string,
        xAxis: am5xy.DateAxis<am5xy.AxisRendererX>,
        yAxis: am5xy.ValueAxis<am5xy.AxisRendererY>,
        data: any[],
        cursor: any,
    ) {
        const series = chart.series.push(
            am5xy.SmoothedXLineSeries.new(root, {
                name: name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: 'value',
                valueXField: 'date',
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: 'left',
                    y: am5.percent(50),
                    forceHidden: true,
                }),
                snapTooltip: true,
                stroke: am5.color(color),
                fill: am5.color(color),
            }),
        );

        series.strokes.template.set('strokeWidth', 2);

        series.bullets.push(() => {
            const circle = am5.Circle.new(root, {
                radius: 0,
                fill: am5.color('#000'),
                stroke: am5.color('#fff'),
                strokeWidth: 3,
                interactive: true,
                // visible: true,
                opacity: 0,
            });

            circle.states.create('default', {
                opacity: 0,
            });

            circle.states.create('hover', {
                radius: 6,
                opacity: 1,
            });

            return am5.Bullet.new(root, {
                locationX: 0.5,
                sprite: circle,
            });
        });

        cursor.events.on('cursormoved', cursorMoved);

        let previousBulletSprites: any = [];
        function cursorMoved() {
            for (let i = 0; i < previousBulletSprites?.length; i++) {
                previousBulletSprites[i]?.unhover();
            }
            previousBulletSprites = [];
            chart.series.each(function (series) {
                const dataItem = series?.get('tooltip')?.dataItem;
                if (dataItem) {
                    const bulletSprite = dataItem?.bullets?.length && dataItem?.bullets[0]?.get('sprite');
                    bulletSprite && bulletSprite.hover();
                    previousBulletSprites.push(bulletSprite);
                }
            });

            const cursorPoint = cursor.getPrivate("point");
            const chartWidth = chart.width(); // Get the total chart width
            const tooltip = chart.plotContainer.get("tooltip");
            const toolTipPosition = tooltip?.get("pointerOrientation")
            if (cursorPoint) {
                const xPos = cursorPoint.x;
                if (xPos < chartWidth / 3) {
                    if (toolTipPosition !== "left") {
                        tooltip?.set("pointerOrientation", "left");
                        tooltip?.set('dx', 15)
                    }
                } else {
                    if (toolTipPosition !== "right") {
                        tooltip?.set("pointerOrientation", "right");
                        tooltip?.set('dx', -15)
                    }
                }
            }

            let show = true;
            let html = `<div style="width:auto;display:flex;flex-direction:column;font-size: 13px;color: #F7F9F9;font-family: 'Mulish';font-weight: 400">`;
            chart.series.each(function (series) {
                const tooltipDataItem = series.get('tooltipDataItem');
                if (tooltipDataItem) {
                    if (show) {
                        const valueX = tooltipDataItem.get("valueX");
                        if (valueX != null) { // Ensure valueX is not null or undefined
                            const date: any = new Date(valueX);
                            const dateOptions: any = { weekday: 'long', month: 'short', day: 'numeric' };
                            const formattedDate = date.toLocaleDateString(undefined, dateOptions);
                            const timeOptions: any = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
                            const formattedTime = date.toLocaleTimeString(undefined, timeOptions);
                            const result = `${formattedDate}, ${formattedTime}`;
                            // const d = moment(tooltipDataItem.get('valueX')).format('dddd, MMM D, HH:mm:ss');
                            html += `<div style="border-bottom: 1px solid #4A5463;padding:10px"> ${result}</div>`;
                            show = false;
                        }
                    }

                    const valueY = tooltipDataItem.get('valueY');
                    const seriesName = series.get('name') || 'Unnamed Series';
                    const seriesColor = series.get('stroke')?.toString() || '#000000';
                    if (series.isVisible() === true) {
                        html += `<div style="display: flex; align-items: center;flex-direction: row;gap: 20px;justify-content: space-between;padding:5px">
            <div style="height:24px;align-items: center;display: flex">
            <span style="border:1px solid ${seriesColor};height:24px;margin-right: 5px;"></span>
            <span>${seriesName}</span>
            </div>
            <div>${valueY == null
                                ? ''
                                : +valueY.toFixed(3) +
                                (series.get('name') === 'System frequency'
                                    ? ' Hz'
                                    : series.get('name') === 'Intake temperature' || series.get('name') === 'Motor temperature'
                                        ? ' °F'
                                        : series.get('name') === 'Drive voltage' || series.get('name') === 'DC bus voltage'
                                            ? ' V'
                                            : series.get('name') === 'Intake pressure' ||
                                                series.get('name') === 'Tubing pressure' ||
                                                series.get('name') === 'Casing pressure'
                                                ? ' PSI'
                                                : series.get('name') === 'Motor current' || series.get('name') === 'Downhole current'
                                                    ? ' A'
                                                    : series.get('name') === 'System RPM'
                                                        ? ' RPM' : series.get('name') === 'Vibration X' || series.get('name') === 'Vibration Y' ? ' g'
                                                            : '')
                            }</div>
            </div>`;
                    }
                }
            });

            html += '</div>';
            chart.plotContainer.set('tooltipHTML', html);
        }

        const convertedArray = [...data]?.map((item) => ({ ...item, date: new Date(item.date).getTime() }))
        const sortedData = convertedArray.sort((a, b) => a.date - b.date);
        series.data.setAll(sortedData);



    }

    const handleStartEndChange = (xAxis: any) => {
        setTimeout(async () => {
            const startTimestamp = xAxis.getPrivate("selectionMin") ?? 0;
            const endTimestamp = xAxis.getPrivate("selectionMax") ?? 0;
            const start = xAxis.get("start") || 0;
            const end = xAxis.get("end") || 1;
            if (start >= 0 || end <= 1) {
                const zoomDirection = (prevZoomState.current.start < start || prevZoomState.current.end > end) ? 'in' : 'out'
                prevZoomState.current = { start: start, end: end }
                const apiData = await getData(new Date(startTimestamp), new Date(endTimestamp), zoomDirection)
                const seriesArray = Array.from(chartInstanceRef?.current?.series || []);

                apiData?.forEach((element: any) => {
                    // Find the variable name based on the current element's variable
                    const variableName = sleVariable.find(item => item.variable === element.variable)?.variableName || '';

                    // Find the series in seriesArray that has a name matching variableName
                    const matchedSeries = seriesArray?.find(series => series?.get('name') === variableName);

                    if (matchedSeries) {
                        const convertedArray = [...element.dataPoints]?.map((item) => ({ ...item, date: new Date(item.date).getTime() }))
                        const sortedData = convertedArray.sort((a, b) => a.date - b.date);
                        matchedSeries.data.setAll(sortedData)
                    }
                });
            }

        }, 50);

    };

    function debounce(func: any, wait: number) {
        let timeout: any;
        return function (...args: any[]) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    }
    useLayoutEffect(() => {
        if (selectedDataUOM.length > 0 && chartData.length > 0 && !initialDataLoading) {
            const root = am5.Root.new('chartdiv');

            root.setThemes([am5themes_Animated.new(root)]);

            const chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panY: false,
                    wheelY: 'zoomX',
                    layout: root.verticalLayout,
                    maxTooltipDistance: 0,
                    paddingTop: 50,
                }),
            );


            // const data = chartData?.map((item) => {
            //     const date = new Date(item.date).getTime();
            //     const result: any = { date };
            //     item.data.forEach((entry) => {
            //         result[entry.name] = entry.value;
            //     });
            //     return result;
            // });

            const xAxis = chart.xAxes.push(
                am5xy.DateAxis.new(root, {
                    maxDeviation: 0.2,
                    groupData: false,
                    extraMax: 0,
                    extraMin: 0,
                    strictMinMax: true,
                    min: startDate.getTime(),
                    max: endDate.getTime(),
                    baseInterval: { timeUnit: 'second', count: 1 },
                    renderer: am5xy.AxisRendererX.new(root, {
                        minGridDistance: 100,
                        stroke: am5.color('#FFFFFF'),
                    }),
                }),
            );

            xAxis.get('renderer').labels.template.set('fill', am5.color('#fff'));
            xAxis.get('renderer').labels.template.set('paddingTop', 20);
            xAxis.on("end", debounce(() => handleStartEndChange(xAxis), 500));

            const yAxis1 = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    extraTooltipPrecision: 1,
                    renderer: am5xy.AxisRendererY.new(root, {
                        opposite: false,
                    }),
                }),
            );

            yAxis1.get('renderer').labels.template.set('fill', am5.color('#fff'));

            const yAxis0 = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    extraTooltipPrecision: 1,
                    renderer: am5xy.AxisRendererY.new(root, {
                        opposite: false,
                    }),
                }),
            );

            yAxis0.get('renderer').labels.template.set('fill', am5.color('#fff'));

            if (selectedDataUOM.includes('g')) {
                const yAxis0Label = am5.Label.new(root, {
                    text: 'g',
                    fill: am5.color('#fff'),
                    rotation: 0,
                    centerX: am5.p50,
                    centerY: am5.p50,
                    x: 15,
                    y: -30,
                    fontSize: 12,
                    fontWeight: 'bold',
                });
                yAxis0.children.push(yAxis0Label);
            }


            const yAxis2 = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    extraTooltipPrecision: 1,
                    renderer: am5xy.AxisRendererY.new(root, {
                        opposite: false,
                    }),
                }),
            );

            yAxis2.get('renderer').labels.template.set('fill', am5.color('#fff'));

            if (selectedDataUOM.includes('A')) {
                const yAxis2Label = am5.Label.new(root, {
                    text: 'A',
                    fill: am5.color('#fff'),
                    rotation: 0,
                    centerX: am5.p50,
                    centerY: am5.p50,
                    x: 15,
                    y: -30,
                    fontSize: 12,
                    fontWeight: 'bold',
                });
                yAxis2.children.push(yAxis2Label);
            }

            const yAxis3 = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    extraTooltipPrecision: 1,
                    renderer: am5xy.AxisRendererY.new(root, {
                        opposite: false,
                    }),
                }),
            );

            yAxis3.get('renderer').labels.template.set('fill', am5.color('#fff'));

            if (selectedDataUOM.includes('psi')) {

                const yAxis3Label = am5.Label.new(root, {
                    text: 'PSI',
                    fill: am5.color('#fff'),
                    rotation: 0,
                    centerX: am5.p50,
                    centerY: am5.p50,
                    x: 15,
                    y: -30,
                    fontSize: 12,
                    fontWeight: 'bold',
                });
                yAxis3.children.push(yAxis3Label);
            }

            const yAxis4 = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    extraTooltipPrecision: 1,
                    renderer: am5xy.AxisRendererY.new(root, {
                        opposite: true,
                    }),
                }),
            );

            yAxis4.get('renderer').labels.template.set('fill', am5.color('#fff'));

            if (selectedDataUOM.includes('F')) {
                const yAxis4Label = am5.Label.new(root, {
                    text: '°F',
                    fill: am5.color('#fff'),
                    rotation: 0,
                    centerX: am5.p50,
                    centerY: am5.p50,
                    x: 15,
                    y: -30,
                    fontSize: 12,
                    fontWeight: 'bold',
                });
                yAxis4.children.push(yAxis4Label);
            }

            const yAxis5 = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    extraTooltipPrecision: 1,
                    renderer: am5xy.AxisRendererY.new(root, {
                        opposite: true,
                    }),
                }),
            );

            yAxis5.get('renderer').labels.template.set('fill', am5.color('#fff'));

            if (selectedDataUOM.includes('Hz')) {
                const yAxis5Label = am5.Label.new(root, {
                    text: 'Hz',
                    fill: am5.color('#fff'),
                    rotation: 0,
                    centerX: am5.p50,
                    centerY: am5.p50,
                    x: 15,
                    y: -30,
                    fontSize: 12,
                    fontWeight: 'bold',
                });
                yAxis5.children.push(yAxis5Label);
            }

            const yAxis6 = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    extraTooltipPrecision: 1,
                    renderer: am5xy.AxisRendererY.new(root, {
                        opposite: true,
                    }),
                }),
            );

            yAxis6.get('renderer').labels.template.set('fill', am5.color('#fff'));

            if (selectedDataUOM.includes('V')) {
                const yAxis6Label = am5.Label.new(root, {
                    text: 'V',
                    fill: am5.color('#fff'),
                    rotation: 0,
                    centerX: am5.p50,
                    centerY: am5.p50,
                    x: 15,
                    y: -30,
                    fontSize: 12,
                    fontWeight: 'bold',
                });
                yAxis6.children.push(yAxis6Label);
            }

            const yAxis7 = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    extraTooltipPrecision: 1,
                    renderer: am5xy.AxisRendererY.new(root, {
                        opposite: true,
                    }),
                }),
            );

            yAxis7.get('renderer').labels.template.set('fill', am5.color('#fff'));

            if (selectedDataUOM.includes('RPM')) {
                const yAxis7Label = am5.Label.new(root, {
                    text: 'RPM',
                    fill: am5.color('#fff'),
                    rotation: 0,
                    centerX: am5.p50,
                    centerY: am5.p50,
                    x: 15,
                    y: -30,
                    fontSize: 12,
                    fontWeight: 'bold',
                });
                yAxis7.children.push(yAxis7Label);
            }

            const cursor = chart.set(
                'cursor',
                am5xy.XYCursor.new(root, {
                    xAxis: xAxis,
                    yAxis: yAxis1,
                    behavior: 'selectXY',
                    showTooltipOn: 'hover',
                }),
            );
            cursor.lineY.set('visible', false);
            cursor.lineX.setAll({
                visible: true,
                stroke: am5.color('#ADD8E6'),
                strokeWidth: 2,
            });

            cursor.lineY.setAll({
                strokeDasharray: [2, 2],
            });

            const tooltip: any = am5.Tooltip.new(root, {
                pointerOrientation: 'right',
                dx: -15,
            });

            tooltip.get('background').setAll({
                fill: am5.color('#001023'),
                stroke: am5.color('#4A5463'),
                opacity: 1,
            });

            chart.plotContainer.set('tooltipPosition', 'pointer');
            chart.plotContainer.set('tooltipHTML', '<div >' + '' + '</div>');
            chart.plotContainer.set('tooltip', tooltip);

            chartData?.forEach((element: any) => {
                const variableName = sleVariable.find(item => item.variable === element.variable)?.variableName || ''
                const colordata = colorHex[variableName] ? colorHex[variableName] : '#60BFDA';
                if (element.shortUnitOfMeasure?.toLowerCase() === 'a') {

                    createSeries(
                        chart,
                        root,
                        variableName,
                        element?.name,
                        colordata,
                        xAxis as am5xy.DateAxis<am5xy.AxisRendererX>,
                        yAxis2 as am5xy.ValueAxis<am5xy.AxisRendererY>,
                        element.dataPoints,
                        cursor,
                    );
                } else if (element.shortUnitOfMeasure.toLowerCase() === 'psi') {
                    createSeries(
                        chart,
                        root,
                        variableName,
                        element?.name,
                        colordata,
                        xAxis as am5xy.DateAxis<am5xy.AxisRendererX>,
                        yAxis3 as am5xy.ValueAxis<am5xy.AxisRendererY>,
                        element.dataPoints,
                        cursor,
                    );
                } else if (element.shortUnitOfMeasure.toLowerCase() === 'f') {
                    createSeries(
                        chart,
                        root,
                        variableName,
                        element?.name,
                        colordata,
                        xAxis as am5xy.DateAxis<am5xy.AxisRendererX>,
                        yAxis4 as am5xy.ValueAxis<am5xy.AxisRendererY>,
                        element.dataPoints,
                        cursor,
                    );
                } else if (element.shortUnitOfMeasure.toLowerCase() === 'hz') {
                    createSeries(
                        chart,
                        root,
                        variableName,
                        element?.name,
                        colordata,
                        xAxis as am5xy.DateAxis<am5xy.AxisRendererX>,
                        yAxis5 as am5xy.ValueAxis<am5xy.AxisRendererY>,
                        element.dataPoints,
                        cursor,
                    );
                } else if (element.shortUnitOfMeasure.toLowerCase() === 'v') {
                    createSeries(
                        chart,
                        root,
                        variableName,
                        element?.name,
                        colordata,
                        xAxis as am5xy.DateAxis<am5xy.AxisRendererX>,
                        yAxis6 as am5xy.ValueAxis<am5xy.AxisRendererY>,
                        element.dataPoints,
                        cursor,
                    );
                } else if (element.shortUnitOfMeasure.toLowerCase() === 'rpm') {
                    createSeries(
                        chart,
                        root,
                        variableName,
                        element?.name,
                        colordata,
                        xAxis as am5xy.DateAxis<am5xy.AxisRendererX>,
                        yAxis7 as am5xy.ValueAxis<am5xy.AxisRendererY>,
                        element.dataPoints,
                        cursor,
                    );
                } else if (element.shortUnitOfMeasure.toLowerCase() === 'g') {
                    createSeries(
                        chart,
                        root,
                        variableName,
                        element?.name,
                        colordata,
                        xAxis as am5xy.DateAxis<am5xy.AxisRendererX>,
                        yAxis0 as am5xy.ValueAxis<am5xy.AxisRendererY>,
                        element.dataPoints,
                        cursor,
                    );
                } else {
                    createSeries(
                        chart,
                        root,
                        variableName,
                        element?.name,
                        colordata,
                        xAxis as am5xy.DateAxis<am5xy.AxisRendererX>,
                        yAxis1 as am5xy.ValueAxis<am5xy.AxisRendererY>,
                        element.dataPoints,
                        cursor,
                    );
                }
            });

            const legend = chart.children.push(
                am5.Legend.new(root, {
                    x: am5.percent(50),
                    centerX: am5.percent(50),
                    layout: am5.GridLayout.new(root, {
                        maxColumns: 6,
                        fixedWidthGrid: true,
                    }),
                    setStateOnChildren: false,
                    paddingTop: 15,
                    useDefaultMarker: true,
                }),
            );

            legend.markerRectangles.template.setAll({
                cornerRadiusTL: 10,
                cornerRadiusTR: 10,
                cornerRadiusBL: 10,
                cornerRadiusBR: 10,
                width: 12,
                height: 12,
            });

            legend.labels.template.set('fill', am5.color(0xffffff));
            legend.data.setAll(chart.series.values);
            legend.itemContainers.template.states.create('hover', {});

            chartInstanceRef.current = chart

            return () => {
                root.dispose();
            };
        }

    }, [chartData, selectedDataUOM, initialDataLoading]);



    useEffect(() => {
        if ((!chartData || selectedDataUOM.length === 0) && !initialDataLoading) {
            const timer = setTimeout(() => {
                setShowError(true);
            }, 500);

            return () => clearTimeout(timer);
        } else {
            setShowError(false);
        }
    }, [chartData, initialDataLoading]);


    return (
        <div className='history-chart-container' style={{ minHeight: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {initialDataLoading ? (
                <div className='chart-loader'>
                    <Loader />
                </div>
            ) : (chartData?.length > 0 && selectedDataUOM.length > 0) ? (
                <div id='chartdiv' style={{
                    width: "100%",
                    height: "500px",
                    opacity: granularDataLoading ? 0.5 : 1,
                    pointerEvents: granularDataLoading ? "none" : "auto",
                    position: 'relative'
                }} className={chartData ? '' : 'center-content'}>
                    <>
                        {granularDataLoading && (
                            <div className='trend-loader flex items-center justify-center w-100' style={{ position: 'absolute', top: '50%', opacity: 'auto' }}>
                                <Loader />
                            </div>
                        )
                        }
                    </>

                </div>
            ) : (
                showError && (
                    <div className='flex flex-column items-center justify-center'>
                        <NoData heading='No data found' />
                    </div>
                )
            )}
        </div>
    );
};

export default SleSmoothedXLineChart;
