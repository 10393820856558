import React, { useEffect, useState } from 'react';
import CustomModal from '../ExpandModel';
import Close from '../../../../../images/x-close.svg';
import { ShutdownByReason } from '../../../model/ShutdownByReason';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { useAppSelector } from '../../../../../hooks/storeHooks';
import { ShutdownByReasonDetails } from '../../../model/ShutdownByReasonDetails';
import NoData from '../../no-data/NoData';
import Loader from '../../../../common/page-loader/ComponentLoader';
import { ReactComponent as SortIconDefault } from '../../../../../images/Chevron-default-icon.svg';
import { ReactComponent as SortIconUp } from '../../../../../images/Chevron-up-icon.svg';
import { ReactComponent as SortIconDown } from '../../../../../images/Chevron-down-icon.svg';

interface ShutdownByReasonModelProps {
  isOpen: boolean;
  onRequestClose: () => void;
  heading: string;
  subHeading: string;
  count: number | undefined;
  shutdownByReason?: ShutdownByReason[];
  loading: boolean;
  modalWidth?: string;
  openModalForPDF?: boolean;
}

type GroupedShutdownByReasonDetails = {
  operatorId: number;
  operator: string;
  [key: string]: number | string;
}

// type SortableKeys = keyof ShutdownByReasonDetails;

const ShutdownByReasonModel: React.FC<ShutdownByReasonModelProps> = ({
  isOpen,
  onRequestClose,
  heading,
  subHeading,
  count,
  shutdownByReason,
  loading,
  modalWidth,
  openModalForPDF,
}) => {
  const shutdownByReasonDetails = useAppSelector((state) => state.dashboard.shutdownByReasonDetails);


  const [sortConfig, setSortConfig] = React.useState<{ key: string; direction: 'asc' | 'desc' } | null>({
    key: 'operator',
    direction: 'asc',
  });

  const [column, setColumn] = useState<string[]>([]);
  const [groupedData, setGroupedData] = useState<GroupedShutdownByReasonDetails[] | null>(null)

  const colorCode: string[] = [
    '#003747', '#00789e', '#60bfda', '#86cfe3', '#a6dbea', '#dcf1f7', '#005f7c',
    "#912018", "#991A0F", "#93370D", "#05603A", "#004654", "#3A7E73",
    "#036A64", "#932F19", "#C78500", "#002F6C", "#FECDCA", "#FEDF89",
    "#A6F4C5", "#FABD85", "#FFE5B2", "#B8D7FF", "#D0E4FF", "#FFEECC", "#FBD0A8",
    "#cbe6e4", "#CEE8E4", "#CDE5EA", "#D1FADF", "#FEF0C7", "#FEE4E2", "#FDC3AB", "#C3E7F1"
  ];
  const initializeChart = (chartId: string, data: ShutdownByReason[]): am5.Root => {
    const root = am5.Root.new(chartId);
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.horizontalLayout,
        innerRadius: am5.percent(50),
      }),
    );

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        valueField: 'value',
        categoryField: 'status',
        legendLabelText: '[#FFFFFF]{category}[/]',
        legendValueText: '[bold #FFFFFF]{value}[/]',
      }),
    );
    series.slices.template.setAll({
      stroke: am5.color('#FFFFFF'),
      strokeWidth: 2,
    });
    series.data.setAll(data);

    const dataItemsArray = Array.from(series.dataItems.values());
    dataItemsArray.forEach((dataItem, index) => {
      const sliceColor = colorCode[index] ?? '#FDC3AB'
      dataItem.set('fill', am5.color(sliceColor));
    });

    // Hide labels and ticks
    series.labels.template.set('forceHidden', true);
    series.ticks.template.set('forceHidden', true);

    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerY: am5.percent(50),
        y: am5.percent(50),
        layout: root.verticalLayout,
        height: am5.percent(100),
        verticalScrollbar: am5.Scrollbar.new(root, {
          orientation: "vertical",
          background: am5.Rectangle.new(root, {
            fill: am5.color("#131F2E"),
            // var(--Darkmode-700, #131F2E)
            width: 6,
            height: am5.percent(100),
          })
        })
      })
    );

    const scrollbar = legend.get("verticalScrollbar");
    scrollbar?.thumb.setAll({
      fill: am5.color("#73777B"),
      width: 7,
      height: 20,
    });

    legend.valueLabels.template.setAll({ textAlign: 'right' });
    legend.labels.template.setAll({
      maxWidth: 180,
      width: 150,
      oversizedBehavior: 'wrap',
    });
    //For making the legend circular
    legend.markerRectangles.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10,
      width: 10,
      height: 10,
    });
    legend.markerRectangles.template.setAll({
      y: 4,
    });
    legend.data.setAll(series.dataItems);

    return root;
  };

  useEffect(() => {
    let root: am5.Root;
    if (isOpen && shutdownByReason && count && !loading) {
      setTimeout(() => {
        root = initializeChart('shutdownByReasonModaldiv', shutdownByReason);
      }, 0);
    }
    return () => {
      if (root) {
        root.dispose();
      }
    };
  }, [isOpen, shutdownByReason, count, heading, loading]);


  useEffect(() => {
    if (shutdownByReason != null) {
      const distinctStatuses = Array.from(new Set(shutdownByReason?.map((item) => item.status)));
      setColumn(distinctStatuses)
    }
  }, [shutdownByReason]);

  useEffect(() => {
    const groupData = (data: ShutdownByReasonDetails[] | null): GroupedShutdownByReasonDetails[] => {
      const groupedData: { [key: string]: GroupedShutdownByReasonDetails } = {};

      data?.forEach(item => {
        const key = `${item.operatorId}`;
        if (!groupedData[key]) {
          groupedData[key] = {
            operatorId: item.operatorId,
            operator: item.operator,
          };
        }
        groupedData[key][removeShutdown(item.status)] = item.value;
      });
      return Object?.values(groupedData);
    };

    const groupedData = groupData(shutdownByReasonDetails);
    setGroupedData(groupedData)
  }, [shutdownByReasonDetails])

  const requestSort = (key: string) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig?.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };


  const removeShutdown = (key: string) => {
    const statusWithoutShutdown = key?.replace(/shutdown/gi, '').trim();
    const cleanStatus = statusWithoutShutdown.replace(/^[-,]+/, '').trim()
    const formatedStatus = cleanStatus?.length > 1 ? cleanStatus : key;
    return formatedStatus;
  }

  const sortedData = React.useMemo(() => {
    if (sortConfig === null) return groupedData;
    if (groupedData != null) {

      if (sortConfig.key === 'operator') {
        return [...groupedData].sort((a, b) => {
          const aValue = a.operator
          const bValue = b.operator
          if (aValue == null || bValue == null) return 0;
          if (typeof aValue === 'string' && typeof bValue === 'string') {
            return aValue.localeCompare(bValue) * (sortConfig.direction === 'asc' ? 1 : -1);
          }
          return aValue < bValue
            ? sortConfig.direction === 'asc'
              ? -1
              : 1
            : aValue > bValue
              ? sortConfig.direction === 'asc'
                ? 1
                : -1
              : 0;
        });
      } else {
        return [...groupedData].sort((a, b) => {
          const aValue = a[sortConfig.key] ?? 0;
          const bValue = b[sortConfig.key] ?? 0;
          if (typeof aValue === 'number' && typeof bValue === 'number') {
            return (aValue - bValue) * (sortConfig.direction === 'asc' ? 1 : -1);
          }
          return aValue < bValue
            ? sortConfig.direction === 'asc'
              ? -1
              : 1
            : aValue > bValue
              ? sortConfig.direction === 'asc'
                ? 1
                : -1
              : 0;
        });
      }
    }
  }, [groupedData, sortConfig]);


  return (
    <CustomModal isOpen={isOpen} onRequestClose={onRequestClose} width={modalWidth} openModalForPDF={openModalForPDF}>
      <div className='modal-header'>
        <div className='flex'>
          <div className='grow'>
            <h3>{heading}</h3>
          </div>
          <div className='flex-none'>
            <button onClick={onRequestClose}>
              <img src={Close} alt='Expand' />
            </button>
          </div>
        </div>
      </div>
      <div id='shutdownByReasonModal-container' className='modal-content'>
        {loading ? (
          <div className='flex items-center justify-center loader-margin'>
            <Loader />
          </div>
        ) : count ? (
          <div className='chart-wrap'>
            <div className='pie-container-dashboard' style={{ width: '80%', margin: '0 auto' }}>
              <div className='total-count'>
                <p>{subHeading}</p>
                <h2>{count}</h2>
              </div>
              <div id='shutdownByReasonModaldiv' className='chart-wrapper'></div>
            </div>
            <div className='table-section' >
              <div className='performance-snapshot-modal-table-wrap' >
                <table className={`${column?.length > 6 ? 'shutdown-max-wrap shutdown-by-reason-table' : 'shutdown-normal-wrap'}`} >
                  <thead >
                    <tr className='table-header-flex'>
                      <th onClick={() => requestSort('operator')} className='operator'>
                        Operator {sortConfig?.key === 'operator' ? (
                          sortConfig.direction === 'asc' ? (
                            <SortIconUp alt='sort-asc' className='sort-img' />
                          ) : (
                            <SortIconDown alt='sort-dsc' className='sort-img' />
                          )
                        ) : (
                          <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                        )}
                      </th>

                      {
                        column?.length && column?.map((item) => {
                          return (
                            <th onClick={() => requestSort(item)} className='other-column'>
                              {item} {sortConfig?.key === item ? (
                                sortConfig.direction === 'asc' ? (
                                  <SortIconUp alt='sort-asc' className='sort-img' />
                                ) : (
                                  <SortIconDown alt='sort-dsc' className='sort-img' />
                                )
                              ) : (
                                <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                              )}
                            </th>
                          )
                        })
                      }

                    </tr>
                  </thead>
                  <tbody >
                    {sortedData?.map((shutdownByReason: GroupedShutdownByReasonDetails, index: number) => (
                      <tr key={index}>
                        <td className='operator'>{shutdownByReason?.operator}</td>
                        {
                          column?.length && column?.map((item) => {
                            return <td className='other-column'>{shutdownByReason[item] ?? 0}</td>
                          })
                        }

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        ) : (
          <NoData heading='No data found' />
        )}
      </div>
    </CustomModal>
  );
};

export default ShutdownByReasonModel;

