import React from 'react'
import './ControlRoomDashboard.scss'
import ControlRoomHeader from './components/header/ControlRoomHeader'
import TabWithUrlComponent from '../../components/TabWithUrl';
import ControlRoomWells from './components/Wells/ControlRoomWells';
import { ToastContainer } from 'react-toastify';

const ControlRoomDashboard = () => {
    const tabs = [
        { label: 'Wells', component: ControlRoomWells },
        { label: 'Tickets', component: () => <h1>Tickets</h1> },
        { label: 'Tasks', component: () => <h1>Tasks</h1> },
    ];
    return (
        <div className="control-room-component">
            <ToastContainer position='top-right' autoClose={3000} />
            <ControlRoomHeader />

            <TabWithUrlComponent initialTab={{ tab: 'Wells' }} tabs={tabs} value='Wells' />
        </div>
    )
}

export default ControlRoomDashboard