import { useState } from 'react';
import AlarmSubscriptionModal from './AlarmSubscriptionModal/AlarmSubscriptionModal';
import '../details-table.scss';
import { AlarmSubscriptionsProps } from '../AssetDetailProps';
// import { capitalizeFirstInWord } from '../../../../../utilities/CommonFunctions';
import WIP from '../../../../../images/wip1.svg';

interface AlarmProps {
  alarmSubscriptions?: AlarmSubscriptionsProps[];
}

const AlarmSubscriptions: React.FC<AlarmProps> = () => {
  const [modaldetail, setModaldetail] = useState<{ isModalOpen: boolean; selectedId: string }>({
    isModalOpen: false,
    selectedId: '',
  });
  const hElement = document.getElementById('main-root');

  const onClose = () => {
    setModaldetail({ ...modaldetail, isModalOpen: false });
    hElement && hElement?.classList.remove('modal-open');
  };

  return (
    <>
      <div className='details-header-section'>
        <div className='title'>
          <h1>Notification subscriptions</h1>
        </div>
        <div className='body'>
          <center>
            <img className='h-[170px]' src={WIP} alt='work in progress' />
            <p className='mt-6'>Coming Soon</p>
          </center>
        </div>
        {/* <div className='details-section'>
          <div className='details-table-row'>
            <div className='details-table'>
              <div style={{ width: '100%' }}>Name</div>
              <div style={{ width: '100%' }}>Title</div>
              <div style={{ width: '100%' }}>Company</div>
            </div>
            <hr />
            <div className='details-table-body'>
              {alarmSubscriptions?.map((value: AlarmSubscriptionsProps) => {
                return (
                  <>
                    <div className='details-table'>
                      <div
                        style={{ width: '100%', color: '#60BFDA', cursor: 'pointer' }}
                        onClick={() => {
                          setModaldetail({ isModalOpen: true, selectedId: value.id });
                          hElement && hElement?.classList.add('modal-open');
                        }}
                      >
                        {value?.name && capitalizeFirstInWord(value?.name)}
                      </div>
                      <div style={{ width: '100%' }}>{value?.jobTitle ?? '-'}</div>
                      <div style={{ width: '100%' }}>{value?.company && `@${value?.company}`}</div>
                    </div>
                    <hr />
                  </>
                );
              })}
            </div>
          </div>
        </div> */}
      </div>
      <AlarmSubscriptionModal
        onClose={onClose}
        isModalOpen={modaldetail.isModalOpen}
        selectedId={modaldetail.selectedId}
      />
    </>
  );
};
export default AlarmSubscriptions;
