import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {getAssetInfo, getAssetInfoEquipments, getAssetInfoEquipmentId, getAssetInfoEventTypes, getAssetInfoEvents} from './AssetInfoServices';
import { AssetDetails, AlarmSubscription, EquipmentItem, EquipmentIdInfo, EventTypeProps, EventsProps } from './AssetInfoTypes';

interface AssetInfoApiState {
  assetDetails: AssetDetails | null;
  alarmSubscriptions: AlarmSubscription[];
  equipments: EquipmentItem[];
  equipmentIdInfo: EquipmentIdInfo[];
  eventTypes: EventTypeProps[];
  events: EventsProps[];
  totalResultsCount: number;
  pageNumber: number;
  pageSize: number | null;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  loading: boolean;
  error: string | null;
  detailsLoading: boolean;
  eventsLoading: boolean;
  equipmentsLoading: boolean;
  equipmentAccordianLoading: boolean;
}

const initialState: AssetInfoApiState = {
  assetDetails: null,
  alarmSubscriptions: [],
  equipments: [],
  equipmentIdInfo: [],
  eventTypes: [],
  events: [], // Initialize empty array for events
  totalResultsCount: 0,
  pageNumber: 1,
  pageSize: null,
  hasPrevPage: false,
  hasNextPage: false,
  loading: false,
  error: null,
  detailsLoading: false,
  eventsLoading: false,
  equipmentsLoading: false,
  equipmentAccordianLoading: false,
};

  export const fetchAssetInfo = createAsyncThunk(
    'assetInfo/Data',
    async ({ assetName }: { assetName: string;}) => {
      const response = await getAssetInfo(assetName);
      return response;
    },
  );

  export const fetchAssetInfoEquipments = createAsyncThunk(
    'assetInfo/Equipments',
    async ({ assetName }: { assetName: string;}) => {
      const response = await getAssetInfoEquipments(assetName);
      return response;
    },
  );

  export const fetchAssetInfoByEquipmentId = createAsyncThunk(
    'assetInfo/EquipmentId',
    async ({ assetName, equipmentId }: { assetName: string; equipmentId: string}) => {
      const response = await getAssetInfoEquipmentId(assetName, equipmentId);
      return response;
    },
  );

  export const fetchAssetInfoEventTypes = createAsyncThunk(
    'assetInfo/EventTypes',
    async ({ assetName }: { assetName: string;}) => {
      const response = await getAssetInfoEventTypes(assetName);
      return response;
    },
  );

  export const fetchAssetInfoEvents = createAsyncThunk(
    'assetInfo/Events',
    async ({ assetName, SearchQuery, FilterBy, SortOrder, SortBy, PageNumber, PageSize}: { assetName: string, SearchQuery: string, FilterBy: string, SortOrder: string, SortBy: string, PageNumber: number, PageSize: number}) => {
      const response = await getAssetInfoEvents(assetName, SearchQuery, FilterBy, SortOrder, SortBy, PageNumber, PageSize);
      return response;
    },
  );

  
  const assetInfoSlice = createSlice({
    name: 'assetInfo',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchAssetInfo.pending, (state) => {
          state.loading = true;
          state.error = null;
          state.detailsLoading = true;
        })
        .addCase(fetchAssetInfo.fulfilled, (state, action) => {
          state.loading = false;
          state.assetDetails = action.payload.data.assetDetails;
          state.alarmSubscriptions = action.payload.data.alarmSubscriptions;
          state.detailsLoading = false;
        })
        .addCase(fetchAssetInfo.rejected, (state, action) => {
          state.loading = false;
          state.assetDetails = null;
          state.alarmSubscriptions = [];
          state.error = action.error.message || 'Failed to fetch data';
          state.detailsLoading = false;
        })

         // Handle the events types API response
        .addCase(fetchAssetInfoEventTypes.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchAssetInfoEventTypes.fulfilled, (state, action) => {
          state.loading = false;
          state.eventTypes = action.payload.data;
        })
        .addCase(fetchAssetInfoEventTypes.rejected, (state, action) => {
          state.loading = false;
          state.eventTypes = [];
          state.error = action.error.message || 'Failed to fetch event types';
        })
        
        // Handle the events API response
        .addCase(fetchAssetInfoEvents.pending, (state) => {
          state.loading = true;
          state.error = null;
          state.eventsLoading = true;
        })
        .addCase(fetchAssetInfoEvents.fulfilled, (state, action) => {
          state.loading = false;
          state.events = action.payload.data.results;
          state.totalResultsCount = action.payload.data.totalResultsCount;
          state.pageNumber = action.payload.data.pageNumber;
          state.pageSize = action.payload.data.pageSize;
          state.hasPrevPage = action.payload.data.hasPrevPage;
          state.hasNextPage = action.payload.data.hasNextPage;
          state.eventsLoading = false;
        })
        .addCase(fetchAssetInfoEvents.rejected, (state, action) => {
          state.loading = false;
          state.events = [];
          state.totalResultsCount = 0;
          state.error = action.error.message || 'Failed to fetch events';
          state.eventsLoading = false;
        })

        // Handle equipment API
        .addCase(fetchAssetInfoEquipments.pending, (state) => {
          state.loading = true;
          state.error = null;
          state.equipmentsLoading = true;
        })
        .addCase(fetchAssetInfoEquipments.fulfilled, (state, action) => {
          state.loading = false;
          state.equipments = action.payload.data;
          state.equipmentsLoading = false;
        })
        .addCase(fetchAssetInfoEquipments.rejected, (state, action) => {
          state.loading = false;
          state.equipments = [];
          state.error = action.error.message || 'Failed to fetch equipment data';
          state.equipmentsLoading = false;
        })

        // Handle the equipment ID API response
        .addCase(fetchAssetInfoByEquipmentId.pending, (state) => {
          state.loading = true;
          state.error = null;
          state.equipmentAccordianLoading = true;
        })
        .addCase(fetchAssetInfoByEquipmentId.fulfilled, (state, action) => {
          state.loading = false;
          state.equipmentIdInfo = action.payload.data;
          state.equipmentAccordianLoading = false;
        })
        .addCase(fetchAssetInfoByEquipmentId.rejected, (state, action) => {
          state.loading = false;
          state.equipmentIdInfo = [];
          state.error = action.error.message || 'Failed to fetch equipment data';
          state.equipmentAccordianLoading = false;
        });
    },
  });

  export default assetInfoSlice.reducer;