import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import annotation_plus from './../../../../images/expand-icon.svg';
import download from '../../../../../src/images/download-icon.svg';
import NoData from '../../../dashboard/components/no-data/NoData';
import Loader from '../../../common/page-loader/ComponentLoader';
import mockData from "../data/casingTubing.json";
import { CasingTubingDetailsProps, casingTubingData } from './interfaces/casing.interfaces';
import CasingTubingChart from './charts/CasingTubingChart';
import CasingModal from './modals/CasingModal';

const CasingTubingDetails: React.FC<CasingTubingDetailsProps> = ({heading}) => {
    const loading = false;  // Set loading based on your data fetching logic
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const cardChartRef = useRef<HTMLDivElement | null>(null);
    const modalChartRef = useRef<HTMLDivElement | null>(null);
    const [data, setData] = useState<casingTubingData>({ casing: [], tubing: [] });
    const hElement = document.getElementById('main-root');    

    const openModal = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setModalIsOpen(true);
        hElement && hElement?.classList.add('modal-open');
    };

    const handleClose = () => {
        setModalIsOpen(false);
        hElement && hElement?.classList.remove('modal-open');
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
          setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const getLast7DaysDate = () => {
        const today = new Date();
        const last7Days = new Date(today);
        last7Days.setDate(today.getDate() - 6);
        return last7Days;
    };

    const [selectedDateRange,] = useState({
        startDate: getLast7DaysDate(),
        endDate: new Date(),
        key: 'selection',
    });

    const fetchData = async () => {
        const transformedData: casingTubingData = {
            casing: mockData.casingTubingData.casing.map((item) => ({
                date: new Date(item.date).getTime(),
                value: item.value,
            })),
            tubing: mockData.casingTubingData.tubing.map((item) => ({
                date: new Date(item.date).getTime(),
                value: item.value,
            })),
        };
        setData(transformedData);
    }

    useEffect(() => {
        fetchData();
    }, [selectedDateRange.startDate, selectedDateRange.endDate]);

    

  return (
    <>
        <ToastContainer position='top-right' autoClose={3000} />
        <div className='grid-item-text h-full graph-card well-graph-card'>
            <div className='table-header-section pie-section'>
                <div className='title'>{heading}</div>
                <div className='header-icon'>
                    <button className='expand-btn cancelSelectorName' onClick={openModal} style={{ cursor: 'pointer', zIndex: 0 }}>
                        <img src={annotation_plus} alt='Expand' className='img-border' />
                    </button>
                </div>
                <div className='mr-4 dropdown-container' ref={dropdownRef}>
                    <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                        <span className='dot'></span>
                        <span className='dot'></span>
                        <span className='dot'></span>
                    </button>
                    {isDropdownOpen && (
                    <div className='dropdown-options'>
                        <button>Actions</button>
                        <button className={`btn btn-default cancelSelectorName`}>
                            <img src={download} alt='icon' className='btn-icon' />
                            Download PDF
                        </button>
                        <button className={`btn btn-default cancelSelectorName`}>
                            <img src={download} alt='icon' className='btn-icon' />
                            Download CSV
                        </button>
                    </div>
                    )}
                </div>
            </div>
            <hr />
            <div id="casingTubing-container" className='h-full wellChartSection casingTubingChartSection'>
                {loading ? (
                    // <div className='well-production-loader'>
                    <Loader />
                    // </div> 
                ) : data?.casing?.length > 0 || data?.tubing?.length > 0 ? (
                    <>
                        <CasingTubingChart chartID={'casingTubingChartdiv'} chartRef={cardChartRef} data={data}/>                  
                    </>
                ): (
                    <NoData heading='No data found' />
                )}
            </div>  
        </div>
        <CasingModal
          isOpen={modalIsOpen}
          onRequestClose={handleClose}
          heading={'Casing / Tubing'}
          loading={loading}
          modelWidth={'75rem'}
          modelClass={'wellChartModal'}
          innerComponent={
            <>
                <CasingTubingChart chartID={'casingTubingChartdiv'} chartRef={modalChartRef} data={data}/> 
            </>
          }
        />
    </>    
  );
};

export default CasingTubingDetails;