import React, { useState, useEffect, useRef } from 'react';
import WellControlOptions from './WellControlOptions';
import './asset-details-header.scss';
import { Options } from '../../data/Options';
import { wellOptions } from '../../data/WellOptions';
import { useNavigate } from 'react-router-dom';
import { updateAsset } from '../../../navigation/AssetGroupsSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import refreshIcon from '../../../../images/refresh-cw-02.svg';
import { GetLocalTimeFormat } from '../../../../utilities/CommonFunctions';
import { AppUser } from '../../../user/model/AppUser';
import { fetchAggregateValue, fetchAssetDetail, fetchXspocAggregateValue } from '../details-table/AssetDetailSlice';
import { AssetDetailsResponseProps } from '../details-table/AssetDetailProps';
import { looupKeys } from './AssetDetailsHeaderService';

interface AssetList {
  assetName: string | undefined;
  assetId: string | undefined;
  industryApplicationId: number | undefined;
}

const AssetDetailsHeader: React.FC<{
  groupName?: string;
  assetList: AssetList[];
  selectedOption: Options | undefined;
  setSelectedOption: (option: Options) => void;
}> = ({ groupName, selectedOption, setSelectedOption, assetList }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleOptionSelect = (option: Options) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
  };
  const [lastUpdatedDateTime, setLastUpdatedDateTime] = useState('');
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser | null = storedUserData ? JSON.parse(storedUserData) : null;
  const selectedAssetNameRef = useRef<string>('');


  let initialUserState = useAppSelector((state) => state?.user?.currentUser);
  if (initialUserState?.id != storedUser?.id)
    initialUserState = storedUser
  const refreshTime = () => {
    // const date = new Date();
    // const formattedDate = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    setLastUpdatedDateTime(GetLocalTimeFormat());
  };

  useEffect(() => {
    refreshTime();
    // setSelectedOption({
    //   wellId: '',
    //   wellName: ,
    // });
  }, []);

  const handleRefreshClick = () => {
    // window.location.reload()
    const href = window.location.href;
    window.location.href = href;

  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleWellOptionChange = async (option: AssetList) => {
    const assetId = option?.assetId ?? ''

    setSelectedOption({
      wellId: assetId,
      wellName: option?.assetName ?? '',
      assetGroupName: groupName ? groupName : '',
    });

    if (assetId) {
      const selectedAsset: { assetId: string, assetName: string, assetGroupName: string } = { assetId: assetId, assetName: option?.assetName ?? '', assetGroupName: groupName ? groupName : '' };
      if (option.assetName) dispatch(updateAsset(selectedAsset));
      sessionStorage.setItem('selectedWellName', JSON.stringify(selectedAsset));
    }
  };

  useEffect(() => {
    handleOptionSelect(wellOptions[0]);

  }, []);

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      if (event.target instanceof HTMLElement && event.target.closest('.select-name')) return;
      setIsDropdownOpen(false);
    };
    document.body.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.body.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  useEffect(() => {
    if (selectedAssetNameRef.current !== selectedOption?.wellName) {
      selectedAssetNameRef.current = selectedOption?.wellName || '';
      if (selectedAssetNameRef.current) {
        getAssetDetail()
        dispatch(fetchAggregateValue(looupKeys.sleAggregationRange))
        dispatch(fetchXspocAggregateValue(looupKeys.xspocAggregationRnge))
      }
    }
  }, [selectedOption])

  const getAssetDetail = async () => {
    if (initialUserState?.id)

      await dispatch(fetchAssetDetail({ userId: initialUserState?.id, wellName: selectedOption?.wellName ?? '' }))
        .unwrap()
        .then((response: AssetDetailsResponseProps) => {
          sessionStorage.setItem('assetCreationDate', response.assetDetails?.creationDateTime ?? '');

          const selectedWellNameString = sessionStorage.getItem('selectedWellName');
          if (selectedWellNameString && JSON.parse(selectedWellNameString)?.assetId !== response?.assetDetails?.assetId) {
            const asset = { ...JSON.parse(selectedWellNameString), assetId: response?.assetDetails?.assetId }
            dispatch(updateAsset(asset))
            sessionStorage.setItem('selectedWellName', JSON.stringify(asset))
          }

        })
        .catch((error) => {
          console.error('Failed to fetch AssetDetails  details:', error);
        });
  }

  const handleAssetGroupNameClick = () => {
    if (groupName)
      sessionStorage.setItem("selectedGroupName", groupName)
    navigate('/layout/group-dashboard', { state: { groupNameSet: groupName } });
  }

  return (
    <div className='dashboard-first-block'>
      <div className='top-header grid grid-cols-2 gap-2'>
        <div>
          <h3 className='page-title' onClick={() => handleAssetGroupNameClick()}>
            {groupName === '-Clients' ? 'Well Groups' : groupName}
          </h3>
          <div className='select-name'>
            <div className={`custom-dropdown ${isDropdownOpen ? 'open' : ''}`} onClick={toggleDropdown}>
              <div className='selected-option'>
                {selectedOption?.wellName}
                <span>&#9660;</span>
              </div>
              {isDropdownOpen && (
                <ul className='dropdown-list max-height'>
                  {assetList.map((option, index) => (
                    <li key={index} onClick={() => handleWellOptionChange(option)}>
                      {option.assetName}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className='flex justify-end'>
          <div className='inner-wrapper'>
            <div>
              <WellControlOptions
                onSelectOption={handleOptionSelect}
                selectedOption={selectedOption || wellOptions[0]}
              />
            </div>
            <div className='pipe'>|</div>
            <div className='flex justify-end' onClick={handleRefreshClick}>
              <p className='text-timestamp'>Last updated on {lastUpdatedDateTime}</p>
              <img src={refreshIcon} alt='refresh-icon' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetDetailsHeader;
