export const SpeedApiURL: any = {
    getSpeedData: "/v1/timeseries/speed",
};
  

// Create axis ranges bands
export const HZbandsData = [{
    title: "Volatile",
    color: "#F97066",
    lowScore: 50,
    highScore: 75
  }, {
    title: "Foundational",
    color: "#FDB022",
    lowScore: 75,
    highScore: 100
  }, {
    title: "Developing",
    color: "#12B76A",
    lowScore: 100,
    highScore: 175
  }, {
    title: "Maturing",
    color: "#12B76A",
    lowScore: 175,
    highScore: 225
  }, {
    title: "Sustainable",
    color: "#FDB022",
    lowScore: 225,
    highScore: 300
  }, {
    title: "High Performing",
    color: "#F97066",
    lowScore: 300,
    highScore: 350
  }];

// Create axis ranges bands
export const RPMbandsData = [{
    title: "Volatile",
    color: "#F97066",
    lowScore: 50,
    highScore: 1440
  }, {
    title: "Foundational",
    color: "#FDB022",
    lowScore: 1440,
    highScore: 2160
  }, {
    title: "Developing",
    color: "#12B76A",
    lowScore: 2160,
    highScore: 2880
  }, {
    title: "Maturing",
    color: "#12B76A",
    lowScore: 2880,
    highScore: 3600
  }, {
    title: "Sustainable",
    color: "#FDB022",
    lowScore: 3600,
    highScore: 4320
  }, {
    title: "High Performing",
    color: "#F97066",
    lowScore: 4320,
    highScore: 5040
  }];