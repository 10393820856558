import React from 'react';
import ErrorIcon from '../../../../../images/error-icon.png'

interface AlertModelProps {
  onCloseClick: () => void;
  onOkClick: () => void;
}

const DeleteModal: React.FC<AlertModelProps> = ({ onCloseClick, onOkClick }) => {
  return (
    <>
      <div className='modal-overlay delete-modal-page document-delete'>
        <div className='modal delete-modal'>
          <div className='flex flex-row flex-grow profile-block'>
            <div className='notify-card'>
              <div className='header-delete'>
                <div className='feature-img'>
                  <img src={ErrorIcon} alt='' />
                </div>
                <p className='title'>
                  Delete?
                </p>
                <p className='normal-text'>
                  Are you sure you want to remove this watchlist?
                </p>
              </div>
              <div className='delete-modal__footer'>
                <button onClick={onCloseClick} className='btn btn-dark-orange' >
                  Cancel
                </button>
                <button onClick={onOkClick} className='btn btn-primary'>
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteModal;
