import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface initialStateProps {
  visibility: { [key: number]: boolean }
}

const initialState: initialStateProps = {
    visibility: {
      1: true,
      2: true,
      3: true,
      4: true,
      5: true,
      6: true,
      7: true,
      8: true,
    }
};
  
const CustomizeControlroomSlice = createSlice({
  name: 'CustomizeControlroomSlice',
  initialState,
  reducers: {
    toggleVisibility: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      state.visibility[id] = !state.visibility[id];
    },
  }
});

export const { toggleVisibility } = CustomizeControlroomSlice.actions;

export default CustomizeControlroomSlice.reducer;










