import { http } from '../../../../config/HttpCommon';

export const getHistoryById = async (wellName: string, startDate: string, endDate: string, paramIds: string) => {
  const response = await http.get(`/GetAssetHistorySparklineData?wellName=${encodeURIComponent(wellName)}&startDate=${startDate}&endDate=${endDate}&paramIds=${paramIds}`);
  return response.data;
};

export const getAssetTimeseriesChannelsData = async (wellName: string, startDate: string, endDate: string, ChannelIds: string, aggregate: string, cancelToken: any) => {
  const response = await http.get(`/GetAssetTimeseriesChannelsData?AssetName=${encodeURIComponent(wellName)}&startDate=${startDate}&endDate=${endDate}&ChannelIds=${ChannelIds}&Aggregate=${aggregate}`, { cancelToken });
  return response.data;
};

export const getHistoryChartData = async (wellName: string, startDate: string, endDate: string, aggregate: string, cancelToken: any) => {
  const response = await http.get(`/GetAssetHistoryChartData?wellName=${encodeURIComponent(wellName)}&startDate=${startDate}&endDate=${endDate}&Aggregate=${aggregate}`, { cancelToken });
  return response.data;
}

export const getMasterVariablesData = async (wellName: string) => {
  const response = await http.get(`/Mastervariables/${encodeURIComponent(wellName)}?channelType=standardparameters`);
  return response.data;
}

export const getHistoryChartFilterData = async () => {
  const response = await http.get(`/GetAssetTrends`);
  return response.data;
}