import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import annotation_plus from './../../../../images/expand-icon.svg';
import download from '../../../../../src/images/download-icon.svg';
import Loader from '../../../common/page-loader/ComponentLoader';
import NoData from '../../../dashboard/components/no-data/NoData';
import TempModal from './modals/TempModal';
import { TemperatureDetailsProps, DataPoint } from './interfaces/temperature.interface';
import TempChart from './charts/TempChart';
import TempServices from './services/temp.services';
import { bandsData } from './constants/temp.constants';

const TemperatureDetails: React.FC<TemperatureDetailsProps> = ({heading, selectedWell}) => {
    const loading = false;
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const motorChartdiv1 = useRef<HTMLDivElement | null>(null);
    const intakeChartdiv2 = useRef<HTMLDivElement | null>(null);
    const ambientChartdiv3 = useRef<HTMLDivElement | null>(null);
    const motorChartdiv4 = useRef<HTMLDivElement | null>(null);
    const intakeChartdiv5 = useRef<HTMLDivElement | null>(null);
    const ambientChartdiv6 = useRef<HTMLDivElement | null>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [data, setData] = useState<DataPoint[]>([]);
    const hElement = document.getElementById('main-root');

    const openModal = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setModalIsOpen(true);
        hElement && hElement?.classList.add('modal-open');
    };

    const handleClose = () => {
        setModalIsOpen(false);
        hElement && hElement?.classList.remove('modal-open');
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
          setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const getSpeedChartData = async () => {
        try {
          const request: any = {
            assetName: selectedWell,
          };
          const response = await TempServices.getTemperatureData(
            request
          );
          if (response?.statusCode === 200 && response?.success) {
            setData(response?.data);
          }
        } catch (error) {
          console.error("error", error);
        } finally {
          //setIsLoading(false);
        }
      };
    
      useEffect(() => {  
        if(selectedWell){
          getSpeedChartData(); 
        }        
      }, [selectedWell]);


  return (
    <>
        <ToastContainer position='top-right' autoClose={3000} />
        <div className='grid-item-text h-full graph-card well-graph-card'>
            <div className='table-header-section pie-section'>
                <div className='title'>{heading}</div>
                <div className='header-icon'>
                    <button className='expand-btn cancelSelectorName' onClick={openModal} style={{ cursor: 'pointer', zIndex: 0 }}>
                        <img src={annotation_plus} alt='Expand' className='img-border' />
                    </button>
                </div>
                <div className='mr-4 dropdown-container' ref={dropdownRef}>
                    <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                        <span className='dot'></span>
                        <span className='dot'></span>
                        <span className='dot'></span>
                    </button>
                    {isDropdownOpen && (
                    <div className='dropdown-options'>
                        <button>Actions</button>
                        <button className={`btn btn-default cancelSelectorName`}>
                            <img src={download} alt='icon' className='btn-icon' />
                            Download PDF
                        </button>
                        <button className={`btn btn-default cancelSelectorName`}>
                            <img src={download} alt='icon' className='btn-icon' />
                            Download CSV
                        </button>
                    </div>
                    )}
                </div>
            </div>
            <hr />
            <div id="temperature-container" className='flex flex-wrap items-center justify-center wellChartSection tempChart-container-dashboard nodata'>
                    
                {loading ? (
                    <div className='flex items-center justify-center w-100'>
                        <Loader />
                    </div>
                ) : data?.length > 0 ? (
                    <>
                    {/* <div id={`speedchartdiv`} ref={speedHzchartdiv} className='chart-wrapper'></div> */}
                    <TempChart chartID={'motorChartdiv1'} chartRef={motorChartdiv1} classValue={'w-[33.33%]'} minValue={50} maxValue={350} clockHandValue={177} clockHandName={'F'} data={data} chartName={'Motor'} bandsData={bandsData} spacingValue={4}/>
                    <TempChart chartID={'intakeChartdiv2'} chartRef={intakeChartdiv2} classValue={'w-[33.33%]'}  minValue={50} maxValue={350} clockHandValue={220} clockHandName={"F"} data={data} chartName={'Intake'} bandsData={bandsData} spacingValue={4}/>
                    <TempChart chartID={'ambientChartdiv3'} chartRef={ambientChartdiv3} classValue={'w-[33.33%]'}  minValue={50} maxValue={350} clockHandValue={220} clockHandName={"F"} data={data} chartName={'Ambient'} bandsData={bandsData} spacingValue={4}/>
                    </>
                ): (
                    <NoData heading='No data found' />
                )}
                
            </div>
        </div>
        <TempModal
          isOpen={modalIsOpen}
          onRequestClose={handleClose}
          heading={'Temperature'}
          loading={loading}
          modelWidth={'80rem'}
          modelClass={'wellChartModal'}
          innerComponent={
            <div className='flex justify-center w-full'>
                <TempChart chartID={'motorChartdiv4'} chartRef={motorChartdiv4} classValue={'w-[33.33%]'} minValue={50} maxValue={350} clockHandValue={177} clockHandName={'F'} data={data} chartName={'Motor'}  bandsData={bandsData} spacingValue={4}/>
                <TempChart chartID={'intakeChartdi5'} chartRef={intakeChartdiv5} classValue={'w-[33.33%]'}  minValue={50} maxValue={350} clockHandValue={220} clockHandName={"F"} data={data} chartName={'Intake'} bandsData={bandsData} spacingValue={4}/>
                <TempChart chartID={'ambientChartdi6'} chartRef={ambientChartdiv6} classValue={'w-[33.33%]'}  minValue={50} maxValue={350} clockHandValue={220} clockHandName={"F"} data={data} chartName={'Ambient'} bandsData={bandsData} spacingValue={4}/>
            </div>
          }
        />
    </>    
  );
};

export default TemperatureDetails;