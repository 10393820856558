import React, { useState } from 'react';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, { DateObject } from 'react-multi-date-picker';
// import Toolbar from 'react-multi-date-picker/plugins/toolbar';
import InputIcon from "react-multi-date-picker/components/input_icon"

interface DocumentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  operator: string;
  setOperator: (value: string) => void;
  assignedCategory: string;
  setAssignedCategory: (value: string) => void;
  eventDate: Date | null;
  setEventDate: (value: Date) => void;
  onFileSelect?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  showFileInput?: boolean;
}

const DocumentModal: React.FC<DocumentModalProps> = ({
  isOpen,
  onClose,
  onSave,
  // operator,
  // setOperator,
  setAssignedCategory,
  assignedCategory,
  eventDate,
  setEventDate,
}) => {
  // const [operatorError, setOperatorError] = useState<string>('');
  const [categoryError, setCategoryError] = useState<string>('');
  const [dateError, setDateError] = useState<string>('');
  // const [isDashboardDropdownOpen, setIsDashboardDropdownOpen] = useState(false);
  const [isDashboardDropdownOpen2, setIsDashboardDropdownOpen2] = useState(false);

  const [value, setValue] = useState<DateObject | null>(null);

  const handleCategoryChange = (option: string) => {
    setAssignedCategory(option);
    setCategoryError(option ? '' : 'Please select an category.');
  };

  // const toggleDashboardDropdown = () => {
  //   setIsDashboardDropdownOpen(!isDashboardDropdownOpen);
  // };

  const toggleDashboardDropdown2 = () => {
    setIsDashboardDropdownOpen2(!isDashboardDropdownOpen2);
  };

  // const operators = ['Operator 1', 'Operator 2', 'Operator 3', 'Operator 4'];
  const categories = ['Install', 'Pull', 'Design', 'DIFA', 'Service', 'Survey', 'Other'];


  const handleDateChange = (date: DateObject | DateObject[] | null) => {
    if (date) {
      const selectedDate = Array.isArray(date) ? date[0] : date;
      setEventDate(selectedDate.toDate());
      setValue(selectedDate)
      setDateError('');
    } else {
      setDateError('Please select a date.');
    }
  };


  const handleSave = () => {
    // if (!operator) {
    //   setOperatorError('Please select an operator.');
    // }
    if (!assignedCategory) {
      setCategoryError('Please select a category.');
    }
    if (!eventDate) {
      setDateError('Please select a date.');
    }

    if (assignedCategory && eventDate) {
      onSave();
      onClose();
      setValue(null)
    }
  };

  const handleClose = () => {
    // setOperatorError('');
    setCategoryError('');
    setDateError('');
    onClose();
    setValue(null)

  }


  return isOpen ? (
    <div className='modal-overlay'>
      <div className='modal'>
        <div className='flex flex-row flex-grow profile-block'>
          <div className='notify-card'>
            <div className='header'>
              <span className='title'>Upload document</span>
              <button className='cancel-icon' onClick={onClose}>
                <i className='fa fa-times'></i>
              </button>
            </div>
            <div className='body form-body'>
              {/* <div className='form-row'>
                <div className='form-col full-col'>
                  <label htmlFor='operator'>Operator</label>
                  <div className='cust-drop'>
                    <div
                      className={`custom-dropdown ${isDashboardDropdownOpen ? 'open' : ''}`}
                      onClick={toggleDashboardDropdown}
                    >
                      <div className='selected-option'>
                        {operator || 'Select an Operator'} <span>&#9660;</span>
                      </div>
                      {isDashboardDropdownOpen && (
                        <ul className='dropdown-list'>
                          {operators.map((operatorOption, index) => (
                            <li key={index} onClick={() => handleOperatorChange(operatorOption)}>
                              {operatorOption}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  {operatorError && (
                    <div style={{ color: 'red' }} className='error-message'>
                      {operatorError}
                    </div>
                  )}
                </div>
              </div> */}
              <div className='form-row'>
                <div className='form-col full-col'>
                  <label htmlFor='assigned-category'>Assigned Category</label>
                  <div className='cust-drop'>
                    <div
                      className={`custom-dropdown ${isDashboardDropdownOpen2 ? 'open' : ''}`}
                      onClick={toggleDashboardDropdown2}
                    >
                      <div className='selected-option'>
                        {assignedCategory || 'Select an Assigned Category'} <span>&#9660;</span>
                      </div>
                      {isDashboardDropdownOpen2 && (
                        <ul className='dropdown-list'>
                          {categories.map((category, index) => (
                            <li key={index} onClick={() => handleCategoryChange(category)}>
                              {category}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  {categoryError && (
                    <div style={{ color: 'red' }} className='error-message'>
                      {categoryError}
                    </div>
                  )}
                </div>
              </div>
              <div className='form-row'>
                <div className='form-col full-col'>
                  <label htmlFor='event-date'>Select Event Date</label>
                  <DatePicker
                    calendarPosition='right'
                    showOtherDays={true}
                    format='MM/DD/YYYY'
                    className='date-input-field'
                    weekDays={["Mo", "Tu", "We", "Th", "Fr", "Sat", "Su"]}
                    placeholder='Select date'
                    value={value ? value : null}
                    onChange={handleDateChange}
                    shadow={false}
                    arrow={false}
                    render={<InputIcon />}
                  />
                  {dateError && (
                    <div style={{ color: 'red' }} className='error-message'>
                      {dateError}
                    </div>
                  )}
                </div>
              </div>
              <div className='divider'></div>
              <div className='btn-notify'>
                <button type='button' className='btn btn-secondary' onClick={handleClose}>
                  Cancel
                </button>
                <button type='button' className='btn btn-primary' onClick={handleSave}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default DocumentModal;
