import React, { useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { AppUser } from '../model/AppUser';
import { fetchUserById, fetchUsers, updateUser } from '../UserSlice';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../common/page-loader/ComponentLoader';
import { capitalizeFirstLetter } from '../../common/DateRangeSelector/DefaultRanges';
import './UserProfile.scss';

interface TabsProps {
  value?: string | undefined;
}

const UserProfile: React.FC<TabsProps> = ({ value }) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state?.user?.currentUser);
  const loading = useAppSelector((state) => state?.user?.userLoading);
  const [user, setUser] = useState<AppUser | null>(currentUser);
  const [emailError, setEmailError] = useState<string>('');
  const [mobNumError, setMobNumError] = useState<string>('');
  const [phoneNumError, setPhoneNumError] = useState<string>('');
  const [countryCodeMobileError, setCountryCodeMobileError] = useState<string>('');
  const [countryCodeOfficeError, setCountryCodeOfficeError] = useState<string>('');
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;

  const userRef = useRef({
    current: currentUser,
    updated: user,
  });

  useEffect(() => {
    if (value && value !== currentUser?.id) {
      dispatch(fetchUserById(value));
    }
  }, [value, currentUser?.id, dispatch]);

  useEffect(() => {
    if (currentUser) {
      setUser({
        ...currentUser,
        lastLoginDate: new Date(currentUser.lastLoginDate)?.toISOString(),
      });
    }
  }, [currentUser, value]);

  useEffect(() => {
    userRef.current = {
      current: currentUser,
      updated: user,
    };
  }, [user, currentUser]);

  const handleInputChange = (id: string, value: string) => {
    let formattedValue = value;

    if (id === 'mobileNumber' || id === 'officeNumber' || id === 'pager' || id === 'phoneExtension') {
      formattedValue = value.replace(/[^\d]/g, '');
    } else if (id === 'firstName' || id === 'lastName') {
      formattedValue = value.replace(/[^a-zA-Z\s-]/g, '');
    } else if (id === 'countryCodeMobile') {
      formattedValue = value.replace(/(?!^\+)[^\d]/g, '');
    } else if (id === 'countryCodeOffice') {
      formattedValue = value.replace(/(?!^\+)[^\d]/g, '');
    }

    setUser((prevUser) => ({
      ...(prevUser as AppUser),
      [id]: formattedValue,
    }));

    validateInput(id, formattedValue); // Pass formattedValue for validation
  };

  const validateInput = (id: string, value: string) => {
    let isValid = true;

    if (id === 'email') {

      const isValidEmail = /\S+@\S+\.\S+/.test(value);
      setEmailError(isValidEmail ? '' : 'Please enter a valid email address');
      isValid = isValid && isValidEmail;
    } else if (id === 'countryCodeMobile') {
      // Validate country code: should be digits and a valid length (e.g., 1-3 digits)
      const isValidCountryCode = /^\+\d{1,3}$/.test(value);
      setCountryCodeMobileError(isValidCountryCode ? '' : 'Country code must start with a + sign and be between 1 and 3 digits');
      isValid = isValid && isValidCountryCode;
    } else if (id === 'countryCodeOffice') {
      // Validate country code: should be digits and a valid length (e.g., 1-3 digits)
      const isValidCountryCode = /^\+\d{1,3}$/.test(value);
      setCountryCodeOfficeError(isValidCountryCode ? '' : 'Country code must start with a + sign and be between 1 and 3 digits');
      isValid = isValid && isValidCountryCode;
    } else if (['mobileNumber', 'officeNumber', 'pager', 'phoneExtension'].includes(id)) {
      // Determine the valid pattern based on the field ID
      let isValidNumber = false;
      let pattern = /^\d{10}$/; // Default pattern for 10 digits

      if (id === 'phoneExtension') {
        pattern = /^\d{2}$/; // Special pattern for 2 digits for phone extension
      }

      isValidNumber = pattern.test(value);

      if (!isValidNumber) {
        if (id === 'mobileNumber') {
          setMobNumError("Mobile number must be exactly 10 digits and will not accept characters");
        } else if (id === 'officeNumber') {
          setPhoneNumError("Office number must be exactly 10 digits and will not accept characters");
        }
      } else {
        if (id === 'mobileNumber') setMobNumError('');
        if (id === 'officeNumber') setPhoneNumError('');
      }
      isValid = isValid && isValidNumber;
    }

    setIsFormValid(isValid);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const formElement = document.getElementById('userProfileForm');
      if (formElement && !formElement.contains(event.target as Node)) {
        if (emailError === '' && mobNumError === '' && phoneNumError === '' && countryCodeMobileError === '' && countryCodeOfficeError === '') {
          saveUserData();
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [emailError, mobNumError, phoneNumError, countryCodeMobileError, countryCodeOfficeError]);

  const saveUserData = () => {
    const { current, updated } = userRef.current;
    if (current?.id && updated && isFormValid) {
      if (!isEqual(current, updated)) {
        dispatch(updateUser({ id: current.id, user: updated }))
          .then(() => {
            if (current.id === storedUser.id) localStorage.setItem('loggedInUser', JSON.stringify(updated));
            dispatch(fetchUsers());
            dispatch(fetchUserById(updated.id));
            toast.success('User profile is updated successfully.');
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }
    } else {
      console.error('Please fill the valid values');
    }
  };

  return (
    <>
      {loading ? (
        <div className='flex items-center loader-margin'>
          <Loader />
        </div>
      ) : currentUser ? (
        <div className='flex flex-row flex-grow profile-block w-100 py-6 user-profile'>
          <div className='card profile-card m-0'>
            <div className='header'>
              <p className='title'>Profile</p>
            </div>
            <div className='body'>
              <div className='profile-info'>
                <div className='protext'>
                  <div className='text'>Personal info</div>
                  <p>Personal details and profile picture</p>
                </div>
                <div className='card'>
                  <form className='register-form' id='userProfileForm'>
                    <div className='form-row'>
                      <div className='form-col'>
                        <label htmlFor='firstName'>First Name</label>
                        <input
                          type='text'
                          id='firstName'
                          className='custom-input'
                          value={capitalizeFirstLetter(user?.firstName || '')}
                          onChange={(e) => {
                            handleInputChange('firstName', e.target.value);
                          }}
                          readOnly={false}
                        />
                      </div>
                      <div className='form-col'>
                        <label htmlFor='lastName'>Last Name</label>
                        <input
                          type='text'
                          id='lastName'
                          className='custom-input'
                          value={capitalizeFirstLetter(user?.lastName || '')}
                          onChange={(e) => {
                            handleInputChange('lastName', e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='form-col'>
                        <label htmlFor='company'>Company</label>
                        <input
                          type='text'
                          id='company'
                          className='custom-input'
                          value={user?.company || ''}
                          onChange={(e) => {
                            handleInputChange('company', e.target.value);
                          }}
                          readOnly={false}
                        />
                      </div>
                      <div className='form-col'>
                        <label htmlFor='jobtitle'>Job Title</label>
                        <input
                          type='text'
                          id='jobtitle'
                          className='custom-input'
                          value={user?.jobTitle || ''}
                          onChange={(e) => {
                            handleInputChange('jobTitle', e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='form-col full-col'>
                        <label htmlFor='email'>Email Address</label>
                        <input
                          type='text'
                          id='email'
                          className='custom-input'
                          value={user?.email || ''}
                          onChange={(e) => {
                            handleInputChange('email', e.target.value);
                          }}
                          disabled={true}
                        />
                        {emailError && (
                          <p className='error-message'>
                            {emailError}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='form-col full-col'>
                        <label htmlFor='mobileNumber'>Mobile Phone Number</label>
                        <div className='phone-input'>
                          <input
                            type='text'
                            id='countryCodeMobile'
                            className='custom-input country-code-input'
                            value={user?.countryCodeMobile || ''}
                            onChange={(e) => {
                              handleInputChange('countryCodeMobile', e.target.value);
                            }}
                          />
                          <input
                            type='text'
                            id='mobileNumber'
                            className='custom-input mobile-number-input'
                            value={user?.mobileNumber || ''}
                            onChange={(e) => {
                              handleInputChange('mobileNumber', e.target.value);
                            }}
                          />
                        </div>
                        {countryCodeMobileError && (
                          <p className='error-message'>
                            {countryCodeMobileError}
                          </p>
                        )}
                        {mobNumError && (
                          <p className='error-message'>
                            {mobNumError}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='form-col col60'>
                        <label htmlFor='officeNumber'>Office Phone Number</label>
                        <div className='phone-input'>
                          <input
                            type='text'
                            id='countryCodeOffice'
                            className='custom-input country-code-input'
                            value={user?.countryCodeOffice || ''}
                            onChange={(e) => {
                              handleInputChange('countryCodeOffice', e.target.value);
                            }}
                          />
                          <input
                            type='text'
                            id='officeNumber'
                            className='custom-input mobile-number-input'
                            value={user?.officeNumber || ''}
                            onChange={(e) => {
                              handleInputChange('officeNumber', e.target.value);
                            }}
                          />
                        </div>
                        {countryCodeOfficeError && (
                          <p className='error-message'>
                            {countryCodeOfficeError}
                          </p>
                        )}
                        {phoneNumError && (
                          <p className='error-message'>
                            {phoneNumError}
                          </p>
                        )}
                      </div>
                      <div className='form-col col40'>
                        <label htmlFor='phoneExtension'>Extension</label>
                        <input
                          type='text'
                          id='phoneExtension'
                          className='custom-input'
                          value={user?.phoneExtension || ''}
                          onChange={(e) => {
                            handleInputChange('phoneExtension', e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='form-col full-col'>
                        <label htmlFor='pager'>Pager</label>
                        <input
                          type='text'
                          id='pager'
                          className='custom-input'
                          value={user?.pager || ''}
                          onChange={(e) => {
                            handleInputChange('pager', e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <ToastContainer position='top-right' autoClose={3000} />
    </>
  );
};

export default UserProfile;
