import React from 'react';
import Featured from '../../../../../images/alertModalIcon.svg';

interface ErrorModalProps {
    isErrorModal: boolean;
    isErrorModalClose: () => void;
    errorMessage: string;
    invalidFiles?: { size: number, name: string }[];

}

const DuplicateErrorModal: React.FC<ErrorModalProps> = ({ isErrorModal, isErrorModalClose, errorMessage, invalidFiles }) => {

    return (
        <>
            {isErrorModal && (
                <div className='modal-overlay delete-modal-page'>
                    <div className='modal'>
                        <div className='flex flex-row flex-grow profile-block'>
                            <div className='notify-card'>
                                <div className='header-delete'>
                                    <div className='feature-img'>
                                        <img src={Featured} alt='' />
                                    </div>
                                    <p className='title'>Duplicate file name</p>
                                    <p className='normal-text mt-5'>{errorMessage}</p>
                                    {
                                        invalidFiles && invalidFiles.length > 0 ?
                                            <div className='file-list mt-5'>
                                                <ul>
                                                    {invalidFiles.map((file, index) => (
                                                        <li key={index} className='mt-5 invalid-file-names-li'>{file.name.length > 30 ? file.name.slice(0, 30) + '...' : file.name} - {
                                                            (file.size / (1024 ** 2)).toFixed(2)
                                                        } MB</li>
                                                    ))}
                                                </ul>
                                            </div>
                                            : ''
                                    }
                                </div>
                                <div className='flex justify-center pb-4'>
                                    <button onClick={isErrorModalClose} className='btn btn-primary'>
                                        OK
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DuplicateErrorModal;
