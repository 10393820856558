import React from 'react';
import { /* addComment,  addReply, */ deleteComment, setSearchTerm } from './slices/CommentsSlice';
import DeleteIcon from '../../../../images/icon-delete.svg';
import ReplyIcon from '../../../../images/icon-reply.svg';
import moment from 'moment-timezone';
import { useAppDispatch, useAppSelector} from '../../../../hooks/storeHooks'; 

const Comments: React.FC = () => {
  const dispatch = useAppDispatch();
  const comments = useAppSelector((state) => state.Comments.comments);
  const searchTerm = useAppSelector((state) => state.Comments.searchTerm);
  
  //const [newComment, setNewComment] = useState<string>("");
  //const [replyContent, setReplyContent] = useState<string>("");

  /* const handleAddComment = () => {
    if (newComment.trim()) {
      dispatch(addComment(newComment));
      setNewComment("");
    }
  }; */

 const handleDeleteComment = (id: number) => {
    dispatch(deleteComment(id));
  };
 /* 
  const handleReplyComment = (id: number) => {
    if (replyContent.trim()) {
      dispatch(addReply({ id, content: replyContent }));
      setReplyContent("");
    }
  }; */

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchTerm(e.target.value));
  };

  const filteredComments = comments.filter(comment =>
    comment.content.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className='comments-wrapper'>  
     <div className='flex justify-between items-center mb-10 text-white'>     
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearch}
          className='flex items-center justify-center w-[50%] h-[48px] py-2 px-4 border border-[#4a5463] text-white rounded-lg'
        />
        <button type='button' className='flex items-center justify-center h-[48px] py-2 px-4 cursor-pointer border border-[#4a5463] text-white rounded-lg'>New comment</button>
      </div>
{/*       <input
        type="text"
        placeholder="New Comment"
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
      />
      <button onClick={handleAddComment}>Add Comment</button> */}
      
      <div>
        {filteredComments.map(comment => (
          <div key={comment.id} className='border-b border-[#4A5463] mb-4 pb-4'>
            <h3 className='text-[18px] mb-4'>{comment.title}</h3>
            <div className='flex text-[14px] justify-between mb-4'>
              <div className='text-[#00789e]'>{comment.userName}</div>
              <div className='flex w-[200px] text-white text-right justify-end'>{moment(comment.timestamp).format("DD/MM/YYYY hh:mm A")}</div>
            </div>
            <div className='flex text-[14px] justify-between mb-4'>
              <p className='mb-2 text-[14px] max-w-[60%]'>{comment.content}</p>
              {comment?.active ? <span className='w-[12px] h-[12px] rounded-full bg-red-500 mr-2'></span> : null}
            </div>
            <div>
              <button type='button' className='border-none p-2 mr-2 cursor-pointer'><img src={ReplyIcon} alt='Reply Icon' className='Delete-icon'/></button>
              <button type='button' className='border-none p-2 cursor-pointer' onClick={() => handleDeleteComment(comment.id)}><img src={DeleteIcon} alt='Delete Icon' className='Delete-icon'/></button>
            </div>
           {/*  <button onClick={() => handleDeleteComment(comment.id)}>Delete</button>
            <input
              type="text"
              placeholder="Reply"
              value={replyContent}
              onChange={(e) => setReplyContent(e.target.value)}
            />
            <button onClick={() => handleReplyComment(comment.id)}>Reply</button> */}

            {comment.replies && comment.replies.length > 0 && (
              <div className='pl-8 pt-5'>
                {comment.replies.map(reply => (
                  <div key={reply.id}>
                    <div className='flex text-[14px] justify-between mb-4'>
                      <div className='text-[#00789e]'>{reply.userName}</div>
                        <div className='flex w-[200px] text-white text-right justify-end'>{moment(reply.timestamp).format("DD/MM/YYYY hh:mm A")}</div>
                    </div>
                    <div className='flex text-[14px] justify-between mb-4'>
                      <p className='mb-2 text-[14px] max-w-[70%]'>{reply.content}</p>
                    </div>
                    <div>
                      <button type='button' className='border-none p-2 cursor-pointer'><img src={DeleteIcon} alt='Delete Icon' className='Delete-icon'/></button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Comments;
