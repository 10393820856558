import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { Reply, Comment, CommentsState } from '../interfaces/comments.interfaces';
import { initialComments } from '../constants/comments.constants';


const initialState: CommentsState = {
  comments: initialComments,
  searchTerm: ''
};

const CommentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    addComment: (state, action: PayloadAction<string>) => {
      const newComment: Comment = {
        id: Date.now(),
        title: "Comment Subject",
        userName: "New User",
        content: action.payload,
        timestamp: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        replies: []
      };
      state.comments.unshift(newComment);
    },
    deleteComment: (state, action: PayloadAction<number>) => {
      state.comments = state.comments.filter(comment => comment.id !== action.payload);
    },
    addReply: (state, action: PayloadAction<{ id: number; content: string }>) => {
      const { id, content } = action.payload;
      const comment = state.comments.find(comment => comment.id === id);
      if (comment) {
        const reply: Reply = {
          id: Date.now(),
          userName: "New User",
          content,
          timestamp: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'")
        };
        comment.replies.push(reply);
      }
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    }
  }
});

export const { addComment, deleteComment, addReply, setSearchTerm } = CommentsSlice.actions;
export default CommentsSlice.reducer;
