import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AmpsServices from "../services/amps.services";


/* interface ampsDataProps {
    ampsData: HealthScoreHistory[],
    loading: boolean;
    error: string | null;
}
 */
const initialState = {
    ampsData: [],
    loading: false,
    error: '',
}

export const fetchAmpsData = createAsyncThunk('getAmpsData', async ({ assetName }: { assetName: string }) => {
    return await AmpsServices.getAmpsChartData(assetName);
})

const AmpsChartSlice = createSlice({
    name: 'ampsChartData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // fetch ampsChart thunk cases
        builder.addCase(fetchAmpsData.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchAmpsData.fulfilled, (state, action) => {
            console.log("action.payload", action.payload)
            state.ampsData = action.payload.data;
            state.loading = false;
        })
        builder.addCase(fetchAmpsData.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
        })
    }
});

export default AmpsChartSlice.reducer;