import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getTrendsById, getTrendsFilterData } from './TrendsService';
import { TrendsDetailsProps } from './TrendsDetails';


interface TrendsState {
  loading: boolean;
  initialDataLoading: boolean;
  granularDataLoading: boolean;
  message: string | null;
  trends: TrendsDetailsProps[] | null;
  trendsFilterData: any | null;
  trendsDataSet: {
    data: TrendsDetailsProps[],
    aggregate: string,
    startDate: string;
    endDate: string;
  }[]
}

const initialState: TrendsState = {
  loading: false,
  initialDataLoading: false,
  granularDataLoading: false,
  message: null,
  trends: null,
  trendsFilterData: null,
  trendsDataSet: []
};

interface FetchTrendsByIdArgs {
  wellName: string;
  startDate: string;
  endDate: string;
  aggregate?: string;
  cancelToken?: any;
  granularData?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchTrendsById = createAsyncThunk(
  'events/fetchTrendsById',
  async ({ wellName, startDate, endDate, aggregate, cancelToken }: FetchTrendsByIdArgs) => {
    const response = await getTrendsById(wellName, startDate, endDate, aggregate, cancelToken);
    return response;
  },
);

export const fetchTrendsChartFilterData = createAsyncThunk(
  'events/fetchTrendsChartFilterData',
  async () => {
    const response = await getTrendsFilterData();
    return response;
  },
);

const trendsSlice = createSlice({
  name: 'trends',
  initialState,
  reducers: {
    resetTrendsDataSet: (state) => {
      state.trendsDataSet = []
    }
  },
  extraReducers: (builder) => {
    // Handle the fetchEventsById fulfilled action
    builder.addCase(fetchTrendsById.pending, (state, action) => {
      const granularData = action.meta.arg.granularData
      if (granularData) {
        state.granularDataLoading = true;
        state.initialDataLoading = false;
      } else {
        state.granularDataLoading = false;
        state.initialDataLoading = true;
      }
    });
    builder.addCase(fetchTrendsById.fulfilled, (state, action) => {
      const { granularData, startDate, endDate } = action.meta.arg
      const aggregateValue = action.meta.arg.aggregate || ''
      // Find index of existing item with the same aggregate value
      const index = state.trendsDataSet?.findIndex((item) => item.aggregate === aggregateValue);

      if (index !== -1 && state.trendsDataSet) {
        // Update existing item
        state.trendsDataSet[index] = { ...state.trendsDataSet[index], data: action.payload, startDate: startDate, endDate: endDate };
      } else {
        // Add new item
        state.trendsDataSet.push({ aggregate: aggregateValue, data: action.payload, startDate: startDate, endDate: endDate });
      }
      if (!granularData)
        state.trends = action.payload;
      state.granularDataLoading = false;
      state.initialDataLoading = false;
    });
    builder.addCase(fetchTrendsById.rejected, (state, action) => {
      if (action.error.message !== "canceled") {
        const { granularData, startDate, endDate } = action.meta.arg

        const aggregateValue = action.meta.arg.aggregate || ''

        const index = state.trendsDataSet?.findIndex((item) => item.aggregate === aggregateValue);

        if (index !== -1 && state.trendsDataSet) {
          // Update existing item
          state.trendsDataSet[index] = { ...state.trendsDataSet[index], data: [], startDate: startDate, endDate: endDate };
        } else {
          // Add new item
          state.trendsDataSet.push({ aggregate: aggregateValue, data: [], startDate: startDate, endDate: endDate });
        }
        if (!granularData)
          state.trends = []
      }
      state.granularDataLoading = false;
      state.initialDataLoading = false;
    });


    //fetch filter data
    builder.addCase(fetchTrendsChartFilterData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchTrendsChartFilterData.fulfilled, (state, action: PayloadAction<any>) => {
      state.trendsFilterData = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchTrendsChartFilterData.rejected, (state, action) => {
      state.trendsFilterData = null;
      state.message = action.payload as string;
      state.loading = false;
    });
  },
});

export const { resetTrendsDataSet } = trendsSlice.actions;

export default trendsSlice.reducer;
