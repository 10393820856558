import React from 'react'


const GasLockRecovery = () => {
    return (
        <div className='gas-lock-recovery-container'>
            <div className='flex flex-col space-y-4'>
                <div className='flex flex-row space-x-4'>
                    <div className='manage-asset-form-control w-1/2'>
                        <label className='label'>Start delay [HH:MM:SS]</label>
                        <input
                            type='text'
                            className='time-input-field'
                            placeholder='10.0'
                        />
                    </div>
                    <div className='manage-asset-form-control w-1/2'>
                        <label className='label'>Low amperage setpoint [A]</label>
                        <input
                            type='text'
                            className='time-input-field'
                            placeholder='05'
                        />
                    </div>
                </div>
                <div className='flex flex-row space-x-4'>
                    <div className='manage-asset-form-control w-1/2'>
                        <label className='label'>Trigger delay [HH:MM:SS]</label>
                        <input
                            type='text'
                            className='time-input-field'
                            placeholder='10.0'
                        />
                    </div>
                    <div className='manage-asset-form-control w-1/2'>
                        <label className='label'>Reset delay [HH:MM:SS]</label>
                        <input
                            type='text'
                            className='time-input-field'
                            placeholder='05'
                        />
                    </div>
                </div>
                <div className='flex flex-row space-x-4'>
                    <div className='manage-asset-form-control w-1/2'>
                        <label className='label'>Attempts allowed</label>
                        <input
                            type='text'
                            className='time-input-field'
                            placeholder='10.0'
                        />
                    </div>
                    <div className='manage-asset-form-control w-1/2'>
                        <label className='label'>Attempts</label>
                        <input
                            type='text'
                            className='time-input-field'
                            placeholder='05'
                        />
                    </div>
                </div>

                <div className='steps-table'>
                    <div className='flex flex-row table-row '>
                        <div className='w-1/3 table-col'></div>
                        <div className='w-1/3 table-col'>Frequency [Hz]</div>
                        <div className='w-1/3 table-col'>Time [HH:MM:SS]</div>
                    </div>
                    <div className='flex flex-row table-row'>
                        <div className='w-1/3 table-col'>Step 1</div>
                        <div className='w-1/3 table-col'>50</div>
                        <div className='w-1/3 table-col'>00:00:10</div>
                    </div>
                    <div className='flex flex-row table-row'>
                        <div className='w-1/3 table-col'>Step 2</div>
                        <div className='w-1/3 table-col'>50</div>
                        <div className='w-1/3 table-col'>00:00:10</div>
                    </div>
                    <div className='flex flex-row table-row'>
                        <div className='w-1/3 table-col'>Step 3</div>
                        <div className='w-1/3 table-col'>50</div>
                        <div className='w-1/3 table-col'>00:00:10</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GasLockRecovery