import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import checkCircle from '../../../images/check-circle-green.svg'
import alertCircle from '../../../images/alert-circle.svg'
import scan from '../../../images/scan.svg'


import React from 'react';
import { useEffect } from 'react';
import './Toast.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { resetReadDeviceStatus } from '../../asset-details/components/setpoints/SetPointsSlice';


interface SetPointToastProps {
  isShowEnabled: boolean
}

const SetPointToast: React.FC<SetPointToastProps> = ({ isShowEnabled }) => {
  const dispatch = useAppDispatch();
  const setPointData = useAppSelector((state) => state.setPoint)

  const [show, setShow] = useState(false);


  useEffect(() => {
    if (isShowEnabled) {
      setShow(true);

      if (setPointData.readDeviceSuccessStatus || setPointData.readDeviceErrorStatus || setPointData?.readDeviceReturnErrorStatus) {
        const timer = setTimeout(() => {
          setShow(false);
          dispatch(resetReadDeviceStatus('NotAll'))
        }, 3000);
        return () => clearTimeout(timer);

      }
    }
  }, [isShowEnabled, setPointData.readDeviceSuccessStatus, setPointData.readDeviceErrorStatus, setPointData?.readDeviceReturnErrorStatus]);

  console.log("setPoint Data", setPointData)
  return (
    <>
      <div
        aria-live="assertive"
        className="z-10 pointer-events-none fixed inset-0 flex items-end justify-end px-4 py-6 sm:items-end sm:justify-end sm:p-6"
        style={{ zIndex: 1001 }}
      >
        <div className="flex flex-col items-end space-y-4 set-point-toast">
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 toaster-wrapper">
              <div className="p-4 h-full">
                <div className="flex items-center justify-center h-full">
                  <div className="flex-shrink-0 check-circle">
                    <img src={setPointData?.readDeviceLoading
                      ? scan
                      : setPointData?.readDeviceSuccessStatus
                        ? checkCircle
                        : (setPointData?.readDeviceErrorStatus || setPointData?.readDeviceReturnErrorStatus)
                          ? alertCircle
                          : ""} alt="" />
                  </div>
                  <div className="ml-3 flex-1 pt-0.5 text-left whitespace-nowrap">
                    <p className={`text-md font-medium ${setPointData?.readDeviceLoading
                      ? "loading"
                      : setPointData?.readDeviceSuccessStatus
                        ? "read"
                        : (setPointData?.readDeviceErrorStatus || setPointData?.readDeviceReturnErrorStatus)
                          ? `error`
                          : ""}`}>
                      {setPointData?.readDeviceLoading
                        ? `${setPointData?.deviceAction === 'Read' ? 'Reading' : 'Updating'} Device`
                        : setPointData?.readDeviceSuccessStatus
                          ? `Device successfully ${setPointData?.deviceAction === 'Read' ? 'Read' : 'Updated'}`
                          : setPointData?.readDeviceErrorStatus
                            ? `Device failed to ${setPointData?.deviceAction === 'Read' ? 'Read' : 'Update'}`
                            : setPointData?.readDeviceReturnErrorStatus
                              ? `Comms timeout`
                              : ''}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}

export default SetPointToast;