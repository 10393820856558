import React from 'react'
import HealthScoreChart from './HealthScoreChart'
import HealthScoreTable from './HealthScoreTable'
import '../components/HealthScore.scss'

const HealthScore = () => {
    return (
        <div className='health-score-container flex flex-col'>
            <HealthScoreChart />
            <HealthScoreTable />
        </div>
    )
}

export default HealthScore