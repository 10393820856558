import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import './TicketDetails.scss';
import '../../Dashboard.scss';
import Close from '../../../../images/x-close.svg';
import chevron_right from '../../../../images/chevron-right.svg';
import { fetchTicketsScheduleById } from '../../DashboardSlice';
import download from '../../../../../src/images/download-icon.svg';
import { AppUser } from '../../../user/model/AppUser';
import { useNavigate } from 'react-router-dom';
import { updateAssetList, updateSelectedAsset } from '../../../navigation/AssetGroupsSlice';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../common/page-loader/ComponentLoader';
import NoData from '../no-data/NoData';
import { downloadDashboardCSV } from '../../DashboardServices';
import { getUrlsArray } from '../../../../utilities/CommonFunctions';
import moment from 'moment-timezone';
import { ReactComponent as SortIconDefault} from '../../../../images/Chevron-default-icon.svg';
import { ReactComponent as SortIconUp} from '../../../../images/Chevron-up-icon.svg';
import { ReactComponent as SortIconDown} from '../../../../images/Chevron-down-icon.svg';

interface TicketDetail {
  assetID?: string | undefined;
  wellName?: string | undefined;
  ticket?: string | undefined;
  operator?: string | undefined;
  assignedTo?: string | undefined;
  subject?: string | undefined;
  status?: string | undefined;
}

interface AssetEventSchedule {
  wellName: string;
  ticket: string;
  operator: string;
  status: string;
  assignedTo: string;
  subject: string;
  [key: string]: string | string[]; // Update this line to include string[]
}

type SortableKeys = keyof AssetEventSchedule;

const TicketDetails: React.FC = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const ticketDetails = useAppSelector((state) => state.dashboard.ticketDetails);
  const ticketsLoading = useAppSelector((state) => state.dashboard.ticketDetailsLoading);
  const urls = useAppSelector((state) => state.dashboard.urls);
  const storedUserData = localStorage.getItem('loggedInUser');
  const activeTab = sessionStorage.getItem('activeTabIndex');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  // const currentUser = useAppSelector((state) => state?.user?.currentUser);
  const dispatch = useAppDispatch();
  const [visibleTickets, setVisibleTickets] = useState<TicketDetail[]>([]);
  const [sortConfig, setSortConfig] = React.useState<{ key: SortableKeys; direction: 'asc' | 'desc' } | null>({
    key: 'wellName',
    direction: 'asc',
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [displayCount, setDisplayCount] = useState(10);
  const [loading, setLoading] = useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const zendeskUrl = urls && getUrlsArray(urls)?.[1]?.key === 'Zendesk_tickets_url' && getUrlsArray(urls)?.[1]?.value;
  const hElement = document.getElementById('main-root');

  useEffect(() => {
    if (storedUser?.id) {
      dispatch(fetchTicketsScheduleById(storedUser?.id));
    }
  }, []);

  useEffect(() => {
    if (ticketDetails?.length) {
      setVisibleTickets(ticketDetails?.slice(0, 5));
    }
  }, [ticketDetails]);

  const handleDownloadCSV = () => {
    toast.success('Ticket CSV download is in progress.!');
    const currentTime = moment().format("MMM-DD-YYYY hh:mm:ss A");
    const userId = storedUser?.id;
    const widgetName = 'Ticket';
    const groupName = 'All Wells';
    const data = { userId, widgetName, groupName };
    downloadDashboardCSV(data)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Lookout-Landing_Page_Ticket_' + currentTime + '.csv');
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(error, 'ticket details error');
        if (error?.message !== '') {
          toast.error('Ticket CSV download is failed. Please try after sometime!');
        }
      });
    setIsDropdownOpen(false);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
    hElement && hElement?.classList.add('modal-open');
  };

  const handleClose = () => {
    setIsModalOpen(false);
    hElement && hElement?.classList.remove('modal-open');
    setVisibleTickets(ticketDetails?.slice(0, 5) || []);
  };

  const requestSort = (key: SortableKeys) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig?.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  function getValueByKey(item: AssetEventSchedule, key: keyof AssetEventSchedule): string {
    const value = item[key];
    if (Array.isArray(value)) {
      return value.join(', ');
    }
    return value;
  }

  const sortedTicketDetails = React.useMemo(() => {
    const sortableItems = visibleTickets ? [...visibleTickets] : [];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = getValueByKey(a as unknown as AssetEventSchedule, sortConfig.key) as string;
        const bValue = getValueByKey(b as unknown as AssetEventSchedule, sortConfig.key) as string;

        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [ticketDetails, sortConfig, visibleTickets]);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleWellName = (wellName: string, assetID: string) => {
    window.scrollTo(0, 0);
    if (activeTab !== '0') {
      sessionStorage.setItem('activeTabIndex', '0');
    }
    const groupList = ticketDetails
      ? ticketDetails?.map((well) => {
        return {
          assetId: well?.assetID,
          assetName: well?.wellName,
          industryApplicationId: 4,
        };
      })
      : [];

    const selectedAsset = {
      assetName: wellName,
      assetGroupName: 'Well Groups',
      assetId: assetID,
      searchString: undefined,
    };

    if (selectedAsset) {
      sessionStorage.setItem('selectedWellName', JSON.stringify(selectedAsset));
    }

    if (groupList) {
      sessionStorage.setItem('wellNameList', JSON.stringify(groupList));
    }

    dispatch(updateSelectedAsset(selectedAsset));
    dispatch(updateAssetList(groupList));
    navigate('/layout/assets/?tab=details');
  };

  useEffect(() => {
    setNoResultsFound(ticketDetails?.length === 0);
  }, [ticketDetails]);

  const displayedRecords = ticketDetails?.slice(0, displayCount);
  const observer = useRef<IntersectionObserver>();
  const lastDocumentElementRef = useCallback(
    (node: HTMLTableRowElement | null) => {
      if (loading || !ticketDetails) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && displayCount < ticketDetails?.length) {
          setLoading(true);
          setTimeout(() => {
            setDisplayCount((prevDisplayCount) => prevDisplayCount + 10);
            setLoading(false);
          });
        }
      });
      if (node instanceof HTMLTableRowElement) observer.current.observe(node);
    },
    [loading, ticketDetails?.length],
  );

  return (
    <>
      <ToastContainer position='top-right' autoClose={3000} closeOnClick={true} />
      <div className='ticket-details-card' style={{ height: ticketDetails?.length ? 'auto' : '100%' }}>
        <div className='table-header-section'>
          <div
            className='title'
          >
            Tickets
          </div>
          <div className=''>
            <div className='header-icon'>
              <div className='dropdown-container' ref={dropdownRef}>
                <button className='dropdown-btn' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                  <span className='dot'></span>
                  <span className='dot'></span>
                  <span className='dot'></span>
                </button>
                {isDropdownOpen && (
                  <div className='dropdown-options'>
                    <button>Actions</button>
                    <button className={`btn btn-default ${(ticketDetails?.length === 0 || ticketDetails === undefined || ticketDetails === null) ? 'btn-disabled' : ''}`} onClick={handleDownloadCSV}>
                      <img src={download} alt='icon' className='btn-icon' />
                      Download CSV
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {ticketsLoading || !ticketDetails?.length ? <div className='separator'></div> : null}

        {ticketsLoading ? (
          <div className='flex items-center justify-center w-100 h-full'>
            <Loader />
          </div>
        ) : ticketDetails?.length ? (
          <>
            <div
              id='ticketsDetailsWidgetDiv'
              className='table-container-dashboard'
              style={{ width: '100%', height: 'auto' }}
            >
              <table className='table-data w-100'>
                <thead className='sticky-table-header'>
                  <tr className='table-header-cell'>
                    <th className='table-header-item well-name' onClick={() => requestSort('wellName')}>
                      Well name
                      {sortConfig?.key === 'wellName' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                    </th>
                    <th className='table-header-item ticket' onClick={() => requestSort('ticket')}>
                      Ticket# 
                      {sortConfig?.key === 'ticket' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                    </th>
                    <th className='table-header-item operator' onClick={() => requestSort('operator')}>
                      Operator 
                      {sortConfig?.key === 'operator' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                    </th>
                    <th className='table-header-item status' onClick={() => requestSort('status')}>
                      Status 
                      {sortConfig?.key === 'status' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                    </th>
                    <th className='table-header-item assigned' onClick={() => requestSort('assignedTo')}>
                      Assigned to{' '}
                      {sortConfig?.key === 'assignedTo' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                    </th>
                    <th className='table-header-item subject' onClick={() => requestSort('subject')}>
                      Subject 
                      {sortConfig?.key === 'subject' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedTicketDetails.map((ticket, index) => {
                    return (
                      <tr key={index} className='table-row-general'>
                        <td
                          className='well-name pointer'
                          onClick={() => handleWellName(ticket.wellName ?? '', ticket?.assetID ?? '')}
                        >
                          {ticket.wellName ?? '-'}
                        </td>
                        <td className='ticket'>{ticket.ticket ?? '-'}</td>
                        <td className='operator' id='operator' data-title={ticket.operator}>
                          {ticket.operator && ticket.operator.length >= 20
                            ? `${ticket.operator.substring(0, 15)}...`
                            : ticket.operator}
                        </td>
                        <td className='status'>{ticket.status ?? '-'}</td>
                        <td className='assigned'>{ticket.assignedTo ?? '-'}</td>
                        <td data-title={ticket.subject} className='subject' id='subject'>
                          {ticket.subject && ticket.subject.length >= 20
                            ? `${ticket.subject.substring(0, 12)}...`
                            : ticket.subject}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className='flex justify-start'>
              <button className='btn view-all' onClick={handleOpen}>
                <span>View all</span>
                <img src={chevron_right} alt='' className='ml-2' />
              </button>
            </div>
            {isModalOpen && (
              <div className='modal-overlay'>
                <div className='view-all-modal ticket-details-modal'>
                  <div className='flex flex-row flex-grow profile-block' style={{ marginTop: '0px' }}>
                    <div className='notify-card'>
                      <div className='modal-header'>
                        <div className='flex'>
                          <div className='grow'>
                            <h3 className='title'>Tickets</h3>
                          </div>
                          <div className='flex-none'>
                            <button onClick={handleClose}>
                              <img src={Close} alt='Expand' />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className='modal-content'>
                        {loading ? (
                          <div className='flex items-center'>
                            <Loader />
                          </div>
                        ) : displayedRecords?.length && ticketDetails?.length ? (
                          <>
                            <div className='chart-wrap-modal ticket-modal'>
                              <table className='w-100 table table-fixed border-collapse'>
                                <thead>
                                  <tr className='table-header-cell'>
                                    <th className='table-header-item well-name'>Well name</th>
                                    <th className='table-header-item ticket'>Ticket#</th>
                                    <th className='table-header-item operator'>Operator</th>
                                    <th className='table-header-item status'>Status</th>
                                    <th className='table-header-item assigned'>Assigned to</th>
                                    <th className='table-header-item subject'>Subject</th>
                                  </tr>
                                </thead>
                                <tbody className='ticket-modal-body'>
                                  {displayedRecords?.map((job, index) => (
                                    <tr
                                      key={index}
                                      className='table-row-general'
                                      ref={index + 1 == displayedRecords.length ? lastDocumentElementRef : null}
                                    >
                                      <td
                                        className='well-name pointer'
                                        onClick={() => handleWellName(job?.wellName ?? '', job?.assetID ?? '')}
                                      >
                                        {job.wellName ?? '-'}
                                      </td>
                                      <td className='ticket'>{job.ticket ?? '-'}</td>
                                      <td className='operator'>{job.operator ?? '-'}</td>
                                      <td className='status'>{job.status ?? '-'}</td>
                                      <td className='assigned'>{job.assignedTo ?? '-'}</td>
                                      <td className='subject'>{job.subject ?? '-'}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                            <div className='separator'></div>
                            <div className='flex items-center justify-center pt-2 viewall-url'><a href={zendeskUrl} target='_blank'>View in zendesk</a></div>
                          </>
                        ) : (
                          noResultsFound && displayedRecords?.length === 0 && <NoData heading='No data found' />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <NoData heading='No data found' />
        )}
      </div>
    </>
  );
};

export default React.memo(TicketDetails);
