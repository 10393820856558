import React, { useEffect, useState } from 'react';
import calendarIcon from '../../../../images/calendar-icon.svg';
import { defaultStaticRanges, defineds, formateDate } from '../../../common/DateRangeSelector/DefaultRanges';
import { useDetectClickOutside } from 'react-detect-click-outside';
import DateRangeSelector from '../../../common/DateRangeSelector/DateRangeSelector';
import filterIcon from '../../../../images/filter-icon.svg';
import searchIcon from '../../../../images/search-icon.svg';
import downIcon from '../../../../images/chevron-down.svg';
import { fetchSleVariables } from './SleSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import '../history/History.scss';

import { showToaster } from '../../../dashboard/components/asset-location/AssetList';
import { SleVariables } from './SleSlice';

type SleHistoryChartFilterProps = {
    filteredVal: string[];
    setFilteredVal: any;
    selectedOption: string;
    setSelectedOption: any;
    setSelectedDateRange: any;
    selectedDateRange: {
        startDate: Date;
        endDate: Date;
        key: string;
    };
    setPrevFilteredVal: any
};

const SleHistoryChartFilter: React.FC<SleHistoryChartFilterProps> = ({ filteredVal, setFilteredVal, selectedOption, setSelectedOption, setSelectedDateRange, selectedDateRange, setPrevFilteredVal }) => {
    const dispatch = useAppDispatch();
    const sleVariable = useAppSelector((state) => state.sleTimeSeries.variable)
    const [showFilterPopup, setShowFilterPopup] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [filteredValues, setFilteredValues] = useState<string[]>([]);
    const [prevFilteredValues, setPrevFilteredValues] = useState<string[]>([]);
    const [searchfiltered, setSearchFiltered] = useState<SleVariables[]>([]);
    const [searchInputVal, setSearchInputVal] = useState<string>('');
    const selectedAsset = useAppSelector((state) => state.assetGroups);


    useEffect(() => {
        if (filteredValues) {
            const checkedVariables =
                sleVariable?.filter((data) => filteredValues?.includes(data?.variable))?.map((obj) => obj?.variable) ?? [];
            setFilteredVal(checkedVariables)
        }
    }, [selectedDateRange])
    useEffect(() => {
        if (selectedAsset?.selectedAssetName) {
            dispatch(fetchSleVariables(selectedAsset?.selectedAssetName || ''))
                .unwrap()
                .then((response) => {
                    const filterSelectedData = response.data?.map((item: any) => item.variable);
                    setFilteredValues(filterSelectedData);
                    setFilteredVal(filterSelectedData);
                    setPrevFilteredValues(filterSelectedData)
                })
                .catch((error) => {
                    setFilteredValues([]);
                    setPrevFilteredValues([])
                    setFilteredVal([]);
                    console.error('Failed to fetch Filter data:', error);
                });
        }

    }, []);
    useEffect(() => {
        setPrevFilteredVal(prevFilteredValues)
    }, [prevFilteredValues])

    useEffect(() => {


        if (sleVariable) {
            const filterSelectedData = sleVariable?.map((item) => item.variable);
            setFilteredValues(filterSelectedData);
            setPrevFilteredValues(filterSelectedData)
            setFilteredVal(filterSelectedData);
            setSearchFiltered(sleVariable);
        }
    }, [sleVariable]);

    const isFilterChanged = prevFilteredValues?.some((item) => !filteredValues?.includes(item)) || filteredValues?.some((item) => !prevFilteredValues?.includes(item));

    const handleSlideOutClick = (e: any) => {
        if (e.target.id === 'range-calendar-input') return;
        if (showCalendar) {
            setShowCalendar(!showCalendar);
        }
    };
    const ref = useDetectClickOutside({ onTriggered: handleSlideOutClick });

    const handleFilterOutClick = (e: any) => {
        if (e.target.id === 'delete-btn') return;

        if (showFilterPopup) {
            setFilteredValues([...prevFilteredValues])
            setShowFilterPopup(false);
            setSearchInputVal('')
            setSearchFiltered(sleVariable ?? []);
        }
    };

    const filterRef = useDetectClickOutside({ onTriggered: handleFilterOutClick });

    const handleSearch = (e: any) => {
        const val = e.target.value;
        setSearchInputVal(val);

        if (val === '') {
            setSearchFiltered(sleVariable ?? []);
        } else {
            const updatedFilter = sleVariable?.filter((item) => item.variableName.toLowerCase().includes(val.toLowerCase())) ?? [];
            setSearchFiltered(updatedFilter);
        }
    };

    const handleClearButton = () => {
        setFilteredValues([]);
        // setFilteredVal([]);
        setSearchFiltered(sleVariable ?? []);
        setSearchInputVal('');
    };

    const handleFilterDropdownClick = () => {
        setShowFilterPopup(!showFilterPopup);

        if (!showFilterPopup) {
            setSearchFiltered(sleVariable ?? []);
            setSearchInputVal('');
        }

    };

    const handleApplyButton = () => {
        if (filteredValues?.length === 0) {
            showToaster('Please select atleast one filter', 'error');
            return;
        }
        const checkedVariables =
            sleVariable?.filter((data) => filteredValues?.includes(data?.variable))?.map((obj) => obj?.variable) ?? [];
        if (!checkedVariables.every(item => filteredVal?.includes(item))) {
            setFilteredVal(checkedVariables);
        }
        setPrevFilteredValues(checkedVariables)
        setShowFilterPopup(false);
    };

    const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const checkFilterAvl = filteredValues?.includes(value);
        if (checkFilterAvl) {
            const updatedFilteredValues = filteredValues?.filter((filteredValue) => filteredValue !== value);
            setFilteredValues(updatedFilteredValues);
            // dispatch(setFilteredValues(updatedFilteredValues));
        } else {
            // dispatch(addFilterValue(value))
            setFilteredValues((prev) => [...prev, value]);
        }
    };




    const handleSparklineClick = () => {
        if (showFilterPopup) {
            setShowFilterPopup(false)
            if (isFilterChanged) {
                setFilteredValues([...prevFilteredValues])
            }
        }
    }

    const handleChartClick = () => {
        if (sleVariable) {
            const filterSelectedData = sleVariable?.map((item) => item.variable);
            setFilteredValues(filterSelectedData);
            setPrevFilteredValues(filterSelectedData)
            setFilteredVal(filterSelectedData);
            setSearchFiltered(sleVariable);
        }
    }

    return (
        <>
            <div className='chart-container flex-row pt-3 pr-6'>
                <div className='select-buttons' style={{ marginRight: '23px', background: 'inherit' }}>
                    <button
                        className={`select-button ${selectedOption === 'Chart' ? 'active' : ''}`}
                        style={{ background: 'inherit' }}
                        onClick={() => { setSelectedOption('Chart'); handleChartClick() }}
                    >
                        Chart
                    </button>
                    <button
                        className={`select-button ${selectedOption === 'SparkLines' ? 'active' : ''}`}
                        style={{ background: 'inherit' }}
                        onClick={() => { setSelectedOption('SparkLines'); handleSparklineClick() }}
                    >
                        SparkLines
                    </button>
                </div>
                {selectedOption === 'Chart' && (
                    <>
                        <div
                            className='well-performance-trends__input-group'
                            style={{ height: '45px', background: showCalendar ? 'var(--Dark-mode-800, #001023)' : 'inherit' }}
                        >
                            <img src={calendarIcon} alt='Range Calendar' />
                            <input
                                id='range-calendar-input'
                                className='input-date'
                                value={`${formateDate(selectedDateRange.startDate)} - ${formateDate(selectedDateRange.endDate)}`}
                                onClick={() => setShowCalendar(!showCalendar)}
                            />
                        </div>

                        <div className='history-container-filter'>
                            <div style={{ position: 'relative', zIndex: '8' }}>
                                <div
                                    id='filter-btn'
                                    style={{
                                        border: showFilterPopup
                                            ? '1px solid var(--Dark-mode-400, #4A5463)'
                                            : '1px solid var(--Dark-mode-400, #4A5463)',
                                        background: showFilterPopup ? 'var(--Dark-mode-800, #001023)' : 'inherit',
                                        cursor: 'pointer',
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleFilterDropdownClick();
                                    }}
                                >
                                    <img src={filterIcon} alt='filterIcon' />
                                    Filter {`(${filteredValues?.length})`}
                                    <img src={downIcon} className={!showFilterPopup ? '' : 'rotate-180'} alt='upIcon' />
                                </div>

                                {showFilterPopup && (
                                    <div className='trends-filter-modal-container' ref={filterRef}>
                                        <div className='trends-filter-modal-header'>Add/Remove trends</div>
                                        <div className='trends-filter-search history-search relative'>
                                            <img src={searchIcon} placeholder='Search for trend' alt='search' className='history-search-icon' />
                                            <input
                                                type='text'
                                                value={searchInputVal}
                                                placeholder='Search for trend'
                                                onChange={handleSearch}
                                                className='history-filter-search'
                                            />
                                        </div>
                                        <div className='trends-filter-modal-body'>
                                            {searchfiltered?.map((val) => {
                                                return (
                                                    <>
                                                        <div className='checkbox-main-container'>
                                                            <label className='checkbox-container'>
                                                                <input
                                                                    type='checkbox'
                                                                    id='showCheckbox'
                                                                    checked={filteredValues?.includes(val?.variable)}
                                                                    onChange={handleCheckbox}
                                                                    value={val?.variable}
                                                                />
                                                                <span className='checkbox-checkmark'></span>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{val?.variableName}&nbsp;
                                                            </label>
                                                        </div>
                                                    </>
                                                );
                                            })}
                                        </div>
                                        <div className='trends-filter-modal-footer'>
                                            <button className='footer-btn' onClick={handleClearButton}>
                                                Clear chart
                                            </button>
                                            <button className='footer-btn' onClick={handleApplyButton}>
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}

                {selectedOption !== 'Chart' && <div style={{ width: '414px' }}></div>}
            </div>
            <div ref={ref} className='history-date-range'>
                {showCalendar && (
                    <DateRangeSelector setShowCalendar={setShowCalendar} setSelectedDateRange={setSelectedDateRange} staticRanges={defaultStaticRanges} minDate={defineds?.startOfLastYear} maxDate={defineds?.endOfToday} selectedDateRange={selectedDateRange} />
                )}
            </div>
        </>
    )
}

export default SleHistoryChartFilter