


interface ProgressBarProps {
    progress: number
}
const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
    const progressStyle = {
        width: `${progress}%`,
        backgroundColor: '#60BFDA',
        height: '8px',
        borderRadius: '8px'
    };

    return (
        <div style={{ backgroundColor: '#e0e0e0', height: '8px', width: '100%', borderRadius: '4px' }}>
            <div style={progressStyle} />
        </div>
    );
};

export default ProgressBar;