import React, { memo, useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import annotation_plus from './../../../../images/expand-icon.svg';
import download from '../../../../../src/images/download-icon.svg';
import NoData from '../../../dashboard/components/no-data/NoData';
import Loader from '../../../common/page-loader/ComponentLoader';
import SpeedChart from './charts/SpeedChart';
import SpeedModal from './modals/SpeedModal';
import { SpeedDetailsProps, DataPoint } from './interfaces/speed.interface';
import { HZbandsData, RPMbandsData } from './constants/speed.contants';
import SpeedServices from './services/speed.services';


const SpeedDetails: React.FC<SpeedDetailsProps> = ({ heading, selectedWell }) => {
  const loading = false;  // Set loading based on your data fetching logic
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const speedHzChartdiv1 = useRef<HTMLDivElement | null>(null);
  const speedRpmChartdiv2 = useRef<HTMLDivElement | null>(null);
  const speedHzChartdiv3 = useRef<HTMLDivElement | null>(null);
  const speedRpmChartdiv4 = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [data, setData] = useState<DataPoint[]>([]);
  const hElement = document.getElementById('main-root');


  const openModal = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setModalIsOpen(true);
    hElement?.classList.add('modal-open');
  };

  const handleClose = () => {
     setModalIsOpen(false);
     hElement && hElement?.classList.remove('modal-open');
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getSpeedChartData = async () => {
    try {
      const request: any = {
        assetName: selectedWell,
      };
      const response = await SpeedServices.getSpeedData(
        request
      );
      if (response?.statusCode === 200 && response?.success) {
        setData(response?.data);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      //setIsLoading(false);
    }
  };

  useEffect(() => {  
    if(selectedWell){
      getSpeedChartData(); 
    }    
  }, [selectedWell]);

  
  return (
    <>
      <ToastContainer position='top-right' autoClose={3000} />
      <div className='grid-item-text h-full graph-card well-graph-card'>
        <div className='table-header-section pie-section'>
          <div className='title'>{heading}</div>
          <div className='header-icon'>
            <button className='expand-btn cancelSelectorName' onClick={openModal} style={{ cursor: 'pointer', zIndex: 0 }}>
              <img src={annotation_plus} alt='Expand' className='img-border' />
            </button>
          </div>
          <div className='mr-4 dropdown-container' ref={dropdownRef}>
            <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              <span className='dot'></span>
              <span className='dot'></span>
              <span className='dot'></span>
            </button>
            {isDropdownOpen && (
              <div className='dropdown-options'>
                <button>Actions</button>
                <button className={`btn btn-default cancelSelectorName`}>
                  <img src={download} alt='icon' className='btn-icon' />
                  Download PDF
                </button>
                <button className={`btn btn-default cancelSelectorName`}>
                  <img src={download} alt='icon' className='btn-icon' />
                  Download CSV
                </button>
              </div>
            )}
          </div>
        </div>
        <hr />
        <div id="speedChart-container" className='flex flex-wrap items-center justify-center wellChartSection speedChart-container-dashboard nodata'>

          {loading ? (
            <div className='flex items-center justify-center w-100'>
              <Loader />
            </div>
          ) : data?.length > 0 ? (
            <>
              {/* <div id={`speedchartdiv`} ref={speedHzchartdiv} className='chart-wrapper'></div> */}
              <SpeedChart chartID={'speedHzChartdiv1'} 
                chartRef={speedHzChartdiv1} classValue={'w-[50%]'} 
                data={data} chartName={'HZ'} 
                minValue={50} maxValue={350} startAngle={-220} endAngle={40} 
                innerCircleEndvalue={350} bandsData={HZbandsData} spacingValue={4} />
              <SpeedChart chartID={'speedRpmChartdiv2'} 
              chartRef={speedRpmChartdiv2} classValue={'w-[50%]'} 
              data={data} chartName={'RPM'} 
              minValue={50} maxValue={5040} startAngle={-230} endAngle={40} 
              innerCircleEndvalue={5040} bandsData={RPMbandsData} spacingValue={50} />
            </>
          ): (
            <NoData heading='No data found' />
          )}         

        </div>
      </div>
      <SpeedModal
          isOpen={modalIsOpen}
          onRequestClose={handleClose}
          heading={'Speed'}
          loading={loading}
          modelWidth={'70rem'}
          modelClass={'wellChartModal'}
          innerComponent={
            <>
              <SpeedChart chartID={'speedHzChartdiv3'} chartRef={speedHzChartdiv3} classValue={'w-[50%]'} data={data} chartName={'HZ'}  minValue={50} maxValue={350} startAngle={-220} endAngle={40} innerCircleEndvalue={350} bandsData={HZbandsData}  spacingValue={4} />
              <SpeedChart chartID={'speedRpmChartdiv4'} chartRef={speedRpmChartdiv4} classValue={'w-[50%]'} data={data} chartName={'RPM'} minValue={50} maxValue={5040} startAngle={-230} endAngle={40} innerCircleEndvalue={5040} bandsData={RPMbandsData} spacingValue={50}/>
            </>
          }
      />
    </>
  );
};

export default memo(SpeedDetails);  