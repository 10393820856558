import React from 'react';
//import ControlRoomSwitcher from './ControlRoomSwitcher';
import { switcherData } from './constants/customizeControlroom.constants';
import './CustomizeControlRoom.scss';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { toggleVisibility } from './slices/CustomizeControlroomSlice';
import ToggleSwitch from '../../../common/ToggleSwitch/ToggleSwitch';


const CustomizeControlRoom = () => {

    const dispatch = useAppDispatch();
    const visibility = useAppSelector((state) => state.CustomizeControlroom.visibility);

    const handleToggleChange = (id: number) => {
       dispatch(toggleVisibility(id));
    }

  return (
    <div className='customizeControlRoom'>
        {
            switcherData?.map((item, index)=>(
                <div key={index}>
                    <div className='flex justify-start px-4 py-2 mb-3 rounded-md text-[#B8C5CC] bg-[#131F2E] controlRoomSwitcher-box'>
                        <div className='pr-3 controlRoomSwitcher-content'>
                            <h3 className='text-left text-[16px] font-semibold p-0 m-0 mb-1 text-[#F7F9F9] heading-h3'>{item.heading}</h3>
                            <p className='text-left text-[#B8C5CC] text-sm  p-0 m-0 mb-5 description'>{item.description}</p>
                        </div>
                        <div className='min-w-[60px] flex justify-center items-center controlRoomSwitcher-switcher'>
                            <ToggleSwitch checked={visibility[item.id]} onChange={() => handleToggleChange(item.id)} label={''} />
                        </div>
                    </div>
 
                </div>
            ))
        }
        
    </div>
  )
}

export default CustomizeControlRoom;