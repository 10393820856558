import React, { /* useCallback, */ useCallback, useEffect, useState } from 'react';
import { Responsive, WidthProvider  } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import SpeedDetails from './components/speed-chart/SpeedDetails';
import AmpsDetails from './components/amps-chart/AmpsDetails';
import TemperatureDetails from './components/temperature-chart/TemperatureDetails';
import ProductionDetails from './components/production-chart/ProductionDetails';
import HarmonicDistortionDetails from './components/harmonic-distortion-chart/HarmonicDistortionDetails';
import CasingTubingDetails from './components/casing-tubing-chart/CasingTubingDetails';
import IntakeDischargeDetails from './components/intake-discharge-chart/IntakeDischargeDetails';
import BackPressureDetails from './components/back-pressure-chart/BackPressureDetails';
import './WellControlroomCharts.scss';
//import { useAppSelector } from '../../hooks/storeHooks';
import { debounce } from "lodash";
import { switcherData } from '../controlroom/components/customize-control-room/constants/customizeControlroom.constants';
import { useAppSelector } from '../../hooks/storeHooks';

interface LayoutItem {
    i: string;
    x: number;
    y: number;
    w: number;
    h: number;
    minH?: number;
    minW?: number;
    maxW?: number;
    maxH?: number;
    static?: boolean;
  }
  interface Layouts {
    lg?:LayoutItem[];
    md?:LayoutItem[];
    [key: string]: LayoutItem[] | undefined
  }

const ResponsiveGridLayout = WidthProvider(Responsive);

const WellControlroomCharts: React.FC = () => {
  const visibility = useAppSelector((state) => state.CustomizeControlroom.visibility);
  const selectedWell = JSON.parse(localStorage.getItem('selectedWellControlRoom') || '{}');
  //const assetControlRoomDrawer = useAppSelector((state) => state.assetControlRoomDrawer);
    const initialLayout = { 
        lg : [
          { i: 'Speed', x: 0, y: 4, w: 4, h: 4, minH: 1.7, minW: 2.5  }, // Adjusted y value
          { i: 'Amps', x: 4, y: 4, w: 4, h: 4, minH: 1.7, minW: 2.5  }, // Adjusted y value
          { i: 'Temperature', x: 8, y: 4, w: 4, h: 4, minH: 1.7, minW: 2.5  }, // Adjusted y value
          { i: 'Production', x: 0, y: 8, w: 6, h: 4, minH:1.7, minW: 3.7 }, // Adjusted y value
          { i: 'Harmonic distortion', x: 6, y: 8, w: 6, h: 4, minH:1.7, minW: 3.7 }, // Adjusted y value
          { i: 'Casing Tubing', x: 0, y: 12, w: 4, h: 4, minH: 1.7, minW: 2.5  }, // Adjusted y value
          { i: 'Intake Discharge', x: 4, y: 12, w: 4, h: 4, minH: 1.7, minW: 2.5  }, // Adjusted y value
          { i: 'Back pressure', x: 8, y: 12, w: 4, h: 4, minH: 1.7, minW: 2.5  }, // Adjusted y value
        ],
        md : [
          { i: 'Speed', x: 0, y: 6, w: 6, h: 4, minH: 1.7, minW: 2.5  }, // Adjusted y value
          { i: 'Amps', x: 6, y: 6, w: 6, h: 4, minH: 1.7, minW: 2.5  }, // Adjusted y value
          { i: 'Temperature', x: 0, y: 8, w: 12, h: 4, minH: 1.7, minW: 2.5  }, // Adjusted y value
          { i: 'Production', x: 0, y: 8, w: 12, h: 4, minH:1.7, minW: 3.7 }, // Adjusted y value
          { i: 'Harmonic distortion', x: 6, y: 8, w: 6, h: 4, minH:1.7, minW: 3.7 }, // Adjusted y value
          { i: 'Casing Tubing', x: 0, y: 12, w: 4, h: 4, minH: 1.7, minW: 2.5  }, // Adjusted y value
          { i: 'Intake Discharge', x: 4, y: 12, w: 4, h: 4, minH: 1.7, minW: 2.5  }, // Adjusted y value
          { i: 'Back pressure', x: 8, y: 12, w: 4, h: 4, minH: 1.7, minW: 2.5  }, // Adjusted y value
        ]
    };
 

    const [layout, setLayout] = useState<Layouts>(initialLayout);
    const [dynamicWidth, setDynamicWidth] = useState(window.innerWidth);
    
    useEffect(() => {
        const handleResize = debounce(() => {
        const newWidth = window.innerWidth;
        if (newWidth !== dynamicWidth) {
              // Update the width based on the calculated value of 100vw - 80px
              setDynamicWidth(newWidth);
        }
       }, 200); 
    
        window.addEventListener('resize', handleResize);
        return () => {
         window.removeEventListener('resize', handleResize);
         handleResize.cancel(); // Cancel any pending debounced function
        };
    
    }, []);


  // Debounced version of onLayoutChange
  const debouncedLayoutChange = useCallback(
    debounce((allLayouts: Layouts) => {
      setLayout((prevLayouts) => {
        // Only update state if layouts are different
        if (JSON.stringify(prevLayouts) !== JSON.stringify(allLayouts)) {
          return allLayouts;
        }
        return prevLayouts; // Return the previous state if unchanged
      });
    }, 300), // Adjust debounce delay as needed
    []
  );

    // Wrapper function to call debounced function with the layout type
  const onLayoutChange = (currentLayout: LayoutItem[], allLayouts: Layouts) => {
   debouncedLayoutChange(allLayouts);
  };

  return (
    <div className='px-[24px] pt-[17px] pb-[24px] grid-container well-charts-container'>
        <ResponsiveGridLayout
            className='layout'
            layouts={{
              lg: layout.lg || [],
              md: layout.md || [],
            }}
            draggableCancel=".cancelSelectorName"
            breakpoints={{ lg: 1200, md: 996}}
            cols={{ lg: 12, md: 12}}
            rowHeight={75}
            onLayoutChange={onLayoutChange}
            isDraggable={true}
            width={dynamicWidth}
            useCSSTransforms={false}
            >
            {layout.lg?.filter((layout) => visibility[switcherData.findIndex((item) => item.heading === layout.i) + 1]).map((item) => (
              <div key={item.i} className='grid-item dashboard-graphs-wrapper wellcontrolroom-graphs' data-grid={item} style={{ zIndex: 9 }}>
                {item.i.startsWith('Speed') && <SpeedDetails heading={'Speed'} selectedWell={selectedWell.wellName}/>}
                {item.i.startsWith('Amps') && <AmpsDetails heading={'Amps'}/> }
                {item.i.startsWith('Temperature') && <TemperatureDetails heading={'Temperature'} selectedWell={selectedWell.wellName}/>}
                {item.i.startsWith('Production') && <ProductionDetails heading={'Production'}/>}
                {item.i.startsWith('Harmonic distortion') && <HarmonicDistortionDetails  heading={'Harmonic distortion'}/>}
                {item.i.startsWith('Casing Tubing') && <CasingTubingDetails heading={'Casing / Tubing'}/>}
                {item.i.startsWith('Intake Discharge') && <IntakeDischargeDetails heading={'Intake / Discharge'}/>}
                {item.i.startsWith('Back pressure') && <BackPressureDetails heading={'Back pressure'}/>}
              </div>
            ))}
        </ResponsiveGridLayout>
    </div>
  )
}

export default WellControlroomCharts;