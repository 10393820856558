import React, { useEffect, useState } from 'react';
import './wellWatchlistModal.scss';
import CloseIcon from '../../../../../../images/CloseIcon.svg';
import WellWatchListCard from './wellWatchListCard/wellWatchListCard';
import { useAppSelector } from '../../../../../../hooks/storeHooks';
import { WellWatchList } from '../../../interfaces/ControlRoomWells.interface';

interface wellWatchlistModal {
  handleCloseModal: () => void;
  createBtnHandler: () => void;
}

function WellWatchlistModal({ handleCloseModal, createBtnHandler }: wellWatchlistModal) {
  const wellWatchList = useAppSelector((state) => state.wellWatchList).wellWatchList as unknown as WellWatchList[];


  const [wells, setWells] = useState(wellWatchList);

  const handleCardClick = (id: string) => {
    const updatedWells = wells.map((well) => ({
      ...well,
      isActive: well.id === id,
    }));

    setWells(updatedWells);
  };
  useEffect(() => {
    setWells(wellWatchList);
  }, [wellWatchList]);


  return (
    <div className='build-watchlist-modal modal-overlay'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h2 className='modal-title'>Build watchlist</h2>
          <button className='close-btn' onClick={handleCloseModal}>
            <img src={CloseIcon} alt='close' />
          </button>
        </div>

        <div className='modal-body'>
          <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'right', marginRight: '30px' }}>
            <button onClick={() => createBtnHandler()} className='btn btn-primary border border-danger'> Build watchlist</button>
          </div>
          <div className='well-watch-list'>
            {wells.map((well) => (
              <WellWatchListCard
                key={well.id}
                id={well.id}
                title={well.name}
                description={well.description}
                count={well.count}
                isActive={well.isActive}
                onClick={handleCardClick}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WellWatchlistModal;
