
import { apiInstance } from "../../../../../config/HttpCommon";
import { AmpsApiURL } from "../constants/apms.constants";

const getAmpsChartData = async (assetName: string) => {
    try {
      const params = {
        assetName: assetName, 
      }
      const response = await apiInstance.get(
        AmpsApiURL.getAmpsData,
        {params}
      );
      return response.data;
    } catch (error) {
        console.error("Error while fetching data:", error);
        throw error;
    }
};

const AmpsServices = {
    getAmpsChartData,
};
  
export default AmpsServices;
  
