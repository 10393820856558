import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getWellProductionTrendsByAssetName } from "./services/wellControlroom.service";
import { ProductionTrendsApiResponse } from "./components/production-chart/interfaces/prod.interfaces";

interface wellControlroomCharts {
    productionTrends: ProductionTrendsApiResponse;
    loading: boolean;
    error: string | null;
}

const initialState: wellControlroomCharts = {
    productionTrends: {
        wellProductionTrend: {
            gasData: [],
            oilData: [],
            waterData: []
        },
        wellProductionTrendDetails: []
    },
    loading: false,
    error: null
}

export const fetchProductionTrendsByWell = createAsyncThunk(
    'wellControlroomCharts/fetchProductionTrendsByWell',
    async ({ userId, startDate, endDate, assetName }: { userId: string, startDate: string, endDate: string, assetName: string }) => {
        return await getWellProductionTrendsByAssetName(
            userId,
            startDate,
            endDate,
            assetName
        );
    }
);

const wellControlroomSlice = createSlice({
    name: 'wellControlroomCharts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchProductionTrendsByWell.fulfilled, (state, action) => {
            state.productionTrends = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchProductionTrendsByWell.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchProductionTrendsByWell.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
        });
    }
});

export default wellControlroomSlice.reducer;
export const wellControlroomActions = wellControlroomSlice.actions;
