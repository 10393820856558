import React, { useEffect, useState } from 'react';
import '../AssetInfo.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { fetchAssetInfoByEquipmentId, fetchAssetInfoEquipments } from '../AssetInfoSlice';
import { EquipmentItem, EquipmentIdInfo } from '../AssetInfoTypes';
import downIcon from '../../../images/chevronW-up.svg';
import Modal from '../components/modal/Modal';
import Loader from '../../common/page-loader/ComponentLoader';
import NoData from '../../dashboard/components/no-data/NoData';

const Equipment = () => {
  const [equipmentHeadingData, setEquipmentHeadingData] = useState<EquipmentItem[] | undefined>([]);
  const [activeAccordionId, setActiveAccordionId] = useState(null);
  const [equipmentBodyData, setEquipmentBodyData] = useState<EquipmentIdInfo[] | undefined>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalDescription, setModalDescription] = useState('');
  const hElement = document.getElementById('main-root');
  const dispatch = useAppDispatch();

  const { equipments, equipmentIdInfo, equipmentsLoading, equipmentAccordianLoading } = useAppSelector((state) => state.assetInfo);

  useEffect(() => {
    const selectedWellName = sessionStorage.getItem('selectedWellName');
    const selectedAssetNames = selectedWellName && JSON.parse(selectedWellName || '');
    dispatch(fetchAssetInfoEquipments({ assetName: selectedAssetNames?.assetName }));
  }, []);

  useEffect(() => {
    if (equipments && equipments.length > 0) {
      setEquipmentHeadingData(equipments);
    }
  }, [equipments]);

  useEffect(() => {
    setEquipmentBodyData(equipmentIdInfo);
  }, [equipmentIdInfo]);

  const handleAccordionClick = async (id: any) => {
    if (activeAccordionId === id) {
      setActiveAccordionId(null);
      setEquipmentBodyData([]);
      return;
    }

    setActiveAccordionId(id);
    const selectedWellName = sessionStorage.getItem('selectedWellName');
    const selectedAssetNames = selectedWellName && JSON.parse(selectedWellName || '');
    dispatch(fetchAssetInfoByEquipmentId({ assetName: selectedAssetNames?.assetName, equipmentId: id }));
  };

  const handleDescriptionClick = (description: any) => {
    setModalDescription(description);
    setModalIsOpen(true);
    hElement && hElement?.classList.add('modal-open');
  };

  const handleClose = () => {
    setModalIsOpen(false);
    hElement && hElement?.classList.remove('modal-open');
  };

  return (
    <div className='equipment-section'>
      {equipmentsLoading ? (
        <div className='flex items-center justify-center w-100 chart-loader'>
          <Loader />
        </div>
      ) : (
        equipmentHeadingData && equipmentHeadingData.length > 0 ? (
          <>
            <div className='esp-equipment'>ESP equipment</div>
            <div className="accordion">
              {equipmentHeadingData.map((heading) => (
                <div key={heading.value} className="accordion-item">
                  <div
                    className="accordion-heading"
                    onClick={() => handleAccordionClick(heading.value)}
                  >
                    {heading.key}
                    <span className={`accordion-icon ${activeAccordionId === heading.value ? 'open' : ''}`}>
                      {activeAccordionId === heading.value ? (
                        <img src={downIcon} className='rotate-180' alt='upIcon' />
                      ) : (
                        <img src={downIcon} className='' alt='upIcon' />
                      )}
                    </span>
                  </div>
                  {activeAccordionId === heading.value && (
                    <div style={{ minHeight: equipmentBodyData && equipmentBodyData.length > 0 ? 'unset' : '60px' }} className="accordion-body">
                      {equipmentAccordianLoading ? (
                        <div className='flex items-center justify-center w-100'>
                          <Loader />
                        </div>
                      ) : (
                        equipmentBodyData && equipmentBodyData.length > 0 ? (
                          equipmentBodyData.map((item, index) => (
                            <div
                              key={index}
                              className='description'
                              onClick={() => handleDescriptionClick(item.description)}
                            >
                              {item.description}
                            </div>
                          ))
                        ) : (
                          <div className='no-data-found'>No data found</div>
                        )
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
            {modalIsOpen && (
              <Modal isOpen={modalIsOpen} onRequestClose={handleClose} description={modalDescription} modelWidth={'70rem'} />
            )}
          </>
        ) : (
          <div className='flex flex-column items-center justify-center no-data-found'>
            <NoData heading='No data found' />
          </div>
        )
      )}
    </div>
  );
  };
  
  export default Equipment;  