import React, { useEffect, useState } from 'react'
import chevronDown from '../../../../images/chevron-down.svg';
import ToggleSwitch from '../../../common/ToggleSwitch/ToggleSwitch';
import './ScheduleEvent.scss'

interface ScheduleEventprops {
    eventHeading: string,
    selectedEventDropdown: string
    handleDropdownClick: (eventName: string) => void
    child: React.ReactNode
}
const ScheduleEvent: React.FC<ScheduleEventprops> = ({ eventHeading, selectedEventDropdown, handleDropdownClick, child }) => {
    const [isToggleActive, setIsToggleActive] = useState(false)

    useEffect(() => {
        if (selectedEventDropdown) {
            if (isToggleActive && eventHeading !== selectedEventDropdown)
                setIsToggleActive(false)
        }
    }, [selectedEventDropdown])

    const handleToggleChange = (event: any) => {
        event.stopPropagation
        setIsToggleActive(!isToggleActive)
    }


    return (
        <div className='schedule-event-container flex flex-col justify-center' >
            <div className='flex flex-row justify-between items-center' onClick={() => handleDropdownClick(eventHeading)}>
                <div className='w-2/3 event-heading'>{eventHeading}</div>
                <div className='flex flex-row w-1/3 justify-between items-center'>
                    <ToggleSwitch checked={isToggleActive} onChange={handleToggleChange} label={'Enable'} />
                    <button className='dropdown-btn' >
                        <img src={chevronDown} alt='chevron' className={`${eventHeading && selectedEventDropdown === eventHeading ? 'rotate-180' : ''}`} />
                    </button>
                </div>
            </div>
            {eventHeading && selectedEventDropdown === eventHeading ?
                <div className={`schedule-content ${!isToggleActive ? 'disabled' : ''}`}> {child}</div>
                : ''}
        </div>
    )
}

export default ScheduleEvent