import { apiInstance } from "../../../../../config/HttpCommon"; 
import { TempApiURL } from "../constants/temp.constants";


const getTemperatureData = async (data: any) => {
  try {
    const params = {
      assetName: data.assetName, 
    }
    const response = await apiInstance.get(
      TempApiURL.getTempData,
      {params}
    );
    return response.data;
  } catch (error) {
      console.error("Error while fetching data:", error);
      throw error;
  }
};

const TempServices = {
    getTemperatureData,
};
  
export default TempServices;
  