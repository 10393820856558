import React, { useEffect, useState } from 'react';
import { SchedulerTimes } from '../../model/SchedulerTimes';
import { Days } from '../../model/Days';
import CustomTimePicker from './CustomTimePicker';

interface SchedulerModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (schedulerTimes: SchedulerTimes) => void;
  schedulerTimes: SchedulerTimes | undefined | null;
}

const orderOfDays: Days[] = [
  Days.Mon,
  Days.Tue,
  Days.Wed,
  Days.Thu,
  Days.Fri,
  Days.Sat,
  Days.Sun,
];

// Function to get the current time in HH:MM format
const getCurrentTime = () => {
  const pad = (numberValue:number) => (numberValue < 10 ? '0' : '') + numberValue;
  const now = new Date();
  const hours = pad(now.getHours());
  const minutes = pad(now.getMinutes());
  return `${hours}:${minutes}`;
};

const SchedulerModal: React.FC<SchedulerModalProps> = ({
  isOpen,
  onClose,
  onSave,
  schedulerTimes,
}) => {

  const [updatedScheduleStartTime, setUpdatedScheduleStartTime] = useState<string>(getCurrentTime());
  const [updatedScheduleEndTime, setUpdatedScheduleEndTime] = useState<string>(getCurrentTime());
  const [selectedDays, setSelectedDays] = useState<Days[]>([]);
  const [validationError, setValidationError] = useState<string>('');
  
  useEffect(() => {    
    setUpdatedScheduleStartTime(schedulerTimes?.scheduleStartTime || getCurrentTime());
    setUpdatedScheduleEndTime(schedulerTimes?.scheduleEndTime || getCurrentTime());
    setSelectedDays(schedulerTimes?.scheduledDays || []);
  }, [schedulerTimes]);

  useEffect(() => {
    validateForm();
  }, [updatedScheduleStartTime, updatedScheduleEndTime, selectedDays]);

  const validateForm = () => {
    if (!isValidTimeFormat(updatedScheduleStartTime) || !isValidTimeFormat(updatedScheduleEndTime)) {
      setValidationError('Invalid time format. Please enter time in hh:mm format.');
    } else if (selectedDays.length === 0) {
      setValidationError('Please select at least one day.');
    } else {
      setValidationError('');
    }
  };

  const isValidTimeFormat = (timeString: string) => {
    const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    return timeRegex.test(timeString);
  };

  /* const handleStartTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedScheduleStartTime(e.target.value);
  }; */

  const handleStartTimeChange = (val: string) => {
    setUpdatedScheduleStartTime(val);
  };

  /* const handleEndTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedScheduleEndTime(e.target.value);
  }; */

  const handleEndTimeChange = (val: string) => {
    setUpdatedScheduleEndTime(val);
  };

  const handleSave = () => {
    if (validationError) return; 

    const updatedSchedulerTimes: SchedulerTimes = {
      scheduleId: schedulerTimes?.scheduleId || 0,
      scheduleStartTime: updatedScheduleStartTime,
      scheduleEndTime: updatedScheduleEndTime,
      scheduledDays: selectedDays,
      snoozeTime: schedulerTimes?.snoozeTime || 0,
    };

    onSave(updatedSchedulerTimes);
    onClose();
  };

  const handleClickDay = (day: Days) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter((selectedDay) => selectedDay !== day));
    } else {
      const newSelectedDays = [...selectedDays, day];
      const orderedSelectedDays = orderOfDays.filter(day => newSelectedDays.includes(day));
      setSelectedDays(orderedSelectedDays);
    }
  };

  const renderDayButtons = () => {
    return orderOfDays.map((day) => (
      <button
        key={day}
        className={`schedule-modal__repeat-btn ${selectedDays.includes(day) ? 'selected-btn' : ''}`}
        onClick={() => handleClickDay(day)}
      >
        {Days[day]}
      </button>
    ));
  };

  return isOpen ? (
    <div className='modal-overlay'>
      <div className='schedule-modal'>
        <div className='schedule-modal__container'>
          <div className='schedule-modal__card'>
            <div className='schedule-modal__header'>
              <p className='schedule-modal__title'>Set a schedule</p>
              <p className='schedule-modal__text'>Select when you would like to receive notifications.</p>
            </div>
            <div className='schedule-modal__main-container'>
              <div className='schedule-modal__time-block'>
                <div className='schedule-modal__form-control'>
                  <label className='schedule-modal__label'>From</label>
                  {/* <input
                    type='time'
                    className='schedule-modal__time-input-field'
                    value={updatedScheduleStartTime}

                    // value={updatedScheduleStartTime ? updatedScheduleStartTime : getCurrentTime()}
                    onChange={handleStartTimeChange}
                  /> */}
                  <CustomTimePicker currentTime={updatedScheduleStartTime} handleTimeChange={handleStartTimeChange}/>
                </div>                
                <div className='divider'></div>
                <div className='schedule-modal__form-control'>
                  <label className='schedule-modal__label'>To</label>                  
                  <CustomTimePicker currentTime={updatedScheduleEndTime} handleTimeChange={handleEndTimeChange}/>
                </div>
                <div className='divider'></div>
                <div className='schedule-modal__form-control'>
                  <label className='schedule-modal__label'>Repeat</label>
                  <div className='schedule-modal__btn-container'>{renderDayButtons()}</div>
                </div>
              </div>
              <div className='divider'></div>
              {validationError && <p style={{ color: 'red' }} className='schedule-modal__error-message'>{validationError}</p>}
              <div className='btn-notify'>
                <button type='button' className='btn btn-secondary' onClick={onClose}>
                  Cancel
                </button>
                <button type='button' className='btn btn-primary' onClick={handleSave}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default SchedulerModal;

