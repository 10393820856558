import React from 'react';
import Details from './components/Details';
import Events from './components/Events';
import Equipment from './components/Equipment';
import Map from './components/Map';
import TabWithUrlComponent from '../../components/TabWithUrl';

const AssetInfo = () => {

  const tabs = [
    { label: 'Details', component: Details },
    { label: 'Events', component: Events },
    { label: 'Equipment', component: Equipment },
    { label: 'Map', component: Map },
];

  return (
    <div className='asset-info-tabs'>
      <TabWithUrlComponent initialTab={{ tab: 'Wells' }} tabs={tabs} value='Wells' />
    </div>
  )
}

export default AssetInfo