import { apiInstance } from '../../../../config/HttpCommon';

export const getSleTimeSeriesData = async (wellName: string, startDate: Date | string, endDate: Date | string, variables: string, aggregate?: string, cancelToken?: any) => {
  const response = await apiInstance.get(`/v1/timeseries?AssetName=${encodeURIComponent(wellName)}&StartDate=${startDate}&EndDate=${endDate}&Variables=${variables}&Aggregate=${aggregate}`, { cancelToken });
  return response.data;
};

export const getSleVariable = async (assetName: string) => {
  const response = await apiInstance.get(`/v1/timeseries/variables?assetName=${encodeURIComponent(assetName)}`);
  return response.data;
};