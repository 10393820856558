import { useCallback, useEffect, useRef, useState } from "react";
import WatchListCard from "../watchListCard/watchListCard";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/storeHooks";
import { WellWatchList, WellWatchListData } from "../../interfaces/ControlRoomWells.interface";
import { deleteWatchList, emptyWellWatchList, fetchWellsWatchList } from "../ControlRoomWellsSlice";
import Loader from "../../../../common/page-loader/ComponentLoader";
import DeleteModal from "../DeleteModal/DeleteModal";

interface WatchWellsListProps {
    selectedWell: WellWatchList;
    setIsBuildWatchlistFiltersModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedWell: React.Dispatch<React.SetStateAction<WellWatchList>>;
    setActiveWell: React.Dispatch<React.SetStateAction<WellWatchList>>;
    activeWell: WellWatchList;
}

const WatchWellsList = (
    { selectedWell, setIsBuildWatchlistFiltersModalOpen, setSelectedWell, setActiveWell, activeWell }: WatchWellsListProps
) => {
    const dispatch = useAppDispatch();
    const wellWatchList = useAppSelector((state) => state.wellWatchList).wellWatchList.results as WellWatchList[];
    const wellWatchListData = useAppSelector((state) => state.wellWatchList).wellWatchList as WellWatchListData;

    const loading = useAppSelector((state) => state.wellWatchList.loading);

    const [wells, setWells] = useState(wellWatchList);
    const [activeDropdownId, setActiveDropdownId] = useState<string | null>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const observer = useRef<IntersectionObserver | null>(null);

    const lastDocumentRef = useCallback((node: HTMLDivElement | null) => {
        if (loading) return;
        if (!wellWatchListData.hasNextPage) return;

        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && wellWatchListData.hasNextPage) {
                dispatch(fetchWellsWatchList({
                    pageSize: 10,
                    pageNumber: Math.floor(wells.length / 10) + 1
                }))
            }
        });

        if (node) observer.current.observe(node);
    }, [wells?.length, wellWatchListData.hasNextPage, loading, dispatch]);

    const handleCardClick = (well: WellWatchList) => {
        if (selectedWell?.id === well.id) {
            setSelectedWell({} as WellWatchList);
        } else {
            setSelectedWell(well);
        }
    };

    const handleDropdownClick = (well: WellWatchList) => {
        setActiveDropdownId(well.id);
    };

    const handleCloseDropdown = () => {
        setActiveDropdownId(null);
    };

    useEffect(() => {
        setWells(wellWatchList);
        setSelectedWell({} as WellWatchList);
    }, [wellWatchList]);

    const handleDeleteWatchList = (well: WellWatchList) => {
        setActiveWell(well);
        setIsDeleteModalOpen(true);
    };

    const handleEditWatchList = (well: WellWatchList) => {

        setActiveWell(well);
        setIsBuildWatchlistFiltersModalOpen(true)
    };

    const onDeleteModalClose = () => {
        setIsDeleteModalOpen(false);
    };

    const onDeleteWatchList = async () => {
        if (activeWell.id) {
            setIsDeleteModalOpen(false);
            await dispatch(deleteWatchList(activeWell.id));
            dispatch(emptyWellWatchList());
            dispatch(fetchWellsWatchList({ pageSize: 10, pageNumber: 1 }));
            setActiveWell({} as WellWatchList);
        }
    };

    return (
        <div className='flex justify-center items-center h-200 watch-wells-list-container'>
            {isDeleteModalOpen && <DeleteModal onOkClick={onDeleteWatchList} onCloseClick={onDeleteModalClose} />}
            {loading && <Loader />}
            <div className={`${loading ? "hidden" : "flex"} watch-wells-list`}>
                {wells?.map((well, index) => (
                    <div
                        key={well.id + index}
                        ref={index === wells.length - 1 ? lastDocumentRef : null}
                    >
                        <WatchListCard
                            title={well.name}
                            description={well.description}
                            count={well.count}
                            isDropdownOpen={activeDropdownId === well.id}
                            onClick={handleCardClick}
                            onDropdownClick={handleDropdownClick}
                            onCloseDropdown={handleCloseDropdown}
                            isActive={well.id === selectedWell.id}
                            handleDeleteWatchList={handleDeleteWatchList}
                            handleEditWatchList={handleEditWatchList}
                            well={well}
                            key={well.id + index}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WatchWellsList;
