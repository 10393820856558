import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAssetDetail, getUserDetailById } from './AssetDetailService';
import { AssetDetailsResponseProps, UserDetailsProps } from './AssetDetailProps';
import { getLookupValue } from '../../../asset/services/AssetDataService';

export interface AggregateProps {
  name: string,
  minutes: number,
  aggregate: string
}
interface AssetDetailStateProps {
  loading: boolean;
  assetDetailLoading: boolean;
  message: string | null;
  assetDetail: AssetDetailsResponseProps | null;
  userDetail: UserDetailsProps | null;
  aggregateValue: AggregateProps[];
  xspocAggregateValue: AggregateProps[]
}

const initialState: AssetDetailStateProps = {
  loading: false,
  assetDetailLoading: false,
  message: null,
  assetDetail: null,
  userDetail: null,
  aggregateValue: [],
  xspocAggregateValue: []
};

export const fetchAggregateValue = createAsyncThunk('getAggregateValue', async (key: string) => {
  return await getLookupValue(key);
})

export const fetchXspocAggregateValue = createAsyncThunk('getXspocAggregateValue', async (key: string) => {
  return await getLookupValue(key);
})


export const fetchAssetDetail = createAsyncThunk(
  'events/fetchAssetDetail',
  async ({ userId, wellName }: { userId: string; wellName: string; }) => {
    const response = await getAssetDetail(userId, wellName);
    return response;
  },
);

export const fetchUserDetailById = createAsyncThunk(
  'events/fetchUserDetailById',
  async ({ id }: { id: string; }) => {
    const response = await getUserDetailById(id);
    return response;
  },
);

const assetDetailSlice = createSlice({
  name: 'assetDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle the fetchAssetDetail fulfilled action
    builder.addCase(fetchAssetDetail.pending, (state) => {
      state.loading = true;
      state.assetDetailLoading = true;
      state.assetDetail = null
    });
    builder.addCase(fetchAssetDetail.fulfilled, (state, action: PayloadAction<AssetDetailsResponseProps>) => {
      state.assetDetail = action.payload;
      state.loading = false;
      state.assetDetailLoading = false;
    });
    builder.addCase(fetchAssetDetail.rejected, (state, action) => {
      state.message = action.payload as string;
      state.loading = false;
      state.assetDetailLoading = false;
      state.assetDetail = null
    });

    // Handle the fetchUserDetailById fulfilled action
    builder.addCase(fetchUserDetailById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserDetailById.fulfilled, (state, action: PayloadAction<UserDetailsProps>) => {
      state.userDetail = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchUserDetailById.rejected, (state, action) => {
      state.message = action.payload as string;
      state.loading = false;
    });

    // Handle the fetch aggregate value
    builder.addCase(fetchAggregateValue.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAggregateValue.fulfilled, (state, action) => {
      const value = JSON.parse(action.payload.data.value) || []
      state.aggregateValue = value
      state.loading = false;
    });
    builder.addCase(fetchAggregateValue.rejected, (state) => {
      state.aggregateValue = []
      state.loading = false;
    });

    // Handle the fetch xspoc aggregate value
    builder.addCase(fetchXspocAggregateValue.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchXspocAggregateValue.fulfilled, (state, action) => {
      const value = JSON.parse(action.payload.data.value) || []
      state.xspocAggregateValue = value
      state.loading = false;
    });
    builder.addCase(fetchXspocAggregateValue.rejected, (state) => {
      state.xspocAggregateValue = []
      state.loading = false;
    });
  },
});

export default assetDetailSlice.reducer;