import { http, wellControlInstance } from "../../../../config/HttpCommon";

export const getSetPointByWellId = async (wellId: string) => {
  const response = await http.get(`/getSetPoint/${wellId}`);
  return response.data;
};

export const getSetPointValuesByName = async (id: string, setPointName: string) => {
  const response = await http.get(`/getSetPointItemsBySetPointName/${id}/${setPointName}`);
  return response.data;
};

export const updateSetpointValuesData = async (id: string, setPoint: any, socketId: string) => {
  const updateData = {
    assetId: id,
    addressValues: setPoint,
    socketId: socketId
  }
  const response = await wellControlInstance.put(`/Setpoints`, updateData);

  return response.data;
};

export const getDeviceValue = async (id: string, addresses: string, socketId: string) => {
  const response = await wellControlInstance.get(`/Setpoints?assetId=${id}&addresses=${addresses}&socketId=${socketId}`);
  return response.data;
}
