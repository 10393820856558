import React, { useEffect, useState, useMemo } from 'react';
import AllWellsTable from './all-wells-table/AllWellsTable';
import './AllWells.scss';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/storeHooks';
import { fetchAssetList, resetControlRoomWells } from './AllWellSlice';
import Loader from '../../../../common/page-loader/ComponentLoader';
import { WellWatchList } from '../../interfaces/ControlRoomWells.interface';

interface AllWellsProps {
  toggleModal: (modalType: 'watchlist' | 'filters', isOpen: boolean) => void;
  selectedWell: WellWatchList;
  setSelectedWell: React.Dispatch<React.SetStateAction<WellWatchList>>;
}

const AllWells = ({ toggleModal, selectedWell, setSelectedWell }: AllWellsProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const assetListLoading = useAppSelector((state) => state.AssetList.loading);
  const dispatch = useAppDispatch();

  const searchValue = useMemo(() => searchTerm, [searchTerm]);

  useEffect(() => {
    const fetchAssets = () => {
      dispatch(resetControlRoomWells())
      const params = selectedWell.id
        ? { pageSize: 25, pageNumber: 1, watchListId: selectedWell.id }
        : { pageSize: 25, pageNumber: 1 };
      dispatch(fetchAssetList(params));
    };

    fetchAssets();
  }, [dispatch, selectedWell.id]);

  return (
    <>
      <div className='all-wells-section'>
        <div className='tab-content set-point-wrap'>
          <div className='card profile-card m-0'>
            <div className='header'>
              <div className='flex flex-row w-100'>
                <p
                  onClick={() => selectedWell.name && setSelectedWell({} as WellWatchList)}
                  className={`title w-auto whitespace-nowrap ${selectedWell.name ? 'active-color' : ''}`}
                >
                  All Wells
                </p>
                {selectedWell.name && (
                  <>
                    <p className='title w-auto ml-2'>/</p>
                    <p className='title w-auto whitespace-nowrap ml-2'>{selectedWell.name}</p>
                  </>
                )}
              </div>
              <div className='breadcrumb'></div>
            </div>

            <div className='serch-filter-section flex flex-row w-100'>
              <div className='w-80'>
                <label className='relative block'>
                  <span className='sr-only'>Search by well name or operator</span>
                  <span className='absolute inset-y-0 left-0 flex items-center pl-2'>
                    <svg className='h-5 w-5 fill-slate-300' viewBox='0 0 20 20'>
                      <path
                        fillRule='evenodd'
                        d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                        clipRule='evenodd'
                      ></path>
                    </svg>
                  </span>
                  <input
                    className='custom-text-input'
                    placeholder='Search by well name or operator'
                    type='text'
                    name='search'
                    value={searchValue}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </label>
              </div>
            </div>

            <div className='body all-wells-table-body'>
              {assetListLoading ? (
                <div className='loader-container-watch-list'>
                  <Loader />
                </div>
              ) : (
                <div className='all-wells-table-block'>
                  <div className='flex flex-row'>
                    <AllWellsTable toggleModal={toggleModal} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllWells;
