
import React, { useState, useMemo, useEffect } from 'react';
import { Column, ColumnInstance, useTable } from 'react-table';
import { AllWellsTableType } from './AllWellsTableType';
import './AllWellsTable.scss';
import { ReactComponent as SortIconDefault } from '../../../../../../images/Chevron-default-icon.svg';
import { ReactComponent as SortIconUp } from '../../../../../../images/Chevron-up-icon.svg';
import { ReactComponent as SortIconDown } from '../../../../../../images/Chevron-down-icon.svg';
import { ReactComponent as StarIconEmpty } from '../../../../../../images/star-empty.svg';
import { ReactComponent as StarIconGold } from '../../../../../../images/star-gold.svg';
import { ReactComponent as ArrowUp } from '../../../../../../images/arrow-narrow-up.svg';
import UpTimeChart from '../all-wells-chart/UpTimeChart';
import IntakePressureChart from '../all-wells-chart/IntakePressureChart';
import ProductionChart from '../all-wells-chart/ProductionChart';
import WellWatchlistModal from '../wellWatchlistModal/wellWatchlistModal';
import thinArrow from '../../../../../../images/thinArrow.svg';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/storeHooks';
import { addAssetToWatchList, fetchAssetList, removeAssetFromWatchList } from '../AllWellSlice';
import { toast } from 'react-toastify';
import { AssetData } from '../../../interfaces/AssetList.interface';

interface AllWellsTableProps {
  toggleModal: (modalType: 'watchlist' | 'filters', isOpen: boolean) => void;
}

const AllWellsTable = ({ toggleModal }: AllWellsTableProps) => {
  const assetList = useAppSelector((state) => state.AssetList.AllWellAssetList).results as unknown as AllWellsTableType[];
  const assetListData = useAppSelector((state) => state.AssetList.AllWellAssetList) as unknown as AssetData;
  const assetListLoading = useAppSelector((state) => state.AssetList.loading);
  const [tableData, setTableData] = useState<AllWellsTableType[]>(assetList);
  if(!assetListData.results) return null;
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<number | null>(null);
  const [sortConfig, setSortConfig] = useState<{ key: keyof AllWellsTableType; direction: 'asc' | 'desc' } | null>({
    key: 'healthScore',
    direction: 'asc',
  });
  const [selectedWell, setSelectedWell] = useState('');
  const [selectedIntakeOption, setSelectedIntakeOption] = useState('Intake Pressure');
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const dispatch = useAppDispatch();
  const wellTableRef = React.createRef<HTMLDivElement>();

  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement
        if (
          scrollHeight - scrollTop - clientHeight < 100 &&
          !assetListLoading && assetListData.hasNextPage
        ) {
          dispatch(fetchAssetList({ pageSize: 25, pageNumber: assetListData.nextPageNumber || assetListData.pageNumber + 1 }));
        }
      }
    },
    [assetList?.length, assetListLoading, dispatch]
  )


  const selectOptions = ['Intake Pressure', 'Production'];

  const toggleDropdwon = () => {
    setIsSelectOpen(!isSelectOpen);
  };

  const handleItemClick = (item: string) => {
    setSelectedIntakeOption(item);
    setIsSelectOpen(!isSelectOpen);
  };

  const handleCloseModal = () => {
    setSelectedWell('');
  };

  const createBtnHandler = () => {
    setSelectedWell('');
    toggleModal('filters', true);
  };
  // Toggle star status for a particular row
  const handleStarClick = async (wellName: string, isStarred: boolean) => {

    let response;
    if (isStarred) {
      response = await dispatch(removeAssetFromWatchList(wellName));
    } else {
      response = await dispatch(addAssetToWatchList(wellName));
    }

    if (response.meta.requestStatus === 'fulfilled') {
      toast.success(isStarred ? 'Well removed from watchlist' : 'Well added to watchlist');
      setTableData((prevData) =>
        prevData.map((item) => (item.wellName === wellName ? { ...item, isStarred: !item.isStarred } : item)),
      );
    }


    // setSelectedWell(wellName);
    // setTableData((prevData) =>
    //   prevData.map((item) => (item.wellName === wellName ? { ...item, isStarred: !item.isStarred } : item)),
    // );

  };

  useEffect(() => {
    setTableData(assetList);
  }, [assetList]);


  const columns: Column<AllWellsTableType>[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'isStarred',
        width: 50,
        minWidth: 50,
        maxWidth: 50,
      },
      {
        Header: 'Well Name',
        accessor: 'wellName',
        width: 200,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        Header: 'HealthScore',
        accessor: 'healthScore',
        width: 100,
        minWidth: 100,
        maxWidth: 100,
      },
      {
        Header: 'Operator',
        accessor: 'operator',
        width: 150,
        minWidth: 150,
        maxWidth: 150,
      },
      {
        Header: 'Uptime',
        accessor: 'uptime',
        width: 300,
        minWidth: 300,
        maxWidth: 300,
      },
      {
        Header: () => (
          <div>
            <div className='intake-pressure__dropdown'>
              <div className='intake-pressure__dropdown-input-container' onClick={toggleDropdwon}>
                <input type='text' className='intake-pressure__dropdown-input' value={selectedIntakeOption} readOnly />
                <img
                  src={thinArrow}
                  alt={isSelectOpen ? 'Up Arrow' : 'Down Arrow'}
                  className={`mr-3 ${isSelectOpen ? 'rotate-180' : ''}`}
                />
              </div>
              {isSelectOpen && (
                <ul className='intake-pressure__dropdown-list'>
                  {selectOptions.map((item, index) => (
                    <li
                      key={index}
                      onClick={() => handleItemClick(item)}
                      className='intake-pressure__dropdown-item'
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        ),
        accessor: 'intakePressure',
        width: 300,
        minWidth: 300,
        maxWidth: 300,
      },
      {
        Header: '7 Day Changes',
        accessor: 'sevenDaysChangeTrend',
        width: 100,
        minWidth: 100,
        maxWidth: 100,
      },
      {
        Header: '',
        accessor: 'dropdown',
        width: 50,
        minWidth: 50,
        maxWidth: 50,
      },
    ],
    [tableData, selectedIntakeOption, isSelectOpen],
  );



  const sortedData = useMemo(() => {
    if (assetListLoading) {
      return [];
    }
    if (sortConfig === null) return tableData;
    return tableData && [...tableData]?.sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      if (aValue == null || bValue == null) return 0;
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue) * (sortConfig.direction === 'asc' ? 1 : -1);
      }
      return aValue < bValue
        ? sortConfig.direction === 'asc'
          ? -1
          : 1
        : aValue > bValue
          ? sortConfig.direction === 'asc'
            ? 1
            : -1
          : 0;
    });
  }, [tableData, sortConfig]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: columns,
    data: sortedData,
    
  });

  const handleSort = (column: ColumnInstance<AllWellsTableType>) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === column.id && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key: column.id as keyof AllWellsTableType, direction });
  };

  const openContextMenuOpen = (index: number) => {
    setIsContextMenuOpen((prevIndex: any) => (prevIndex === index ? null : index));
  };

  const getBorderColor = (score: any) => {
    if (score < 50) return '#F97066';
    if (score >= 51 && score <= 75) return '#FFBF3F';
    if (score > 75) return '#12B76A ';
    return '#CCC';
  };

  const handleWellNameClick = (selectedWell: AllWellsTableType) => {
    localStorage.setItem('selectedWellControlRoom', JSON.stringify(selectedWell));
  }

  return (
    <>
      <div className='doc-wrapper'>
        <div className='table-container w-full' 
          ref={wellTableRef}
          onScroll={() => fetchMoreOnBottomReached(wellTableRef.current)}
        >
          <table className='document-table' {...getTableProps()}>
            <thead className='document-head'>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps({
                        style: { width: column.width, minWidth: column.minWidth, maxWidth: column.maxWidth },
                      })}
                      onClick={() => {
                        if (column.id !== 'uptime' && column.id !== 'intakePressure' && column.id !== 'dropdown') {
                          handleSort(column as ColumnInstance<AllWellsTableType>)
                        }
                      }
                      }
                      key={column.id}
                    >
                      <div>
                        {column.render('Header')}
                        {(column.id !== 'uptime' && column.id !== 'intakePressure' && column.id !== 'dropdown') ?
                          sortConfig?.key === column.id ? (
                            sortConfig.direction === 'asc' ? (
                              <SortIconUp alt='sort-asc' className='sort-img' />
                            ) : (
                              <SortIconDown alt='sort-dsc' className='sort-img' />
                            )
                          ) : (
                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                          ) : ''}
                      </div>
                    </th>
                  ))}
                  {/* <th></th> */}
                </tr>
              ))}
            </thead>
            <tbody className='document-body' {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={row.id}>
                    {row.cells.map((cell, cellIndex) => (
                      <td {...cell.getCellProps({
                        style: { width: cell.column.width, minWidth: cell.column.minWidth, maxWidth: cell.column.maxWidth },
                      })} key={cell.column.id}>
                        {cellIndex === 0 ? (
                          <span className='favourite-icon' onClick={() => handleStarClick(row.original.wellName, row.original.isStarred)}>
                            {row.original.isStarred ? <StarIconGold /> : <StarIconEmpty />}
                          </span>
                        ) : cellIndex === 1 ? (

                          <Link onClick={() => handleWellNameClick(row.original)} to={`/layout/well-control-room`} className='well-name-link'>
                            {cell.render('Cell')}
                          </Link>
                        ) : cellIndex === 2 ? (
                          <div className='flex justify-left align-center'>
                            <div
                              className='health-score-circle flex justify-center align-center items-center'
                              style={{
                                border: `2px solid ${getBorderColor(row.original.healthScore)}`,
                              }}>
                              {row.original.healthScore}
                            </div>
                          </div>
                        ) : cellIndex === 3 ? (
                          cell.render('Cell')
                        ) : cellIndex === 4 ? (
                          <UpTimeChart data={row.original.uptimeData || []} />
                        ) : cellIndex === 5 ? (
                          selectedIntakeOption === 'Intake Pressure' ? (
                            <IntakePressureChart data={row.original.intakePressureData || []} />
                          ) : (
                            <ProductionChart data={row.original.productionData || []} />
                          )
                        ) : cellIndex === 6 ? (
                          <div className='all-wells-day-change'>
                            <div className='square-bracket-style'>
                              {' '}
                              <ArrowUp /> {row.original.sevenDaysChangeTrend}%
                            </div>
                          </div>
                        ) : cellIndex === 7 ? (
                          <div className='dropdown-container'>
                            <button className='dropdown-btn' onClick={() => openContextMenuOpen(rowIndex)}>
                              <span className='dot'></span>
                              <span className='dot'></span>
                              <span className='dot'></span>
                            </button>
                            {isContextMenuOpen === rowIndex && (
                              <div className='dropdown-options'>
                                <button>System dashboard snapshot</button>
                                <button>Remove well from watchlist</button>
                              </div>
                            )}
                          </div>
                        ) : (
                          cell.render('Cell')
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {selectedWell && <WellWatchlistModal handleCloseModal={handleCloseModal} createBtnHandler={createBtnHandler} />}
    </>
  );
};

export default AllWellsTable;