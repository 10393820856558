import { apiInstance } from "../../../../../config/HttpCommon";


export const GetAssetList = async (
    pageSize: number,
    pageNumber: number,
    searchQuery?: string,
    watchListId?: string,
) => {
    const response = await apiInstance.get(`/v2/assets`, {
        params: {
            SearchQuery: searchQuery,
            WatchListId: watchListId,
            PageSize: pageSize,
            PageNumber: pageNumber,
        },
    });
    return response.data;
}

export const addAssetToWatchListAPI = async (assetId: string) => {
    const response = await apiInstance.put(`/v1/watchlists/add-asset`, {
        assetId,
    });
    return response.data;
}

export const removeAssetFromWatchListAPI = async (assetId: string) => {
    const response = await apiInstance.put(`/v1/watchlists/remove-asset`, {
        assetId,
    });
    return response.data;
}