import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import annotation_plus from './../../../../images/expand-icon.svg';
import download from '../../../../../src/images/download-icon.svg';
import Loader from '../../../common/page-loader/ComponentLoader';
import NoData from '../../../dashboard/components/no-data/NoData';
import mockData from "../data/intakeDischarge.json";
import { IntakeDetailsProps, IntakeDischargeData } from './interfaces/intake.interfaces';
import IntakeChart from './charts/IntakeChart';
import IntakeModal from './modals/IntakeModal';


const IntakeDischargeDetails: React.FC<IntakeDetailsProps> = ({heading}) => {
    const loading = false;  // Set loading based on your data fetching logic
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const cardChartRef = useRef<HTMLDivElement | null>(null);
    const modalChartRef = useRef<HTMLDivElement | null>(null);
    const [data, setData] = useState<IntakeDischargeData>({ intake: [], discharge: [] });
    const hElement = document.getElementById('main-root');    

    const openModal = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setModalIsOpen(true);
        hElement && hElement?.classList.add('modal-open');
    };

    const handleClose = () => {
        setModalIsOpen(false);
        hElement && hElement?.classList.remove('modal-open');
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
          setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const getLast7DaysDate = () => {
        const today = new Date();
        const last7Days = new Date(today);
        last7Days.setDate(today.getDate() - 6);
        return last7Days;
    };

    const [selectedDateRange,] = useState({
        startDate: getLast7DaysDate(),
        endDate: new Date(),
        key: 'selection',
    });


    const fetchData = async () => {
        const transformedData: IntakeDischargeData = {
            intake: mockData.intakeDischargeData.intake.map((item) => ({
                date: new Date(item.date).getTime(),
                value: item.value,
            })),
            discharge: mockData.intakeDischargeData.discharge.map((item) => ({
                date: new Date(item.date).getTime(),
                value: item.value,
            })),
        };
        setData(transformedData);
    }

    useEffect(() => {
        fetchData();
    }, [selectedDateRange.startDate, selectedDateRange.endDate]);


  return (
    <>
        <ToastContainer position='top-right' autoClose={3000} />
        <div className='grid-item-text h-full graph-card well-graph-card'>
            <div className='table-header-section pie-section'>
                <div className='title'>{heading}</div>
                <div className='header-icon'>
                    <button className='expand-btn cancelSelectorName' onClick={openModal} style={{ cursor: 'pointer', zIndex: 0 }}>
                        <img src={annotation_plus} alt='Expand' className='img-border' />
                    </button>
                </div>
                <div className='mr-4 dropdown-container' ref={dropdownRef}>
                    <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                        <span className='dot'></span>
                        <span className='dot'></span>
                        <span className='dot'></span>
                    </button>
                    {isDropdownOpen && (
                    <div className='dropdown-options'>
                        <button>Actions</button>
                        <button className={`btn btn-default cancelSelectorName`}>
                            <img src={download} alt='icon' className='btn-icon' />
                            Download PDF
                        </button>
                        <button className={`btn btn-default cancelSelectorName`}>
                            <img src={download} alt='icon' className='btn-icon' />
                            Download CSV
                        </button>
                    </div>
                    )}
                </div>
            </div>
            <hr />
            <div id="intakeDischarge-container" className='h-full wellChartSection intakeDischargeChartSection'>
                {loading ? (
                    // <div className='well-production-loader'>
                    <Loader />
                    // </div> 
                ) :  data?.intake?.length > 0 || data?.discharge?.length > 0 ? (
                    <> 
                        <IntakeChart chartID={'intakeChartdiv'} chartRef={cardChartRef} data={data}/>                
                    </>
                ): (
                    <NoData heading='No data found' />
                )}
            </div>  
        </div>
        <IntakeModal
          isOpen={modalIsOpen}
          onRequestClose={handleClose}
          heading={'Intake / Discharge'}
          loading={loading}
          modelWidth={'75rem'}
          modelClass={'wellChartModal'}
          innerComponent={
            <>
                <IntakeChart chartID={'intakeChartdiv'} chartRef={modalChartRef} data={data}/>   
            </>
          }
        />
    </>    
  );
};

export default IntakeDischargeDetails;