import React, { useEffect, useState } from "react";
import "./ToolTip.scss";

const ClickEventToolTip = (props: any) => {
    const [active, setActive] = useState(true);


    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (active) {

                const target = event.target as HTMLElement;
                if (!target.closest('.tooltip-wrapper')) {
                    setActive(!active)
                }
            }
        };

        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [active]);



    return (
        <div
            className="tooltip-wrapper"
            onClick={() => setActive(!active)}
            data-testid="tooltip-wrapper"
        >
            {props.children}
            {active && (
                <div className={`tooltip-tip ${props.direction || "top"}`}>
                    {props.content}
                </div>
            )}
        </div>
    );
};

export default ClickEventToolTip;
