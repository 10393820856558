import { HistoryDetailsProps } from "../HistoryDetails";
import SleLineChart from './SleLineChart'



interface CardsDesignProps {
    trend: HistoryDetailsProps[] | any;
    index: number;
    selectedCalendarDate: {
        startDate: Date,
        endDate: Date
    };
    filteredValues: string[];
    currentAggregationValue: string
}
const CardsDesign: React.FC<CardsDesignProps> = ({ trend, index, selectedCalendarDate, filteredValues, currentAggregationValue }) => {
    const unitOfMeasure = trend?.unitOfMeasure;
    const colorCombination: any = {
        'System frequency': '#FEC84B',
        'Vibration Y': '#FFD072',
        'DC bus voltage': '#006DFA',
        'Drive voltage': '#12B76A',
        'Vibration X': '#3A7E73',
        'Intake pressure': '#004EB3',
        'Motor temperature': '#F97066',
        'Intake temperature': '#F04438',
        'Casing pressure': '#6BBBAE',
        'Tubing pressure': '#0094BD',
        'Motor current': '#FB743C',
        'Downhole current': '#F97066',
        'System RPM': '#B8C5CC',
        'Vibration Y (g)': '#FFD072',
        'DC Bus Voltage (V)': '#006DFA',
        'Drive Output Voltage (V)': '#12B76A',
        'Vibration X (g)': '#3A7E73',
        'Intake Pressure (psi)': '#004EB3',
        'Intake Temperature (F)': '#F04438',
        'Analog Input 2 - Casing Pressure': '#6BBBAE',
        'Analog Input 1 - Tubing Pressure': '#0094BD',
        'Motor Current (A)': '#FB743C',
    };

    return (
        <>
            <div className='card'>
                <div className='card-header'>
                    <div className='title'>{trend[0]?.unitOfMeasure}</div>
                </div>

                <div className='card-body'>
                    <div className='left-container-history'>
                        {trend?.map((item: any) => {
                            return (
                                <div style={{ margin: '8px 0' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div
                                            style={{
                                                width: '12px',
                                                height: '12px',
                                                borderRadius: '50%',
                                                marginRight: '4px',
                                                background: colorCombination[item?.trendName] ? colorCombination[item?.trendName] : 'red',
                                            }}
                                        ></div>
                                        <div>{item?.trendName}</div>
                                    </div>
                                    <div
                                        style={{ marginLeft: '20px' }}
                                    >{`${item?.median ? +Number(item?.median).toFixed(3) : '0'} ${item?.short_UnitOfMeasure ? item?.short_UnitOfMeasure?.toUpperCase() : item?.shortUnitOfMeasure?.toUpperCase()}`}</div>
                                </div>
                            );
                        })}
                    </div>
                    <div className='rigth-container' >
                        <SleLineChart
                            chartName={trend?.[0]?.trendName}
                            unitOfMeasure={unitOfMeasure}
                            chartData={trend}
                            colorCombination={colorCombination}
                            index={index}
                            short_UnitOfMeasure={trend[0]?.short_UnitOfMeasure ? trend[0]?.short_UnitOfMeasure : trend[0]?.shortUnitOfMeasure}
                            selectedCalendarDate={selectedCalendarDate}
                            key={trend?.trendName}
                            filteredValues={filteredValues}
                            currentAggregationValue={currentAggregationValue}

                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardsDesign;