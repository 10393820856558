export const switcherData = [
    {   id:1,
        heading:'Speed',
        description:'Description of this data source and the information it provides, and explanation of how the data can be utilized for assessment and optimization.',
        switcher:false,
    },
    {   id:2,
        heading:'Amps',
        description:'Description of this data source and the information it provides, and explanation of how the data can be utilized for assessment and optimization.',
        switcher:false,
    },
    {   id:3,
        heading:'Temperature',
        description:'Description of this data source and the information it provides, and explanation of how the data can be utilized for assessment and optimization.',
        switcher:false,
    },
    {   id:4,
        heading:'Production',
        description:'Description of this data source and the information it provides, and explanation of how the data can be utilized for assessment and optimization.',
        switcher:false,
    },
    {   id:5,
        heading:'Harmonic distortion',
        description:'Description of this data source and the information it provides, and explanation of how the data can be utilized for assessment and optimization.',
        switcher:false,
    },
    {   id:6,
        heading:'Casing Tubing',
        description:'Description of this data source and the information it provides, and explanation of how the data can be utilized for assessment and optimization.',
        switcher:false,
    },
    {   id:7,
        heading:'Intake Discharge',
        description:'Description of this data source and the information it provides, and explanation of how the data can be utilized for assessment and optimization.',
        switcher:false,
    },
    {   id:8,
        heading:'Back pressure',
        description:'Description of this data source and the information it provides, and explanation of how the data can be utilized for assessment and optimization.',
        switcher:false,
    },
]
