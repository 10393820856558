import React, { memo, useEffect, useRef, useState } from "react";
import "./CustomTimePicker.scss";
import moment from "moment-timezone";

interface customTimeProps {
  currentTime?: string;
  handleTimeChange: (val:string) => void;
}

const CustomTimePicker: React.FC<customTimeProps> = ({currentTime, handleTimeChange}) => {
  const [selectedHour, setSelectedHour] = useState<string>("--");
  const [selectedMinute, setSelectedMinute] = useState<string>("--");
  const [period, setPeriod] = useState<string>("--");

  // Single state for managing the dropdown visibility
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const hours = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, "0"));
  const minutes = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, "0"));
  const periods = ["AM", "PM"];

  const dropdownRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {  
    const time = moment(currentTime, 'HH:mm' );
        setSelectedHour(time.format("hh"));
        setSelectedMinute(time.format("mm"));
        setPeriod(time.format("A"));
      const handleClickOutside = (event: MouseEvent) => {
          if (
              dropdownRef.current &&
              !dropdownRef.current.contains(event.target as Node)
          ) {
              setIsDropdownOpen(false);
          }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
          document.removeEventListener("mousedown", handleClickOutside);
      };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const convertTimeString = (timeString:string) => {
    const time = moment(timeString, ["hh:mm A"]);

    return time;
  }

  const handleTimeChangeData = () => {
    const timeStringData = `${selectedHour}:${selectedMinute}${period}`;
    const actualTime =  convertTimeString(timeStringData);
    handleTimeChange(actualTime.format("HH:mm"));
    console.log("time:",actualTime.format());
  }

  useEffect(() => {
    const getEditStatus = setTimeout(async () => {
      await handleTimeChangeData();
    }, 1000);  
    return () => clearTimeout(getEditStatus);
  },[selectedHour, selectedMinute, period]);

  useEffect(() => {
    const time = moment(currentTime, 'HH:mm' );
        setSelectedHour(time.format("hh"));
        setSelectedMinute(time.format("mm"));
        setPeriod(time.format("A"));
  },[currentTime])

  return (
    <div className="relative w-[60%]" ref={dropdownRef}>
      {/* Display selected time in a single box */}
      <div className="flex w-[100%] items-center space-x-2">
        <div
          className="w-[100%] flex items-center min-h-[47px] py-[10px] px-[14px] border rounded-[8px] text-[16px] font-medium bg-[#001023] border-[#4a5463] text-white cursor-pointer text-left"
          onClick={toggleDropdown}
        >
          {selectedHour}:{selectedMinute} {period}
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 ml-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>

        </div>
      </div>

      {/* Unified Dropdown for hours, minutes, and AM/PM */}
      {isDropdownOpen && (
        <div className="absolute top-[48px] left-0 w-48 border bg-[#001023] text-white border-[#4A5463] shadow-lg z-10 rounded-[8px]">
          {/* Hours, Minutes, Period selection */}
          <div className="flex">
            <div className="w-1/3 p-2 max-h-[230px] overflow-y-auto timescrollbar">
              {hours.map((hour) => (
                <div
                  key={hour}
                  onClick={() => {setSelectedHour(hour);}}
                  className={`p-2 cursor-pointer text-center hover:bg-[#4a5463] hover:text-white ${
                    selectedHour === hour ? "bg-[#4a5463] text-white" : ""
                  }`}
                >
                  {hour}
                </div>
              ))}
            </div>

            {/* Minutes selection */}
            <div className="w-1/3 p-2 max-h-[230px] overflow-y-auto timescrollbar">
              {minutes.map((minute) => (
                <div
                  key={minute}
                  onClick={() => {setSelectedMinute(minute);}}
                  className={`p-2 cursor-pointer text-center hover:bg-[#4a5463] hover:text-white ${
                    selectedMinute === minute ? "bg-[#4a5463] text-white" : ""
                  }`}
                >
                  {minute}
                </div>
              ))}
            </div>

            {/* Period selection (AM/PM) */}
            <div className="w-1/3 p-2">
              {periods.map((p) => (
                <div
                  key={p}
                  onClick={() => {setPeriod(p);}}
                  className={`p-2 cursor-pointer text-center hover:bg-[#4a5463] hover:text-white ${
                    period === p ? "bg-[#4a5463] text-white" : ""
                  }`}
                >
                  {p}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(CustomTimePicker);