// Create axis ranges bands
export const bandsData = [{
    title: "Volatile",
    color: "#F97066",
    lowScore: 0,
    highScore: 250
  }, {
    title: "Foundational",
    color: "#FDB022",
    lowScore: 250,
    highScore: 500
  }, {
    title: "Developing",
    color: "#12B76A",
    lowScore: 500,
    highScore: 750
  }, {
    title: "Maturing",
    color: "#12B76A",
    lowScore: 750,
    highScore: 1000
  }, {
    title: "Sustainable",
    color: "#FDB022",
    lowScore: 1000,
    highScore: 1250
  }, {
    title: "High Performing",
    color: "#F97066",
    lowScore: 1250,
    highScore: 1500
  }];