import React, { useState, useRef } from 'react';
import HistoryChart from './SmoothedXLineChart';
import SleHistoryChart from './SleSmoothedXLineChart'
import { addDays, endOfDay, startOfDay } from 'date-fns';
import './History.scss';
import { ToastContainer } from 'react-toastify';
import SleHistoryChartFilter from '../sle-time-series/SleHistoryChartFilter';
import { useAppSelector } from '../../../../hooks/storeHooks';
import Loader from '../../../common/page-loader/ComponentLoader';
import HistoryChartFilter from './HistoryChartFilter';
import SparklineChart from './sparkline-chart/SparklineChart';
import SleSparklineChart from './sparkline-chart/SleSparklineChart';

const History = () => {
  const [selectedOption, setSelectedOption] = useState('Chart');
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: startOfDay(addDays(new Date(), -6)),
    endDate: endOfDay(new Date()),
    key: 'selection',
  });
  const [filteredVal, setFilteredVal] = useState<string[]>([]);
  const [prevFilteredVal, setPrevFilteredVal] = useState<string[]>([]);

  const toasterRef = useRef<HTMLDivElement>(null);
  const { assetDetailLoading, assetDetail } = useAppSelector((state) => state?.assetDetail);
  const isSleStatus = assetDetail?.assetDetails.isSleAsset


  return (
    <>
      <ToastContainer position='top-right' autoClose={3000} ref={toasterRef} />

      <div className='flex flex-row flex-grow profile-block w-100 py-6 px-8'>
        <div className='card profile-card m-0 asset-history'>
          <div className='header'>
            <span className='title'>Asset history</span>
          </div>
          <div className='divider'></div>
          <>
            {
              assetDetailLoading ?
                <div className='flex items-center justify-center w-100'>
                  <Loader />
                </div>
                :
                <>
                  {
                    isSleStatus ?
                      <SleHistoryChartFilter filteredVal={filteredVal} setFilteredVal={setFilteredVal} selectedOption={selectedOption} setSelectedOption={setSelectedOption} setSelectedDateRange={setSelectedDateRange} selectedDateRange={selectedDateRange} setPrevFilteredVal={setPrevFilteredVal} />
                      :
                      <HistoryChartFilter filteredVal={filteredVal} setFilteredVal={setFilteredVal} selectedOption={selectedOption} setSelectedOption={setSelectedOption} setSelectedDateRange={setSelectedDateRange} selectedDateRange={selectedDateRange} />
                  }
                  <div className='body'>
                    {selectedOption === 'Chart' && (
                      <>
                        {
                          isSleStatus ?
                            <SleHistoryChart
                              filteredValues={prevFilteredVal}
                              selectedOption={selectedOption}
                              selectedDateRange={selectedDateRange}
                              selectedVariables={filteredVal}
                            />
                            :
                            <HistoryChart
                              filteredValues={filteredVal}
                              selectedOption={selectedOption}
                              selectedDateRange={selectedDateRange}
                            />
                        }
                      </>
                    )}
                    {selectedOption === 'SparkLines' && (isSleStatus ? <SleSparklineChart /> : <SparklineChart />)}
                  </div>
                </>


            }
          </>




        </div>
      </div>
    </>
  );
};

export default History;
