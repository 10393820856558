import React from 'react'
import refreshIcon from '../../../../images/refresh-cw-02.svg';
import './ControlRoomHeader.scss'

function ControlRoomHeader() {
    return (
        <div className='control-room-header'>
            <h4 className='control-room-header-title'>CONTROL ROOM</h4>
            <div className="refresh-icon">
                <img src={refreshIcon} alt='refresh-icon' />
            </div>
        </div>
    )
}

export default ControlRoomHeader