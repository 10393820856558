import React from 'react';
import './wellWatchListCard.scss';

interface WatchListCardProps {
    title: string;
    description: string;
    count: number;
    isActive?: boolean;
    id: string;
    onClick: (id: string) => void;
}

function WellWatchListCard({ title, description, count, isActive, id, onClick }: WatchListCardProps) {

    return (
        <div className={`${"well-watch-list-card"} ${isActive ? 'well-watch-list-card-active' : ''}`} onClick={() => onClick(id)}>
            <div>
                <h4 className="well-watch-list-card-title">{title}</h4>
                <p className="well-watch-list-card-description">{description}</p>
            </div>
            <div className="well-watch-list-count">
                <h3>{count}</h3>
            </div>
        </div>
    );
}

export default WellWatchListCard;
