import { format } from 'date-fns';
import { Comment } from '../interfaces/comments.interfaces';

// Initial state with type
export const initialComments: Comment[] = [
    {
      id: 1,
      title: "Comment Subject",
      userName: "Mark Hoppus",
      active: true,
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      timestamp: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      replies: [
        {
          id: 2,
          userName: "Wade Wilson",
          content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
          timestamp: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'")
        }
      ]
    },
    {
      id: 2,
      title: "Comment Subject",
      userName: "Nick Fury",
      active: true,
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      timestamp: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      replies: []
    },
    {
      id: 3,
      title: "Comment Subject",
      userName: "Mark Hoppus",
      active: false,
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      timestamp: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      replies: []
    },
    {
      id: 4,
      title: "Comment Subject",
      userName: "Wade Wilson",
      active: false,
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      timestamp: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      replies: []
    },
    {
      id: 5,
      title: "Comment Subject",
      userName: "Mark Hoppus",
      active: false,
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      timestamp: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      replies: []
    }
  ];