import { http } from '../../config/HttpCommon';


export const getJobSchedule = async (userId: string) => {
  const response = await http.get(`/getJobEventSchedule/${userId}`);
  return response.data;
};

export const getTicketsById = async (userId: string) => {
  const response = await http.get(`/getTicketChart/${userId}`);
  return response.data;
};

export const getAssetListById = async (userId: string, groupName: string, startIndex: number, endIndex: number, searchQuery: string, includeTimeseriesFields: boolean, cancelToken: any) => {
   // Determine if `includeTimeseriesFields` should start with `?` or `&`
   const includeTimeseriesParam = includeTimeseriesFields !== undefined 
   ? `${searchQuery ? '&' : '?'}includeTimeseriesFields=${includeTimeseriesFields}` 
   : "";
  // Conditionally include `includeTimeseriesFields` if it's defined
  const url = `/getDashboardAssetDetails/${userId}/${groupName}/${startIndex}/${endIndex}${searchQuery}${includeTimeseriesParam}`;
  
  const response = await http.get(url, { cancelToken });
  return response.data;
};

export const getPerformanceSnapshot = async (userId: string, groupName: string) => {
  const response = await http.get(`/getPerformanceSnapshot/${userId}/${groupName}`);
  return response.data;
};

// export const getWellPerformanceTrends = async (userId: string, fiilter: string, startDate: string, endDate: string, groupName: string, cancelToken:any) => {
//   const response = await http.get(`/getWellPerformanceTrends/${userId}/${fiilter}/${startDate}/${endDate}/${groupName}`,{cancelToken});
//   return response.data;
// };

export const getWellPerformanceTrends = async (userId: string, startDate: string, endDate: string, groupName: string, cancelToken: any, includeAssetCount?: boolean) => {
  const response = await http.get(`/getMultipleWellPerformanceTrends/${userId}/${startDate}/${endDate}/${groupName}?includeAssetCount=${includeAssetCount}`, { cancelToken });
  return response.data;
};

export const getWellProductionTrends = async (userId: string, startDate: string, endDate: string, groupName: string) => {
  const response = await http.get(`/getWellProductionTrends/${userId}/${startDate}/${endDate}/${groupName}`);
  return response.data;
};

export const getPerformanceSnapshotByName = async (userId: string, name: string, groupName: string) => {
  const response = await http.get(`/getPerformanceSnapshotByName/${userId}/${name}/${groupName}`);
  return response.data;
};

// export const downloadDashboardPDF = async (pdf: { userId: string; widgetName: string, groupName: string }) => {
//   const { userId, widgetName, groupName } = pdf;
//   const url = `/downloadDashboardPDF/${userId}/${widgetName}/${groupName}`;
//   const response = await http.get(url, { responseType: 'blob' });
//   return response.data;
// };

export const downloadDashboardCSV = async (csv: { userId: string; widgetName: string, groupName: string, filterTerm?: string }) => {
  const { userId, widgetName, groupName } = csv;
  const url = `/downloadDashboardCSV/${userId}/${widgetName}/${groupName}${csv.filterTerm ? ("?filter=" + csv.filterTerm) : ''}`;
  const response = await http.get(url, { responseType: 'blob' });
  return response.data;
};

// export const downloadWellTrendsPDF = async (pdf: { userId: string; filter: string, startTime: string, endTime: string, groupName?: string }) => {
//   const { userId, filter, startTime, endTime, groupName } = pdf;
//   const url = `/downloadWellTrends/${userId}/${filter}/${startTime}/${endTime}/${groupName}`;
//   const response = await http.get(url, { responseType: 'blob' });
//   return response.data;
// };

export const getUrls = async (groupId: string) => {
  const response = await http.get(`/UrlsLookup/?groupId=${groupId}`);
  return response.data;
};
