import React, { useState, useEffect } from 'react';
import '../AssetInfo.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import downIcon from '../../../images/chevron-down.svg';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { showToaster } from '../../dashboard/components/asset-location/AssetList';
import { fetchAssetInfoEventTypes, fetchAssetInfoEvents } from '../AssetInfoSlice';
import { EventsProps, EventTypeProps } from '../AssetInfoTypes';
import { ReactComponent as FilterIcon } from '../../../images/filter-funnel.svg';
import { ReactComponent as SortIcon } from '../../../images/sort-descending.svg';
import { ReactComponent as StepperGroup } from '../../../images/stepper_Group.svg';

const Events = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [showSortPopup, setShowSortPopup] = useState(false);
  const [filteredCheckedValues, setFilteredCheckedValues] = useState<string[]>([]);
  const [tempCheckedValues, setTempCheckedValues] = useState<string[]>([]);
  const [filteredVal, setFilteredVal] = useState<string[]>([]);
  const [sortOrder, setSortOrder] = useState('');
  const [eventsData, setEventsData] = useState<EventsProps[] | undefined>([]);
  const dispatch = useAppDispatch();

  const { eventTypes, events } = useAppSelector((state) => state.assetInfo);
  const sortOptions = ['Newset', 'Oldest']
  
  useEffect(() => {
    const selectedWellName = sessionStorage.getItem('selectedWellName');
    const selectedAssetNames = selectedWellName && JSON.parse(selectedWellName || '');
    dispatch(fetchAssetInfoEventTypes({ assetName: selectedAssetNames?.assetName }));
  }, []);

  useEffect(() => {
    if (Array.isArray(eventTypes) && eventTypes.length > 0) {
      const filterSelectedData = eventTypes.map((item: EventTypeProps) => item.name);
      setFilteredVal(filterSelectedData);
      setFilteredCheckedValues(filterSelectedData);
      setTempCheckedValues(filterSelectedData);
    }
  }, [eventTypes]);

// Create a debounced function for search term changes

useEffect(() => {
  const timeoutId = setTimeout(() => {
    const selectedWellName = sessionStorage.getItem('selectedWellName');
    const selectedAssetNames = selectedWellName && JSON.parse(selectedWellName || '');

    dispatch(fetchAssetInfoEvents({
      assetName: selectedAssetNames?.assetName,
      SearchQuery: searchTerm,
      FilterBy: filteredCheckedValues.join(','),
      SortOrder: sortOrder,
      SortBy: 'createdAt',
      PageNumber: 1,
      PageSize: 10
    }));
  }, 500);

  return () => clearTimeout(timeoutId);
}, [searchTerm, filteredCheckedValues, sortOrder, dispatch]);

  useEffect(() => {
    if (events && events.length > 0) {
      setEventsData(events);
    }
  }, [events]);

  const handleFilterOutClick = (e: any) => {
    if (e?.target?.id === 'delete-btn') return;
    if (showFilterPopup) {
      setShowFilterPopup(false);
    }
    if (showSortPopup) {
      setShowSortPopup(false);
    }
  };

  const filterRef = useDetectClickOutside({ onTriggered: handleFilterOutClick });

  const handleClearButton = () => {
    setTempCheckedValues([]);
  };

  const handleApplyButton = () => {
    if (tempCheckedValues.length === 0) {
      showToaster('Please select at least one filter', 'error');
      return;
    }
    setFilteredCheckedValues(tempCheckedValues);
    setShowFilterPopup(false);
  };

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTempCheckedValues((prev) =>
      prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
    );
  };

  const handleSortSelect = (order: string) => {
    setSortOrder(order);
    setShowSortPopup(false);
  };

  return (
    <>
      <div className="search-sort-filter-container">
        <div className='search-container flex flex-row w-100'>
          <div className='w-80'>
            <label className='relative block'>
              <span className='sr-only'>Search by event type</span>
              <span className='absolute inset-y-0 left-0 flex items-center pl-2'>
                <svg className='h-5 w-5 fill-slate-300' viewBox='0 0 20 20'>
                  <path
                    fillRule='evenodd'
                    d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                    clipRule='evenodd'
                  ></path>
                </svg>
              </span>
              <input
                className='custom-text-input'
                placeholder='Search by event type'
                type='text'
                name='search'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </label>
          </div>
        </div>
        <div className="sort-filter-container">
          <div className='filter-container'>
            <div
              id='filter-btn'
              style={{
                border: showFilterPopup ? '1px solid var(--Dark-mode-400, #4A5463)' : '1px solid var(--Dark-mode-400, #4A5463)',
                background: showFilterPopup ? 'var(--Dark-mode-800, #001023)' : 'inherit',
                cursor: 'pointer',
              }}
              onClick={(e) => {
                e.stopPropagation();
                setShowFilterPopup(!showFilterPopup);
                setShowSortPopup(false);
              }}
            >
              <FilterIcon />
              Filter
              <img src={downIcon} className={!showFilterPopup ? '' : 'rotate-180'} alt='upIcon' />
            </div>

            {showFilterPopup && (
              <div className='events-filter-modal-container' ref={filterRef}>
                <div className='events-filter-modal-header'>Filter by</div>
                <div className='events-filter-modal-body'>
                  {filteredVal?.map((trend: any) => (
                    <div key={trend} className='checkbox-main-container'>
                      <label className='checkbox-container'>
                        <input
                          type='checkbox'
                          id='showCheckbox'
                          checked={tempCheckedValues.includes(trend)}
                          onChange={handleCheckbox}
                          value={trend}
                        />
                        <span className='checkbox-checkmark'></span>
                        <span className='inline-block pl-[29px]'>{trend}&nbsp;</span>
                      </label>
                    </div>
                  ))}
                </div>
                <div className='events-filter-modal-footer'>
                  <button className={'footer-btn'} onClick={handleClearButton}>
                    Clear
                  </button>
                  <button className={'footer-btn'} onClick={handleApplyButton}>
                    Apply
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className='sort-container'>
        <div
            id='sort-btn'
            style={{
              border: showSortPopup
                ? '1px solid var(--Dark-mode-400, #4A5463)'
                : '1px solid var(--Dark-mode-400, #4A5463)',
              background: showSortPopup ? 'var(--Dark-mode-800, #001023)' : 'inherit',
              cursor: 'pointer',
            }}
            onClick={(e) => {
              e.stopPropagation();
              setShowSortPopup(!showSortPopup);
              setShowFilterPopup(false);
            }}
          >
            <SortIcon />
            Sort
            <img src={downIcon} className={!showSortPopup ? '' : 'rotate-180'} alt='upIcon' />
          </div>

          {showSortPopup && (
            <div className='events-sort-modal-container' ref={filterRef}>
              <div className='events-sort-modal-header'>Sort by</div>
              <div className='events-sort-modal-body'>
              {sortOptions.map((order) => (
                  <div
                    key={order}
                    className='sort-option-list'
                    onClick={() => handleSortSelect(order)}
                  >
                    {order}
                  </div>
                ))}
                
              </div>
            </div>
          )}
        </div>
        </div>
      </div>

      <div className='stepper'>
        <div className='events-list-section'>
          {eventsData && eventsData.length > 0 ? (
            eventsData.map((event, index) => (
              <div key={event.id} className='step'>
                <div className='step-indicator'>
                  <StepperGroup />
                </div>
                <div className='event-card'>
                  <div className='event-header'>
                    <span className='event-subject'>{event.subject}</span>
                    <span className='event-date'>{new Date(event.createdAt).toLocaleDateString()}</span>
                  </div>
                  <div className='event-type'>
                    <span>{event.type}</span>
                  </div>
                  <div className='event-description'>
                    <p>{event.description}</p>
                  </div>
                </div>
                {index < eventsData.length - 1 && <div className='step-line' />}
              </div>
            ))
          ) : (
            <div className='no-event-found'>No events found</div>
          )}
        </div>
      </div>
    </>
  );
};

export default Events;
