import React, { useLayoutEffect, useRef, useState } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import NoData from '../../../dashboard/components/no-data/NoData';
import Loader from '../../../common/page-loader/ComponentLoader';
import { DataPoints } from '../sle-time-series/SleSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import axios from 'axios';
import { GetAggregation } from '../../../../utilities/CommonFunctions';
import { fetchTrendsById } from './TrendsSlice';
import { TrendsDetailsProps } from './TrendsDetails';

interface ChartDataProps {
  date: string;
  name?: string;
  value: string;
}

interface LineChartProps {
  chartName: string;
  index: number;
  unitOfMeasure: string;
  chartData: ChartDataProps[] | DataPoints[] | null,
  selectedDate: {
    startDate: Date,
    endDate: Date
  }
  loadingIcon: boolean;
  setTrendsMinMaxValue: (value: any) => void;
  currentAggregateValue: string;
  filteredValues: string[];
}

const chartColor: { trendName: string; color?: string; color1?: string }[] = [
  { trendName: "Drive voltage", color: "#12B76A", color1: "#6CE9A6" },
  { trendName: 'Intake temperature', color: "#F04438", color1: "#FDA29B" },
  { trendName: 'Intake pressure', color: "#004EB3", color1: "#4294FF" },
  { trendName: 'Motor current', color: "#FB743C", color1: "#FCA682" },
  { trendName: 'Tubing pressure', color: "#0094BD", color1: "#86CFE3" },
  { trendName: 'Vibration X', color: "#3A7E73", color1: "#52AFA0" },
  { trendName: 'Vibration Y', color: "#FFD072", color1: "#FFE5B2" },
  { trendName: 'System frequency', color: "#FEC84B", color1: "#FEF0C7" },
  { trendName: 'DC bus voltage', color: "#006DFA", color1: "#89BCFF" },
  { trendName: 'Motor temperature', color: "#F97066", color1: "#FECDCA" },
  { trendName: 'Casing pressure', color: "#6BBBAE", color1: "#9CD2C9" },
  { trendName: 'System RPM', color: "#B8C5CC", color1: "#E7ECEE" },

];
interface ChartDataContext {
  date: number;
  value: number;
  time: string;
}


let chartsToSync: am5xy.XYChart[] = [];

const LineChart: React.FC<LineChartProps> = ({ chartName, unitOfMeasure, index, chartData, selectedDate, loadingIcon, setTrendsMinMaxValue, currentAggregateValue, filteredValues }) => {
  const chartIdRef = useRef(`chart-${chartName}-${index}-${Math.random()}`);
  const chartId = chartIdRef.current;
  const selectedAsset = useAppSelector((state) => state.assetGroups);
  const { granularDataLoading } = useAppSelector((state) => state.trends);
  const aggregateValues = useAppSelector((state) => state?.assetDetail?.xspocAggregateValue)
  const currentAggregation = useRef(currentAggregateValue)

  const startDate = new Date(selectedDate.startDate.getFullYear(), selectedDate.startDate.getMonth(), selectedDate.startDate.getDate(), 0, 0, 0, 0);
  const endDate = new Date(selectedDate.endDate.getFullYear(), selectedDate.endDate.getMonth(), selectedDate.endDate.getDate(), 23, 59, 59, 999);
  const timeSeriesCancelTokenSource = useRef<ReturnType<typeof axios.CancelToken.source> | null>(null);
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [chartsData, setChartData] = useState(chartData)
  const prevZoomState = useRef({ start: 0, end: 1 })

  const updateDataRange = (apiData: TrendsDetailsProps[]) => {
    if (!apiData) return;
    am5.array.each(chartsToSync, function (chart) {


      const xAxis = chart.xAxes.getIndex(0) as am5xy.DateAxis<am5xy.AxisRendererX>

      const minPosition = xAxis.get("start") || 0;
      const maxPosition = xAxis.get("end") || 1;

      // Convert positions to actual date range values
      const minDate = xAxis.positionToValue(minPosition);
      const maxDate = xAxis.positionToValue(maxPosition);

      const targetSeries = chart.series.getIndex(0);
      const seriesName: string = targetSeries?.get("name") || ''

      // Filter data within this x-axis range and get the corresponding y-axis data
      const targetData = apiData.find(item => item.trendName === seriesName);

      const visibleDataY = targetData?.dataPoints
        ?.filter(dataItem => {
          const dateValue = dataItem.date ? new Date(dataItem.date + "Z").getTime() : 0;
          return dateValue && dateValue >= minDate && dateValue <= maxDate;
        })
        .map(dataItem => Number(dataItem.value))
        .filter((value): value is number => !isNaN(value)) || []; // Ensure only numbers

      // Calculate min and max only if visibleDataY has numeric values
      const minY = visibleDataY.length > 0 ? Math.min(...visibleDataY) : 0;
      const maxY = visibleDataY.length > 0 ? Math.max(...visibleDataY) : 0;
      const meanY = visibleDataY.length > 0 ? visibleDataY.reduce((sum, value) => sum + value, 0) / visibleDataY.length : 0;

      setTrendsMinMaxValue((prev: any) => ({
        ...prev,
        [seriesName]: {
          minValue: +minY.toFixed(1),
          maxValue: +maxY.toFixed(1),
          mean: +meanY.toFixed(1)

        },
      }));

      const uom = apiData?.find(item => item.trendName === seriesName)?.short_UnitOfMeasure
      const yAxis = chart.yAxes.getIndex(0) as am5xy.ValueAxis<am5xy.AxisRendererY>
      const axisRanges = yAxis?.axisRanges;

      axisRanges.each((range, index) => {
        const newValue = minY; // Example update
        const newEndValue = maxY; // Example update
        // Set the new values
        range.set("value", newValue);
        range.set("endValue", newEndValue);

        // Update the label text
        const labelText = index === 0 ? `High : ${+newEndValue?.toFixed(3)} ${uom ?? ''}` : `Low : ${+newValue?.toFixed(3)} ${uom ?? ''}`; // Customize the label text as needed
        range?.get("label")?.set("text", labelText);

      });


      const highLabel = axisRanges.getIndex(0)?.get("label");
      const lowLabel = axisRanges.getIndex(1)?.get("label");


      if (highLabel && lowLabel) {
        // Get the width of both labels
        const highLabelWidth = highLabel.width();
        const lowLabelWidth = lowLabel.width();
        // Calculate the maximum width between both labels
        const maxWidth = Math.max(highLabelWidth, lowLabelWidth);

        // Set the maximum width to both labels
        if (maxWidth !== 0) {
          highLabel.set("width", maxWidth);
          lowLabel.set("width", maxWidth);
        }
      }
    });
  }
  const handleStartEndChange = (targetChart: am5xy.XYChart) => {
    setTimeout(async () => {

      const xAxis = targetChart.xAxes.getIndex(0) as am5xy.DateAxis<am5xy.AxisRendererX>
      if (xAxis) {
        const startTimestamp = xAxis.getPrivate("selectionMin") ?? 0;
        const endTimestamp = xAxis.getPrivate("selectionMax") ?? 0;
        const start = xAxis.get("start") || 0;
        const end = xAxis.get("end") || 1;
        if (start >= 0 || end <= 1) {
          const zoomDirection = (prevZoomState.current.start < start || prevZoomState.current.end > end) ? 'in' : 'out'
          prevZoomState.current = { start: start, end: end }
          const apiData = await getData(new Date(startTimestamp), new Date(endTimestamp), zoomDirection)
          if (apiData) {
            updateDataRange(apiData)

            am5.array.each(chartsToSync, function (chart) {
              const series = chart.series.getIndex(0);
              const seriesName = series?.get('name')
              const matchedData = apiData?.find(item => item.trendName === seriesName)
              const dataPoints = matchedData?.dataPoints || []
              const sortedData = [...dataPoints]?.sort((a, b) => new Date(a?.date).getTime() - new Date(b?.date).getTime());

              const data = sortedData?.map((chart) => {
                const data = { date: new Date(chart?.date.includes('z') || chart?.date.includes('Z') ? chart?.date : chart?.date + 'Z').getTime(), value: Number(chart.value), time: chart.date?.split("T")[1] }
                return data;
              });
              series?.data.setAll(data)
            });
          } else {
            setChartData([])
          }
        }
      }
    }, 50);

  };
  function debounce(func: any, wait: number) {
    let timeout: any;
    return function (...args: any[]) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  const debouncedHandleStartEndChange = debounce((targetChart: am5xy.XYChart) => handleStartEndChange(targetChart), 500);

  function syncAxes(targetChart: am5xy.XYChart) {


    const targetAxis = targetChart.xAxes.getIndex(0);

    if (targetAxis && targetAxis._skipSync !== true) {
      const start = targetAxis.get("start");
      const end = targetAxis.get("end");

      am5.array.each(chartsToSync, function (chart) {
        if (chart !== targetChart) {

          const axis = chart.xAxes.getIndex(0);
          if (axis) {
            axis._skipSync = true;
            axis.setAll({
              start: start,
              end: end
            });
            axis._skipSync = false;
          }
        }
        if (chart === targetChart) {
          debouncedHandleStartEndChange(targetChart)
        }
      });
    }
  }

  const fixedDecimal = (value: number) => {
    return +value.toFixed(3)
  }


  const getData = async (start: any, end: any, zoomDirection = 'in') => {
    if (timeSeriesCancelTokenSource.current) {
      timeSeriesCancelTokenSource.current.cancel('canceled');
    }
    const { store } = await import('../../../../store');
    const trendsDataSet = store.getState().trends.trendsDataSet;
    timeSeriesCancelTokenSource.current = axios.CancelToken.source();

    const wellName = selectedAsset?.selectedAssetName ?? '';
    const startDate = new Date(start).toISOString();
    const endDate = new Date(end).toISOString();
    const aggregateValue = GetAggregation(new Date(start), new Date(end), aggregateValues)

    currentAggregation.current = aggregateValue

    const matchedRecord = trendsDataSet?.find((item) => item.aggregate === aggregateValue)
    let matchedData = matchedRecord?.data

    if (!matchedData) {
      setLoading(true)
      await dispatch(fetchTrendsById({ wellName: wellName, startDate: startDate, endDate: endDate, aggregate: aggregateValue, granularData: true, cancelToken: timeSeriesCancelTokenSource?.current?.token }))
        .unwrap()
        .then((response: TrendsDetailsProps[]) => {
          matchedData = response
          setLoading(false);
        })
        .catch((error) => {
          console.error('Failed to fetch Trends tab details:', error);
          matchedData = []
          setLoading(false);
        });
    } else {
      if (zoomDirection === 'out' || zoomDirection === 'in') {
        const storedDataStartDate = matchedRecord?.startDate ? new Date(matchedRecord?.startDate) : new Date(0)
        const storedDataEndDate = matchedRecord?.endDate ? new Date(matchedRecord?.endDate) : new Date(0)
        const startDateDifference = Math.abs(storedDataStartDate.getTime() - new Date(startDate).getTime());
        const endDateDifference = Math.abs(storedDataEndDate.getTime() - new Date(endDate).getTime());

        if ((storedDataStartDate > new Date(startDate) && startDateDifference > 1 * 1000) || (storedDataEndDate < new Date(endDate) && endDateDifference > 1 * 1000)) {
          setLoading(true)
          await dispatch(fetchTrendsById({ wellName: wellName, startDate: startDate, endDate: endDate, aggregate: aggregateValue, granularData: true, cancelToken: timeSeriesCancelTokenSource?.current?.token }))
            .unwrap()
            .then((response: TrendsDetailsProps[]) => {
              matchedData = response
              setLoading(false);
            })
            .catch((error) => {
              console.error('Failed to fetch Trends tab details:', error);
              matchedData = []
              setLoading(false);
            });

        }
      }
    }
    setLoading(false);



    return matchedData
  }


  useLayoutEffect(() => {
    if (!chartsData) return;

    const root = am5.Root.new(chartId);

    root.setThemes([
      am5themes_Dark.new(root)
    ]);
    const minValue = Math.min(...chartsData.map(item => typeof item.value === 'number' ? item.value : parseFloat(item.value)));
    const maxValue = Math.max(...chartsData.map(item => typeof item.value === 'number' ? item.value : parseFloat(item.value)));

    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: true,
      panY: true,
      wheelX: 'none',
      wheelY: 'zoomX',
      pinchZoomX: true,
      paddingTop: 30,
    }));

    chartsToSync.push(chart);
    const xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      maxDeviation: 0.1,
      groupData: false,
      extraMax: 0,
      extraMin: 0,
      strictMinMax: true,
      baseInterval: { timeUnit: 'second', count: 1 },
      renderer: am5xy.AxisRendererX.new(root, {
      })
    }));

    if (selectedDate && selectedDate?.startDate)
      xAxis.set('min', new Date(startDate).getTime())
    if (selectedDate && selectedDate?.endDate)
      xAxis.set('max', new Date(endDate).getTime())

    xAxis.get('renderer').labels.template.set('paddingTop', 15);

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      maxDeviation: 0.1,
      // min: 0,
      // max: maxValue,
      // extraMin: 0.1,
      // extraMax: 0.1,
      extraMin: 0,
      extraMax: 0,
      renderer: am5xy.AxisRendererY.new(root, {
      })
    }));


    const tooltip = am5.Tooltip.new(root, {
      getFillFromSprite: false,
      autoTextColor: false,
      dy: -10,
      dx: 0
    });


    tooltip?.get("background")?.setAll({
      fill: am5.color("#001023"),
      stroke: am5.color("#4A5463"),
    });
    tooltip.label.setAll({
      fill: am5.color("#F7F9F9"),
      fontFamily: "Mulish",
      fontSize: 14,
      fontWeight: "400",
      textAlign: "center",
      textBaseline: "middle",
    });


    tooltip.label.adapters.add("html", (html, target) => {
      const dataItem = target.dataItem as am5.DataItem<am5xy.IXYSeriesDataItem>;
      if (dataItem) {
        const dataContext = dataItem.dataContext as ChartDataContext;
        const date: any = new Date(dataContext?.date)
        const time = `${date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', second: '2-digit' })}`
        // const dataContext = dataItem.dataContext as ChartDataContext;
        // const time = dataContext?.time;
        return `
        <div>
        <strong>${chartName}</strong> ${fixedDecimal(Number(dataItem?.get("valueY")))} ${unitOfMeasure ?? ""} ${time}
        </div>`;
      }
      return html;
    });


    const bulletColor = chartColor.find(item => item.trendName === chartName)
    const series = chart.series.push(am5xy.LineSeries.new(root, {
      name: chartName,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: 'value',
      valueXField: 'date',
      tooltip: am5.Tooltip.new(root, {
        pointerOrientation: "horizontal"
      }),
      snapTooltip: true,
      stroke: am5.color(bulletColor?.color ?? '#F8AA62'),
    }));

    series.strokes.template.setAll({
      strokeWidth: 2
    })

    series.set("tooltip", tooltip)

    series.bullets.push(() => {
      const circle = am5.Circle.new(root, {
        radius: 0,
        fill: am5.color(`${bulletColor?.color ?? '#0000'}`),
        stroke: am5.color(`${bulletColor?.color1 ?? '#fff'}`),
        strokeWidth: 5,
        visible: false,
      });

      circle.states.create('hover', {
        radius: 7,
        visible: true,
      });


      return am5.Bullet.new(root, {
        locationX: 0.5,
        sprite: circle,
      });
    });

    const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {
      snapToSeries: [series], // Pass series as an array
      // behavior: 'none'
      behavior: 'zoomXY' // Ensure cursor snaps to the series and allows zooming
    }));
    cursor.lineY.set('visible', false);
    cursor.lineX.set('stroke', am5.color('#60BFDA'));
    cursor.lineX.set('strokeWidth', 3); // Adjust the width as needed
    cursor.lineX.set('height', 5); // Adjust the height as needed


    cursor.events.on('cursormoved', cursorMoved);
    cursor.events.on('cursorhidden', hideBullets);

    let range: any | null = null;
    let range1: any | null = null;

    function createRange(value: number, endValue: number, color: am5.Color) {

      const rangeDataItem = yAxis.makeDataItem({
        value: value,
        endValue: endValue
      });

      range = yAxis.createAxisRange(rangeDataItem);

      const rangeDataItem1 = yAxis.makeDataItem({
        value: value,
        endValue: endValue
      });

      range1 = yAxis.createAxisRange(rangeDataItem1);

      if (endValue) {
        range?.get("axisFill")?.setAll({
          fill: color,
          fillOpacity: 0,
          visible: true
        });
        range?.get("label")?.setAll({
          fill: am5.color("#191F23"),
          text: `High : ${+maxValue?.toFixed(3)} ${unitOfMeasure ?? ''}`,
          inside: true,
          height: 23,
          oversizedBehavior: "wrap",
          textAlign: "center",
          centerX: 0,
          visible: false,
          dx: 5,
          dy: -12,
          location: 1,
          background: am5.RoundedRectangle.new(root, {
            fill: am5.color("#60BFDA"),
            cornerRadiusTL: 4,
            cornerRadiusTR: 4,
            cornerRadiusBL: 4,
            cornerRadiusBR: 4,
          })
        });

        range1?.get("axisFill")?.setAll({
          fill: color,
          fillOpacity: 0,
          visible: true
        });
        range1?.get("label")?.setAll({
          fill: am5.color("#191F23"),
          text: ` Low : ${+minValue?.toFixed(3)} ${unitOfMeasure ?? ''} `,
          height: 23,
          oversizedBehavior: "wrap",
          textAlign: "center",
          inside: true,
          centerX: 0,
          visible: false,
          dx: 5,
          dy: 12,
          location: 0,
          background: am5.RoundedRectangle.new(root, {
            fill: am5.color("#60BFDA"),
            cornerRadiusTL: 4,
            cornerRadiusTR: 4,
            cornerRadiusBL: 4,
            cornerRadiusBR: 4,
          })
        });

      }
      else {
        range?.get("label")?.setAll({
          fill: color,
          background: am5.RoundedRectangle.new(root, {
            fill: color
          })
        });
        range1?.get("label")?.setAll({
          fill: color,
          background: am5.RoundedRectangle.new(root, {
            fill: color
          })
        });
      }
      range?.get("grid")?.setAll({
        stroke: am5.color("#384252"),
        strokeOpacity: 0,
        location: 1,
      });
      range1?.get("grid")?.setAll({
        stroke: am5.color("#384252"),
        strokeOpacity: 0,
        location: 0,
      });
    }

    if (minValue !== Infinity && maxValue !== Infinity) {
      createRange(minValue, maxValue, am5.color("#253040"));
    }

    const handleZoom = () => {
      syncAxes(chart)
    }


    let previousBulletSprites: any = [];
    function cursorMoved() {
      for (let i = 0; i < previousBulletSprites?.length; i++) {
        previousBulletSprites[i].unhover();
        previousBulletSprites[i].set('visible', false); // Hide bullets when not hovered
      }
      previousBulletSprites = [];
      chart.series.each(function (series) {
        const dataItem = series?.get('tooltip')?.dataItem;
        if (dataItem) {
          const bulletSprite = dataItem?.bullets?.length && dataItem?.bullets[0]?.get('sprite');
          if (bulletSprite) {
            bulletSprite.hover();
            bulletSprite.set('visible', true); // Make bullet visible on hover
            previousBulletSprites.push(bulletSprite);
          }
        }
      });

      yAxis?.axisRanges?.each((range) => {
        range.get("label")?.set("visible", true)
        range.get("grid")?.set("strokeOpacity", 1)
        range.get("axisFill")?.set("fillOpacity", 0.5)
      })

      const cursorPosition = chart?.get("cursor")?.getPrivate("point");
      const tooltip = series.get("tooltip");
      if (cursorPosition && tooltip) {
        tooltip.setAll({
          dy: -(cursorPosition.y - 10),
        });
      }

    }

    function hideBullets() {
      for (let i = 0; i < previousBulletSprites.length; i++) {
        previousBulletSprites[i].unhover();
        previousBulletSprites[i].set('visible', false); // Hide bullets
      }
      previousBulletSprites = [];

      yAxis?.axisRanges?.each((range) => {
        range.get("label")?.set("visible", false)
        range.get("grid")?.set("strokeOpacity", 0)
        range.get("axisFill")?.set("fillOpacity", 0)
      })
    }

    const sortedData = [...chartsData]?.sort((a, b) => new Date(a?.date).getTime() - new Date(b?.date).getTime());

    const data = sortedData?.map((chart) => {
      const data = { date: new Date(chart?.date.includes('Z') || chart?.date.includes('Z') ? chart?.date : chart?.date + 'Z').getTime(), value: Number(chart.value), time: chart.date?.split("T")[1] }
      return data;
    });

    series.data.setAll(data);
    xAxis.on("start", handleZoom);
    xAxis.on("end", handleZoom);

    root.events.on("frameended", function () {
      const highLabel = range?.get("label");
      const lowLabel = range1?.get("label");


      if (highLabel && lowLabel) {
        // Get the width of both labels
        const highLabelWidth = highLabel.width();
        const lowLabelWidth = lowLabel.width();
        // Calculate the maximum width between both labels
        const maxWidth = Math.max(highLabelWidth, lowLabelWidth);

        // Set the maximum width to both labels
        if (maxWidth !== 0) {
          highLabel.set("width", maxWidth);
          lowLabel.set("width", maxWidth);
        }
      }
    });

    return () => {
      root.dispose();
      chartsToSync = chartsToSync.filter(c => c !== chart); // Remove disposed chart from sync list
    };

  }, [chartsData, chartName, unitOfMeasure, index, filteredValues]);


  return (
    <div id={chartId} style={{
      width: "100%",
      height: "200px",
      opacity: loadingIcon || loading || granularDataLoading ? 0.5 : 1,
      pointerEvents: loadingIcon || loading || granularDataLoading ? "none" : "auto",
    }} className={chartsData ? '' : 'center-content'}>
      {loadingIcon || loading || granularDataLoading ? (
        <div className='trend-loader flex items-center justify-center w-100'>
          <Loader />
        </div>
      ) : (!chartsData && <NoData heading='No data found' />)}

    </div>
  );
};

export default LineChart;

