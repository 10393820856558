import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Column, useTable } from 'react-table';
import { HealthScoreHistory } from '../model/HealthScoreHistory';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import DateRangeSelector from '../../common/DateRangeSelector/DateRangeSelector';
import { defaultStaticRanges, defineds, formateDate } from '../../common/DateRangeSelector/DefaultRanges';
import calenderIcon from '../../../images/calendar-icon.svg';
import UpArrow from '../../../images/arrow-up-1.svg'
import DownArrow from '../../../images/arrow-down-1.svg'
import { ReactComponent as SortIconDefault } from '../../../images/Chevron-default-icon.svg';
import { ReactComponent as SortIconUp } from '../../../images/Chevron-up-icon.svg';
import { ReactComponent as SortIconDown } from '../../../images/Chevron-down-icon.svg';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { fetchHealthScoreHistory } from '../HealthScoreSlice';
import NoData from '../../dashboard/components/no-data/NoData';
import Loader from '../../common/page-loader/ComponentLoader';

// Sample data
// const data = [
//     {
//         date: '01/05/2024',
//         events: 'Param change',
//         healthScore: '88',
//         healthScoreImpact: 0,
//         healthScoreImpactStatus: 'none'
//     },
//     {
//         date: '01/15/2024',
//         events: 'RTU alarm',
//         healthScore: '55',
//         healthScoreImpact: 5,
//         healthScoreImpactStatus: 'down'
//     },
//     {
//         date: '01/17/2024',
//         events: 'Status change',
//         healthScore: '88',
//         healthScoreImpact: 7,
//         healthScoreImpactStatus: 'up'
//     },
//     {
//         date: '01/17/2024',
//         events: 'Param change',
//         healthScore: '88',
//         healthScoreImpact: 0,
//         healthScoreImpactStatus: 'none'
//     },
//     {
//         date: '01/24/2024',
//         events: 'Param change',
//         healthScore: '77',
//         healthScoreImpact: 12,
//         healthScoreImpactStatus: 'up'
//     },
//     {
//         date: '01/24/2024',
//         events: 'Param change',
//         healthScore: '78',
//         healthScoreImpact: 14,
//         healthScoreImpactStatus: 'up'
//     },
//     {
//         date: '01/05/2024',
//         events: 'Param change',
//         healthScore: '64',
//         healthScoreImpact: 2,
//         healthScoreImpactStatus: 'down'
//     },
// ];

// Define the columns
const columns: Column<HealthScoreHistory>[] = [
    {
        Header: 'Date',
        accessor: 'date', // key in the data
    },
    {
        Header: 'Event',
        accessor: 'events',
    },
    {
        Header: 'Health Score',
        accessor: 'healthScore',
    },
    {
        Header: 'Health Score Impact',
        accessor: 'healthScoreImpact',
    },
];

type SortableKeys = keyof HealthScoreHistory;

const HealthScoreTable = () => {
    const dispatch = useAppDispatch()
    const healthScoreHistoryLoading = useAppSelector((state) => state.healthScore.healthScoreHistoryLoading)
    const healthScoreHistoryData = useAppSelector((state) => state.healthScore.healthScoreHistory);
    const healthScoreHistoryTotalCount = useAppSelector((state) => state.healthScore.totalHealthScoreHistoryCount);
    const [pageNumber, setPageNumber] = useState(0)
    const [pageSize] = useState(15)
    const [loading, setLoading] = useState(false)
    const [sortConfig, setSortConfig] = useState<{ key: SortableKeys; direction: 'asc' | 'desc' } | null>({
        key: 'date',
        direction: 'asc',
    });
    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: startOfDay(addDays(new Date(), -6)),
        endDate: endOfDay(new Date()),
        key: 'selection',
    });


    useEffect(() => {
        dispatch(fetchHealthScoreHistory({ StartDate: formateDate(selectedDateRange.startDate), EndDate: formateDate(selectedDateRange.endDate), PageNumber: pageNumber, PageSize: pageSize }))
    }, [])

    const handleSlideOutClick = (e: any) => {
        if (e.target.id === 'range-calendar-input') return;
        if (showCalendar) {
            setShowCalendar(!showCalendar);
        }
    };
    const ref = useDetectClickOutside({ onTriggered: handleSlideOutClick });

    const requestSort = (key: SortableKeys) => {
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig?.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const getValueByKey = (item: HealthScoreHistory, key: keyof HealthScoreHistory): string => {
        const value = item[key];
        if (value === null || value === undefined) {
            return ''; // Return empty string if value is null or undefined
        }
        if (Array.isArray(value)) {
            return value.join(', '); // Convert arrays to a string for comparison
        }
        return value.toString(); // Convert non-null values to string to ensure safe operations
    };

    const observer = useRef<IntersectionObserver>();
    const lastHealthScoreElementRef = useCallback(
        (node: HTMLTableRowElement | null) => {
            if (healthScoreHistoryLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && healthScoreHistoryTotalCount > healthScoreHistoryData?.length) {
                    setLoading(true);
                    //   setTimeout(() => {
                    //     setDisplayCount((prevDisplayCount) => prevDisplayCount + 15);
                    //     setLoading(false);
                    //   });
                    const updatedPageNumber = pageNumber + 1
                    setPageNumber(updatedPageNumber)
                    dispatch(fetchHealthScoreHistory({ StartDate: formateDate(selectedDateRange.startDate), EndDate: formateDate(selectedDateRange.endDate), PageNumber: updatedPageNumber, PageSize: pageSize }))
                        .then(() => {
                            setLoading(false);
                        })
                }
            });
            if (node instanceof HTMLTableRowElement) observer.current.observe(node);
        },
        [healthScoreHistoryLoading, healthScoreHistoryData?.length],
    );

    const sortedHealthScoreHistoryData = useMemo(() => {
        const sortableItems = [...healthScoreHistoryData];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                const aValue = getValueByKey(a as unknown as HealthScoreHistory, sortConfig.key);
                const bValue = getValueByKey(b as unknown as HealthScoreHistory, sortConfig.key);
                return aValue.toLowerCase().localeCompare(bValue.toLowerCase()) * (sortConfig.direction === 'asc' ? 1 : -1);
            });
        }
        return sortableItems;
    }, [healthScoreHistoryData, sortConfig]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns: columns, data: sortedHealthScoreHistoryData });

    const getBorderColor = (value: number) => {
        return value > 80 ? '2px solid var(--Success-Green500, #12B76A)' : (value <= 80 && value > 70) ? '2px solid var(--Yellow-500, #FFBF3F)' : '2px solid var(--Error-Red400, #F97066)'
    }

    return (
        <>
            {(healthScoreHistoryLoading || loading) ?
                < div className={`flex items-center justify-content`}>
                    <Loader />
                </ div>
                :

                healthScoreHistoryData?.length ?
                    <div className='health-score-table card m-0'>
                        <div className='heading flex justify-between'>
                            <div className='header1'>
                                History
                            </div>
                            <div className='history-container-date-range health-score-date-range'>
                                <div className='input-group'>
                                    <img src={calenderIcon} alt='calendar-icon' className='absolute top-3 left-3' />
                                    <input
                                        id='range-calendar-input'
                                        className='date-range-input'
                                        value={`${formateDate(selectedDateRange.startDate)} - ${formateDate(selectedDateRange.endDate)}`}
                                        onClick={() => setShowCalendar(!showCalendar)}
                                    />
                                </div>
                                <div ref={ref}>
                                    {showCalendar && (
                                        <DateRangeSelector
                                            setShowCalendar={setShowCalendar}
                                            setSelectedDateRange={setSelectedDateRange}
                                            staticRanges={defaultStaticRanges}
                                            minDate={defineds?.startOfLastYear}
                                            maxDate={defineds?.endOfToday}
                                            selectedDateRange={selectedDateRange}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='table-container w-full'>
                            <table className='document-table' {...getTableProps()}>
                                <thead className='document-head'>
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id} >
                                            {headerGroup.headers.map((column) => {
                                                return (
                                                    <th
                                                        {...column.getHeaderProps()}
                                                        key={column.id}
                                                        onClick={() => requestSort(column.id as SortableKeys)}
                                                    >
                                                        <div className='flex'>
                                                            {column.render('Header')}<span className='sort-icon'>
                                                                {sortConfig?.key === column.id ? (
                                                                    sortConfig.direction === 'asc' ? (
                                                                        <SortIconUp alt='sort-asc' className='sort-img' />
                                                                    ) : (
                                                                        <SortIconDown alt='sort-dsc' className='sort-img' />
                                                                    )
                                                                ) : (
                                                                    <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                                                )}
                                                            </span>
                                                        </div>
                                                    </th>
                                                )
                                            })}
                                            {/* <th></th> */}
                                        </tr>
                                    ))}
                                </thead>

                                <tbody className='document-body' {...getTableBodyProps()}>
                                    <>
                                        {rows.map((row, rowIndex) => {
                                            prepareRow(row);
                                            return (
                                                <tr
                                                    {...row.getRowProps()}
                                                    key={row.id}
                                                    ref={rows.length === rowIndex + 1 ? lastHealthScoreElementRef : null}
                                                >

                                                    {row.cells.map((cell, cellIndex) => (

                                                        <td {...cell.getCellProps()} key={cell.column.id}>

                                                            {
                                                                cellIndex === 0 ?
                                                                    (<div>{formateDate(new Date(row.original.date))}</div>)

                                                                    :
                                                                    cellIndex === 1 ?

                                                                        (<div className='text-[#60BFDA]'>{row.original.events}</div>)
                                                                        :
                                                                        cellIndex === 2 ?
                                                                            (
                                                                                <div className='flex justify-center'>
                                                                                    <div className="health-score-round-div" style={{ border: `${getBorderColor(Number(row.original.healthScore))}` }}>
                                                                                        {row.original.healthScore}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                            :
                                                                            cellIndex === 3 ?
                                                                                (
                                                                                    <div className='flex justify-start'>
                                                                                        <div className={`health-score-impact-status flex space-x-2 ${row.original.healthScoreImpactStatus.toLocaleLowerCase()}`} >
                                                                                            {row.original.healthScoreImpactStatus.toLowerCase() === 'up' ? <img src={UpArrow} /> : row.original.healthScoreImpactStatus.toLowerCase() === 'down' ? <img src={DownArrow} /> : ''}
                                                                                            <span>{row.original.healthScoreImpact} points</span>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                                : cell.render('Cell')

                                                            }
                                                        </td>
                                                    ))}
                                                </tr>
                                            );
                                        })}

                                    </>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    :
                    <div >
                        <NoData heading='No data found' />
                    </div>
            }
        </>

    )
}

export default HealthScoreTable