import React from 'react'

const RockingStart = () => {
    return (
        <div className='rocking-start-container'>
            <div className='flex flex-col space-y-4'>
                <div className='flex flex-row space-x-4'>
                    <div className='manage-asset-form-control w-1/2'>
                        <label className='label'>Rocking frequency (Hz)</label>
                        <input
                            type='text'
                            className='time-input-field'
                            placeholder='10.0'
                        />
                    </div>
                    <div className='manage-asset-form-control w-1/2'>
                        <label className='label'>Direction time [5]</label>
                        <input
                            type='text'
                            className='time-input-field'
                            placeholder='05'
                        />
                    </div>
                </div>
                <div className='flex flex-row space-x-4'>
                    <div className='manage-asset-form-control w-1/2'>
                        <label className='label'>Cycles allowed</label>
                        <input
                            type='text'
                            className='time-input-field'
                            placeholder='5'
                        />
                    </div>
                    <div className='manage-asset-form-control w-1/2'>
                        <label className='label'>Cycles remaining</label>
                        <input
                            type='text'
                            className='time-input-field'
                            placeholder='5'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RockingStart