import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAssetTimeseriesChannelsData, getHistoryById, getHistoryChartData, getHistoryChartFilterData, getMasterVariablesData } from './HistoryService';
import { HistoryChartProps, HistoryDetailsProps, HistoryFilterProps, SparkLineFilterProps } from './HistoryDetails';


interface HistoryState {
  loading: boolean;
  message: string | null;
  histories: HistoryDetailsProps[] | null;
  historychart: HistoryChartProps[] | null;
  filterData: HistoryFilterProps[] | null;
  sparkLineFilterData: null | any;
  historyChartDataLoaing: boolean;
  sparkLineChartLoading: boolean;
  sparkLineFilterLoading: boolean;
  timeSeriesChannelDataLoading: boolean;
  historyChartDataSet: {
    data: HistoryChartProps[],
    aggregate: string,
    startDate: string,
    endDate: string
  }[];
  historySparklineDataSet: {
    data: HistoryDetailsProps[],
    aggregate: string,
    startDate: string,
    endDate: string
  }[]
  granularDataLoading: boolean;
  initialDataLoading: boolean;

}

const initialState: HistoryState = {
  loading: false,
  message: null,
  histories: null,
  historychart: null,
  filterData: null,
  sparkLineFilterData: null,
  historyChartDataLoaing: false,
  sparkLineChartLoading: false,
  sparkLineFilterLoading: false,
  timeSeriesChannelDataLoading: false,
  historyChartDataSet: [],
  granularDataLoading: false,
  initialDataLoading: false,
  historySparklineDataSet: []
};

interface fetchHistoryChartDataByIdArgs {
  wellName: string;
  startDate: string;
  endDate: string;
  aggregate: string;
  cancelToken: any;
  granularData: boolean;
}


interface fetchHistorySparklineDataByIdArgs {
  wellName: string;
  startDate: string;
  endDate: string;
  channelIds: string;
  aggregate: string;
  cancelToken: any;
  granularData?: boolean;
}

export const fetchHistoryById = createAsyncThunk(
  'events/fetchHistoryById',
  async ({ wellName, startDate, endDate, paramIds }: { wellName: string; startDate: string; endDate: string; paramIds: string; }) => {
    const response = await getHistoryById(wellName, startDate, endDate, paramIds);
    return response;
  },
);

export const fetchAssetTimeseriesChannelsData = createAsyncThunk(
  'events/GetAssetTimeseriesChannelsData',
  async ({ wellName, startDate, endDate, channelIds, aggregate, cancelToken }: fetchHistorySparklineDataByIdArgs) => {
    const response = await getAssetTimeseriesChannelsData(wellName, startDate, endDate, channelIds, aggregate, cancelToken);
    return response;
  },
);

export const fetchHistoryChartDataById = createAsyncThunk(
  'events/fetchHistoryChartDataById',
  async ({ wellName, startDate, endDate, aggregate, cancelToken }: fetchHistoryChartDataByIdArgs) => {
    const response = await getHistoryChartData(wellName, startDate, endDate, aggregate, cancelToken);
    return response;
  },
);

export const fetchMasterVariablesByWellname = createAsyncThunk(
  'events/fetchMasterVariablesByWellname',
  async ({ wellName }: { wellName: string; }) => {
    const response = await getMasterVariablesData(wellName);
    return response;
  },
);

export const fetchHistoryChartFilterData = createAsyncThunk(
  'events/fetchHistoryChartFilterData',
  async () => {
    const response = await getHistoryChartFilterData();
    return response;
  },
);


const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    resetHistoryChartDataSet: (state) => {
      state.historyChartDataSet = []
    },
    updateHistorySparklineDataSet: (state, action) => {
      state.historySparklineDataSet = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHistoryById.pending, (state) => {
      state.loading = true;
      state.sparkLineChartLoading = true;
    });
    builder.addCase(fetchHistoryById.fulfilled, (state, action: PayloadAction<any>) => {
      state.histories = action.payload;
      state.loading = false;
      state.sparkLineChartLoading = false;
    });
    builder.addCase(fetchHistoryById.rejected, (state, action) => {
      state.message = action.payload as string;
      state.histories = null;
      state.loading = false;
      state.sparkLineChartLoading = false;
    });

    //fetch time series data
    builder.addCase(fetchAssetTimeseriesChannelsData.pending, (state, action) => {
      state.timeSeriesChannelDataLoading = true;
      const granularData = action.meta.arg.granularData
      if (granularData) {
        state.granularDataLoading = true;
        state.initialDataLoading = false;
      } else {
        state.granularDataLoading = false;
        state.initialDataLoading = true;
      }
    });
    builder.addCase(fetchAssetTimeseriesChannelsData.fulfilled, (state, action) => {
      state.timeSeriesChannelDataLoading = false;
      const { granularData, startDate, endDate } = action.meta.arg

      const aggregateValue = action.meta.arg.aggregate || ''
      // Find index of existing item with the same aggregate value
      const index = state.historySparklineDataSet?.findIndex((item) => item.aggregate === aggregateValue);
      if (index !== -1 && state.historySparklineDataSet) {
        // Update existing item
        state.historySparklineDataSet[index] = { ...state.historySparklineDataSet[index], data: action.payload, startDate: startDate, endDate: endDate };
      } else {
        // Add new item
        state.historySparklineDataSet.push({ aggregate: aggregateValue, data: action.payload, startDate: startDate, endDate: endDate });
      }
      if (!granularData) {
        state.histories = action.payload;
      }
      state.granularDataLoading = false;
      state.initialDataLoading = false;
    });
    builder.addCase(fetchAssetTimeseriesChannelsData.rejected, (state, action) => {
      state.timeSeriesChannelDataLoading = false;
      const { granularData, startDate, endDate } = action.meta.arg

      if (action.error.message !== "canceled") {
        const aggregateValue = action.meta.arg.aggregate || ''

        state.historySparklineDataSet = [];
        const index = state.historySparklineDataSet?.findIndex((item) => item.aggregate === aggregateValue);

        if (index !== -1 && state.historySparklineDataSet) {
          // Update existing item
          state.historySparklineDataSet[index] = { ...state.historySparklineDataSet[index], data: [], startDate: startDate, endDate: endDate };
        } else {
          // Add new item
          state.historySparklineDataSet.push({ aggregate: aggregateValue, data: [], startDate: startDate, endDate: endDate });
        }
      }
      if (!granularData) {
        state.histories = [];
      }

      state.granularDataLoading = false;
      state.initialDataLoading = false;
    });



    //
    builder.addCase(fetchHistoryChartDataById.pending, (state, action) => {
      state.loading = true;
      state.historyChartDataLoaing = true;

      const granularData = action.meta.arg.granularData
      if (granularData) {
        state.granularDataLoading = true;
        state.initialDataLoading = false;
      } else {
        state.granularDataLoading = false;
        state.initialDataLoading = true;
      }
    });
    builder.addCase(fetchHistoryChartDataById.fulfilled, (state, action) => {
      const { granularData, startDate, endDate } = action.meta.arg

      state.loading = false;
      state.historyChartDataLoaing = false;

      const aggregateValue = action.meta.arg.aggregate || ''
      // Find index of existing item with the same aggregate value
      const index = state.historyChartDataSet?.findIndex((item) => item.aggregate === aggregateValue);

      if (index !== -1 && state.historyChartDataSet) {
        // Update existing item
        state.historyChartDataSet[index] = { ...state.historyChartDataSet[index], data: action.payload, startDate: startDate, endDate: endDate };
      } else {
        // Add new item
        state.historyChartDataSet.push({ aggregate: aggregateValue, data: action.payload, startDate: startDate, endDate: endDate });
      }
      if (!granularData) {
        state.historychart = action.payload;
      }
      state.granularDataLoading = false;
      state.initialDataLoading = false;
    });
    builder.addCase(fetchHistoryChartDataById.rejected, (state, action) => {
      const { granularData, startDate, endDate } = action.meta.arg

      state.message = action.payload as string;
      state.loading = false;
      state.historyChartDataLoaing = false;

      if (action.error.message !== "canceled") {
        const aggregateValue = action.meta.arg.aggregate || ''

        state.historyChartDataSet = [];
        const index = state.historyChartDataSet?.findIndex((item) => item.aggregate === aggregateValue);

        if (index !== -1 && state.historyChartDataSet) {
          // Update existing item
          state.historyChartDataSet[index] = { ...state.historyChartDataSet[index], data: [], startDate: startDate, endDate: endDate };
        } else {
          // Add new item
          state.historyChartDataSet.push({ aggregate: aggregateValue, data: [], startDate: startDate, endDate: endDate });
        }
      }
      if (!granularData) {
        state.historychart = [];
      }

      state.granularDataLoading = false;
      state.initialDataLoading = false;
    });

    // Filter API
    builder.addCase(fetchHistoryChartFilterData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchHistoryChartFilterData.fulfilled, (state, action: PayloadAction<HistoryFilterProps[]>) => {
      state.filterData = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchHistoryChartFilterData.rejected, (state, action) => {
      state.filterData = null;
      state.message = action.payload as string;
      state.loading = false;
    });

    // Filter API
    builder.addCase(fetchMasterVariablesByWellname.pending, (state) => {
      state.loading = true;
      state.sparkLineFilterLoading = true;
    });
    builder.addCase(fetchMasterVariablesByWellname.fulfilled, (state, action: PayloadAction<SparkLineFilterProps[]>) => {
      state.sparkLineFilterData = action.payload;
      state.loading = false;
      state.sparkLineFilterLoading = false;

    });
    builder.addCase(fetchMasterVariablesByWellname.rejected, (state, action) => {
      state.sparkLineFilterData = null;
      state.message = action.payload as string;
      state.loading = false;
      state.sparkLineFilterLoading = false;

    });
  },
});

export const { resetHistoryChartDataSet, updateHistorySparklineDataSet } = historySlice.actions;

export default historySlice.reducer;
