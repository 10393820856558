import React from 'react';
import WellDashboardHeader from './components/well-dashboard-header/WellDashboardHeader';
import WellControlroomCharts from '../well-controlroom-charts/WellControlroomCharts';
import AssetControlRoomHeader from '../asset-control-room-header/components/AssetControlRoomHeader';
import AssetControlRoomDrawer from '../asset-control-room-navigation-drawer/components/AssetControlRoomDrawer';
import './WellControlroomDashboard.scss';
import { useAppSelector } from '../../hooks/storeHooks';

const WellControlroomDashboard = () => {
  const assetControlRoomDrawer = useAppSelector((state) => state.assetControlRoomDrawer);
  const selectedWellName =sessionStorage.getItem('selectedWellName');
  const selectedAssetNames = selectedWellName && JSON.parse(selectedWellName || '');
  
  //const dispatch = useAppDispatch()

  return (
    <>
        <div className={`${assetControlRoomDrawer?.openState ? 'main-content openDrawer-content' : 'main-content'}`}>
          <div className={`${assetControlRoomDrawer?.openState ? 'w-1/2' : 'w-[100%]'}`}>
            <WellDashboardHeader headerTitle={selectedAssetNames.assetName}/>
            <AssetControlRoomHeader/>
            <WellControlroomCharts/>
          </div>
          <AssetControlRoomDrawer/>
        </div>
    </>
  )
}

export default WellControlroomDashboard;