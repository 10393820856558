import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createWatchListData, deleteWatchListAPI, getAssetLocations, getAssetOperators, getAssetParameters, getAssetStatuses, getWatchList, getWatchListByIdAPI, updateWatchListAPI } from './ControlRoomWellsService';
import { ControlRoomWellsResponse, GetWatchListByIdApiResponse, WellWatchList, WellWatchListData } from '../interfaces/ControlRoomWells.interface';
import { AssetParameterApiResponse, Parameter } from '../interfaces/AssetParameters.interface';



interface ControlRoomWellsState {
    wellWatchList: WellWatchListData;
    assetStatuses?: Parameter[];
    assetLocations?: Parameter[];
    assetOperators?: Parameter[];
    assetParameters?: Parameter[];
    wellsWatchListById?: WellWatchList;
    loading: boolean;
    error: string | null;
}

const initialState: ControlRoomWellsState = {
    wellWatchList: {} as WellWatchListData,
    assetLocations: [],
    assetOperators: [],
    assetParameters: [],
    assetStatuses: [],
    wellsWatchListById: undefined,
    loading: false,
    error: null,
};

export const fetchWellsWatchList = createAsyncThunk(
    'controlRoomWells/fetchWellsWatchList',
    async ({ pageSize, pageNumber }: { pageSize: number, pageNumber: number }) => {
        return await getWatchList(pageSize, pageNumber);
    }
);

export const fetchAssetStatuses = createAsyncThunk(
    'controlRoomWells/fetchAssetStatuses',
    async () => {
        return await getAssetStatuses();
    }
);

export const fetchAssetLocations = createAsyncThunk(
    'controlRoomWells/fetchAssetLocations',
    async () => {
        return await getAssetLocations();
    }
);

export const fetchAssetOperators = createAsyncThunk(
    'controlRoomWells/fetchAssetOperators',
    async () => {
        return await getAssetOperators();
    }
);

export const fetchAssetParameters = createAsyncThunk(
    'controlRoomWells/fetchAssetParameters',
    async () => {
        return await getAssetParameters();
    }
);

export const createWatchList = createAsyncThunk(
    'controlRoomWells/createWatchList',
    async (payload: any) => {
        return await createWatchListData(payload);
    }
);

export const deleteWatchList = createAsyncThunk(
    'controlRoomWells/deleteWatchList',
    async (id: string) => {
        return await deleteWatchListAPI(id);
    }
);

export const getWatchListById = createAsyncThunk(
    'controlRoomWells/getWatchListById',
    async (id: string) => {
        return await getWatchListByIdAPI(id);
    }
);

export const updateWatchList = createAsyncThunk(
    'controlRoomWells/updateWatchList',
    async ({ id, payload }: { id: string, payload: any }) => {

        return await updateWatchListAPI(id, payload);
    }
);



const controlRoomWellsSlice = createSlice({
    name: 'controlRoomWells',
    initialState,
    reducers: {
        emptyWellWatchList: (state) => {
            state.wellWatchList = {} as WellWatchListData;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchWellsWatchList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchWellsWatchList.fulfilled, (state, action: PayloadAction<ControlRoomWellsResponse>) => {
            state.wellWatchList = {
                ...state.wellWatchList,
                ...action.payload.data,
                results: state.wellWatchList.results ? [...state.wellWatchList.results, ...action.payload.data.results] : action.payload.data.results
            };
            state.loading = false;
        });
        builder.addCase(fetchWellsWatchList.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
        });
        builder.addCase(fetchAssetStatuses.fulfilled, (state, action: PayloadAction<AssetParameterApiResponse>) => {
            state.assetStatuses = action.payload.data;
            state.loading = false;
        });
        builder.addCase(fetchAssetLocations.fulfilled, (state, action: PayloadAction<AssetParameterApiResponse>) => {
            state.assetLocations = action.payload.data;
            state.loading = false;
        });
        builder.addCase(fetchAssetOperators.fulfilled, (state, action: PayloadAction<AssetParameterApiResponse>) => {
            state.assetOperators = action.payload.data;
            state.loading = false;
        });
        builder.addCase(fetchAssetParameters.fulfilled, (state, action: PayloadAction<AssetParameterApiResponse>) => {
            state.assetParameters = action.payload.data;
            state.loading = false;
        });
        builder.addCase(fetchAssetStatuses.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
        });
        builder.addCase(fetchAssetLocations.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
        });
        builder.addCase(fetchAssetOperators.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
        });
        builder.addCase(fetchAssetParameters.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
        });
        builder.addCase(fetchAssetStatuses.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchAssetLocations.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchAssetOperators.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchAssetParameters.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createWatchList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createWatchList.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(createWatchList.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
        });
        builder.addCase(deleteWatchList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteWatchList.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(deleteWatchList.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
        });
        builder.addCase(getWatchListById.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getWatchListById.fulfilled, (state, action: PayloadAction<GetWatchListByIdApiResponse>) => {
            state.wellsWatchListById = action.payload.data;
            state.loading = false;
        });
        builder.addCase(getWatchListById.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false
        });
        builder.addCase(updateWatchList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateWatchList.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(updateWatchList.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
        });




    },

});


export default controlRoomWellsSlice.reducer;
export const selectControlRoomWells = (state: { controlRoomWells: ControlRoomWellsState }) => state.controlRoomWells;
export const { emptyWellWatchList } = controlRoomWellsSlice.actions;