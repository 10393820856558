import { apiInstance } from "../../../../config/HttpCommon";
import { SelectedFilters } from "./ControlRoomWells";

export const getWatchList = async (pageSize: number, pageNumber: number) => {
        const response = await apiInstance.get(`/v1/watchlists?pageSize=${pageSize}&pageNumber=${pageNumber}`);
        return response.data;
}

export const getAssetStatuses = async () => {
    const response = await apiInstance.get(`/v2/assets/statuses`);
    return response.data;
}

export const getAssetLocations = async () => {
    const response = await apiInstance.get(`/v2/assets/locations`);
    return response.data;
}

export const getAssetOperators = async () => {
    const response = await apiInstance.get(`/v2/assets/operators`);
    return response.data;
}

export const getAssetParameters = async () => {
    const response = await apiInstance.get(`/v2/assets/parameters`);
    return response.data;
}

export const createWatchListData = async (payload: SelectedFilters) => {
    const response = await apiInstance.post(`/v1/watchlists`, payload);
    return response.data;
}

export const deleteWatchListAPI = async (id: string) => {
    const response = await apiInstance.delete(`/v1/watchlists/${id}`);
    return response.data;
}

export const getWatchListByIdAPI = async (id: string) => {
    const response = await apiInstance.get(`/v1/watchlists/${id}`);
    return response.data;
}

export const updateWatchListAPI = async (id: string, payload: SelectedFilters) => {
    const response = await apiInstance.put(`/v1/watchlists/${id}`, payload);
    return response.data;
}