
import { apiInstance } from "../../../../../config/HttpCommon";
import { SpeedApiURL } from "../constants/speed.contants";

const getSpeedData = async (data: any) => {
    try {
      const params = {
        assetName: data.assetName, 
      }
      const response = await apiInstance.get(
        SpeedApiURL.getSpeedData,
        {params}
      );
      return response.data;
    } catch (error) {
        console.error("Error while fetching data:", error);
        throw error;
    }
};

const SpeedServices = {
    getSpeedData,
};
  
export default SpeedServices;
  
